import React, { Fragment, useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { PageHeader } from "../components/PageHeader";
import { ITEMS_PER_PAGE } from "../lib/settings";
import { getClients } from "../api/client";
import { Client } from "../generated";
import { useQuery } from "@tanstack/react-query";

export const Clients: React.FC = () => {
  const [clients, setClients] = useState<Array<Client>>([]);
  const { data, isLoading } = useQuery({
    queryKey: ["clients"],
    queryFn: getClients,
  });

  useEffect(() => {
    if (data !== undefined) {
      setClients(data.data);
    }
  }, [data]);

  const columns: ColumnsType<Client> = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];

  if (isLoading) return null;

  return (
    <Fragment>
      <PageHeader title="Clients" />

      <Table
        rowKey="Id"
        columns={columns}
        tableLayout="auto"
        sticky={true}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        dataSource={clients.sort((a, b) => a.Id - b.Id)}
        sortDirections={["descend", "ascend"]}
      />
    </Fragment>
  );
};
