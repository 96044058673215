import { Button, Layout, Menu, MenuProps, Spin, message } from "antd";
import { Header } from "antd/es/layout/layout";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelectedMenuItemKey } from "./utils";
import { ReactComponent as Logo } from "./logo.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getClients, postLogout, getUser } from "./api/client";
import { Client, User, UserRole } from "./generated";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { AxiosError } from "axios";
import { MainRoutes } from "./Router";
import { ClientContextProvider, UserContextProvider } from "./editor/context";

const { Content, Sider } = Layout;

const loadingIcon = (
  <LoadingOutlined rev={undefined} style={{ fontSize: 24 }} spin />
);

type SelectInfo = {
  key: string;
};

export const Root: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [clients, setClients] = useState<Array<Client>>([]);
  const {
    data: clientData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["rootClients"],
    queryFn: getClients,
  });
  const { mutate: doLogout } = useMutation({
    mutationKey: ["logout"],
    mutationFn: postLogout,
  });

  const {
    data: userData,
    error: userError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: _,
  } = useQuery({
    queryKey: ["rootUser"],
    queryFn: getUser,
  });

  useEffect(() => {
    if (userError === null) {
      setUser(
        userData === undefined || userData.data === undefined
          ? null
          : userData.data
      );
    }
  }, [userError, userData]);

  useEffect(() => {
    if (error instanceof AxiosError) {
      if (
        error !== null &&
        error.response !== undefined &&
        error.response.status === 401
      ) {
        void message.error("Permission denied :(");
        setUser(null);
        navigate(MainRoutes.LOGIN);
      }
    }
  }, [error, navigate]);

  useEffect(() => {
    console.log(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (clientData !== undefined) {
      if (clientData.data.length > 0) {
        if (currentClient === null) setCurrentClient(clientData.data[0]);
        setClients(clientData.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const locationItems = useMemo<ItemType[]>(
    () => [
      {
        label: <Link to={`/dashboard/locations`}>Locations</Link>,
        key: "dashboard/locations",
      },
      {
        label: <Link to={`/dashboard/kiosks`}>Kiosks</Link>,
        key: "dashboard/kiosks",
      },
    ],
    []
  );

  const adminItems = useMemo<ItemType[]>(
    () => [
      {
        label: <Link to={`/dashboard/clients`}>Clients</Link>,
        key: "dashboard/clients",
      },
      {
        label: <Link to={`/dashboard/locations`}>Locations</Link>,
        key: "dashboard/locations",
      },
      {
        label: <Link to={`/dashboard/menus`}>Menus</Link>,
        key: "dashboard/menus",
      },
      {
        label: <Link to={`/dashboard/images`}>Images</Link>,
        key: "dashboard/images",
      },
      {
        label: <Link to={`/dashboard/kiosks`}>Kiosks</Link>,
        key: "dashboard/kiosks",
      },
    ],
    []
  );

  const employeeItems = useMemo<ItemType[]>(
    () => [
      {
        label: <Link to={`/dashboard/locations`}>Locations</Link>,
        key: "dashboard/locations",
      },
      {
        label: <Link to={`/dashboard/menus`}>Menus</Link>,
        key: "dashboard/menus",
      },
      {
        label: <Link to={`/dashboard/images`}>Images</Link>,
        key: "dashboard/images",
      },
    ],
    []
  );

  const renderItems = () => {
    switch (user !== null && user.Role) {
      case UserRole.ADMIN:
        return adminItems;
      case UserRole.CLIENT:
        return adminItems;
      case UserRole.EMPLOYEE:
        return employeeItems;
      default:
        return locationItems;
    }
  };

  const selectedKeys = useSelectedMenuItemKey(renderItems());

  const menuItems: MenuProps["items"] = [
    {
      label: currentClient !== null ? currentClient.Name : "No client selected",
      key: "client",
      icon: <DownOutlined rev={undefined} />,
      children: clients.map((r) => {
        return r !== null
          ? {
              key: r.Id,
              label: r.Name,
            }
          : r;
      }),
    },
  ];

  const onClientSelect = useCallback(
    (aswd: SelectInfo) => {
      if (currentClient !== null && currentClient.Id !== parseInt(aswd.key)) {
        const client = clients.find((r) => r.Id === parseInt(aswd.key));
        if (client !== undefined) {
          setCurrentClient(client);
          if (location.pathname.includes("menu")) {
            navigate("/dashboard/menus");
          }
        }
      }
    },
    [clients, currentClient]
  );

  const onLogoutClick = useCallback(() => {
    void doLogout();
    window.location.href = "/login";
  }, [doLogout]);

  if (isLoading) return <Spin indicator={loadingIcon} />;

  return (
    <UserContextProvider value={{ user: user }}>
      <ClientContextProvider value={{ selectedClient: currentClient }}>
        <Layout style={{ minHeight: "100vh" }}>
          <Header
            className="header"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ backgroundColor: "#001529" }}>
              <Logo style={{ verticalAlign: "middle" }} />
            </div>
            <div>
              <Menu
                theme="dark"
                mode="horizontal"
                disabledOverflow={true}
                items={menuItems}
                selectedKeys={
                  currentClient !== null ? [`${currentClient.Id}`] : []
                }
                onSelect={onClientSelect}
              />
            </div>
          </Header>
          <Layout>
            <Sider width={200} className="hidden-print">
              <Menu
                theme="dark"
                selectedKeys={selectedKeys}
                mode="inline"
                items={renderItems()}
              />
              <div
                style={{
                  color: "white",
                  position: "absolute",
                  bottom: 20,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Button
                  onClick={onLogoutClick}
                  type="text"
                  style={{ color: "white" }}
                >
                  Logout
                </Button>
              </div>
            </Sider>
            <Layout style={{ padding: 16 }}>
              <Content>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </ClientContextProvider>
    </UserContextProvider>
  );
};
