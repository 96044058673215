import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback } from "react";

import React from "react";

export type CheckboxNullProps<T> = {
  // label?: string;
  value: T | null;
  defaultValue: T;
  disabled?: boolean;
  children?: React.ReactNode;
  onChange?: (value: T | null) => void;
  hidden?: boolean;
};

// FC<CheckboxNullProps<T>>
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const CheckboxNull = <T extends unknown>({
  // label,
  value,
  defaultValue,
  disabled,
  children,
  onChange: onValueChange,
  hidden,
}: CheckboxNullProps<T>) => {
  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (onValueChange !== undefined) {
        if (e.target.checked) {
          onValueChange(defaultValue);
        } else {
          onValueChange(null);
        }
      }
    },
    [defaultValue, onValueChange]
  );

  return (
    <Checkbox
      onChange={onChange}
      checked={value !== null}
      disabled={disabled}
      style={{ display: hidden === true ? "none" : "auto" }}
    >
      {children}
    </Checkbox>
  );
};
