import { InputNumber as AntInputNumber, Tooltip } from "antd";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { CheckboxNull } from "./ChechboxNull";
import { InfoCircleOutlined } from "@ant-design/icons";

export type InputNumberProps = {
  label?: string;
  value: number | null;
  nullable?: boolean;
  onChange?: (value: number | null) => void;
  disabled?: boolean;
  integer?: boolean;
  tooltipText?: string;
  hidden?: boolean;
};

export const InputNumber: FC<InputNumberProps> = ({
  label,
  value,
  nullable,
  disabled,
  onChange: onNumberChange,
  integer,
  tooltipText,
  hidden,
}: InputNumberProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
    onNumberChange !== undefined && onNumberChange(value);
  }, [value]);

  const onChange = useCallback(
    (value: number | null) => {
      setFieldValue(value !== null || nullable === true ? value : 0);
      onNumberChange !== undefined &&
        onNumberChange(value !== null || nullable === true ? value : 0);
    },
    [nullable, onNumberChange]
  );

  const onCheckBoxChange = useCallback(
    (value: number | null) => {
      console.log(value);
      if (value === null) {
        onChange(null);
      } else {
        onChange(value);
      }
    },
    [onChange]
  );

  let checkbox: ReactNode = undefined;
  if (nullable === true)
    checkbox = (
      <CheckboxNull
        onChange={onCheckBoxChange}
        value={value}
        defaultValue={0}
      />
    );

  return (
    <div
      style={{
        marginBottom: 5,
        marginTop: 5,
        display: hidden === true ? "none" : "auto",
      }}
    >
      {tooltipText !== undefined && label !== undefined && (
        <Tooltip title={tooltipText} placement="topLeft">
          <h3 style={{ width: "fit-content" }}>
            {label}
            <InfoCircleOutlined
              style={{ fontSize: 12, position: "absolute", marginLeft: 3 }}
            />
          </h3>
        </Tooltip>
      )}
      {label !== undefined && tooltipText === undefined && <h3>{label}</h3>}
      <AntInputNumber
        addonBefore={checkbox}
        value={fieldValue}
        ref={ref}
        step={integer === true ? 1 : undefined}
        onChange={onChange}
        disabled={disabled}
        type="number"
      />
    </div>
  );
};
