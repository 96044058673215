import styled from "styled-components";
import { Col, Input, Row } from "antd";

export const LoginPageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const LoginHeaderWrapper = styled.div`
  display: flex;
`;

export const LoginFormWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const LoginInputWrapper = styled(Input)`
  width: 100%;
  margin: 10px;
`;

export const LoginPasswordInputWrapper = styled(Input.Password)`
  text-align: center;
  margin: 10px;
`;

export const LoginFooter = styled(Row)`
  height: 90px;
  flex-shrink: 0;
  flex-direction: column;
`;
