import { Client, User } from "../generated";

// https://bobbyhadz.com/blog/typescript-object-comparison
export const shallowCompare = <P>(
  prevProps: Readonly<P>,
  nextProps: Readonly<P>
): boolean => {
  return (
    Object.keys(prevProps).length === Object.keys(nextProps).length &&
    (Object.keys(prevProps) as (keyof typeof prevProps)[]).every((key) => {
      // const pp = prevProps[key];
      // const np = nextProps[key];

      // if (typeof pp === "function" && typeof np === "function")
      //   return pp.toString() === np.toString();

      return (
        Object.prototype.hasOwnProperty.call(nextProps, key) &&
        prevProps[key] === nextProps[key]
      );
    })
  );
};

export interface HideFields {
  categories: string[];
  options: string[];
  products: string[];
  steps: string[];
}

export interface EditorConfig {
  hideFields: HideFields;
}

const isEditorConfig = (obj: unknown): obj is EditorConfig => {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "hideFields" in obj &&
    typeof (obj as EditorConfig).hideFields === "object" &&
    "categories" in (obj as EditorConfig).hideFields &&
    "options" in (obj as EditorConfig).hideFields &&
    "products" in (obj as EditorConfig).hideFields &&
    "steps" in (obj as EditorConfig).hideFields
  );
};

export const isInputHidden = (
  inputName: string,
  menuLabel: "categories" | "products" | "steps" | "options",
  clientCtx: Client | null,
  userCtx: User | null
): boolean => {
  if (clientCtx === null) {
    return false;
  }
  if (userCtx === null) {
    return false;
  }
  if (userCtx.Role === "admin") {
    return false;
  }
  if (isEditorConfig(clientCtx.EditorConfig)) {
    switch (menuLabel) {
      case "categories":
        return clientCtx.EditorConfig.hideFields.categories.includes(inputName);
      case "products":
        return clientCtx.EditorConfig.hideFields.products.includes(inputName);
      case "steps":
        return clientCtx.EditorConfig.hideFields.steps.includes(inputName);
      case "options":
        return clientCtx.EditorConfig.hideFields.options.includes(inputName);
      default:
        return false;
    }
  }
  return false;
};

export const findHiddenLanguage = (
  clientCtx: Client | null,
  menuLabel: "categories" | "products" | "steps" | "options"
) => {
  if (clientCtx === null) {
    return [];
  }
  if (isEditorConfig(clientCtx.EditorConfig)) {
    switch (menuLabel) {
      case "categories":
        return clientCtx.EditorConfig.hideFields.categories.filter(
          (field) => field.length === 2
        );
      case "products":
        return clientCtx.EditorConfig.hideFields.categories.filter(
          (field) => field.length === 2
        );
      case "steps":
        return clientCtx.EditorConfig.hideFields.categories.filter(
          (field) => field.length === 2
        );
      case "options":
        return clientCtx.EditorConfig.hideFields.categories.filter(
          (field) => field.length === 2
        );
      default:
        return [];
    }
  }
  return [];
};
