import styled, { keyframes } from "styled-components";
import { Card } from "antd";

interface CardProps {
  pulse?: boolean;
}

export const PulseCard = styled(Card)<CardProps>`
  animation-name: ${(props) =>
    props.pulse !== undefined && props.pulse ? color : ""};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;
`;

const color = keyframes`
  to {
    background-color: #FA5F55;
    }
`;
