import { Input, InputRef, Tooltip } from "antd";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { CheckboxNull } from "./ChechboxNull";
import { InfoCircleOutlined } from "@ant-design/icons";

export type InputStringProps = {
  label?: string;
  placeholder?: string;
  value: string | null;
  nullable?: boolean;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
  suffix?: ReactNode;
  tooltipText?: string;
  hidden?: boolean;
};

export const InputString: FC<InputStringProps> = ({
  label,
  placeholder,
  value,
  nullable,
  onChange: onStringChange,
  disabled,
  suffix,
  tooltipText,
  hidden,
}: InputStringProps) => {
  const ref = useRef<InputRef | null>(null);

  // const renderedValue = value !== null ? value : "";

  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
    onStringChange !== undefined && onStringChange(value);
  }, [value]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.value);
      onStringChange !== undefined && onStringChange(e.target.value);
    },
    [onStringChange]
  );

  let checkbox: ReactNode = undefined;
  if (nullable === true)
    checkbox = <CheckboxNull value={value} defaultValue={""} />;

  return (
    <div
      style={{
        marginBottom: 5,
        marginTop: 5,
        display: hidden === true ? "none" : "auto",
      }}
    >
      {tooltipText !== undefined && label !== undefined && (
        <Tooltip title={tooltipText} placement="topLeft">
          <h3 style={{ width: "fit-content" }}>
            {label}
            <InfoCircleOutlined
              style={{ fontSize: 12, position: "absolute", marginLeft: 3 }}
            />
          </h3>
        </Tooltip>
      )}
      {label !== undefined && tooltipText === undefined && <h3>{label}</h3>}
      <Input
        addonBefore={checkbox}
        suffix={suffix}
        placeholder={placeholder}
        value={fieldValue !== null ? fieldValue : ""}
        ref={ref}
        onChange={onChange}
        disabled={fieldValue === null || disabled}
      />
    </div>
  );
};
