import styled from "styled-components";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

export const StatusReady = styled(CheckCircleOutlined)`
  color: green;
`;

export const StatusError = styled(CloseCircleOutlined)`
  color: red;
`;

export const StatusWarning = styled(ClockCircleOutlined)`
  color: orange;
`;

export const StatusWait = styled(ClockCircleOutlined)`
  color: orange;
`;

export const StatusDefault = styled(QuestionCircleOutlined)`
  color: grey;
`;

export const TabTitle = styled.div`
  display: "flex";
  flex-direction: "row";
  justify-content: "center";
  align-items: "center";
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubContainer = styled.div`
  width: "100%";
`;

export const StatusContainer = styled.div`
  padding-left: 20px;
`;

export const StatusTitle = styled.div`
  font-weight: 500;
  padding-bottom: 5px;
`;

export const SubStatusTitle = styled.div`
  font-weight: 500;
  padding-left: 20px;
`;

interface StatusValueProps {
  isCriticalStatus: boolean;
}

export const SubStatusValue = styled.span<StatusValueProps>`
  font-weight: 400;
  color: ${(props) => (props.isCriticalStatus ? "red" : "black")};
`;

export const CommandButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

interface IAdminHelpModal {
  showModal: boolean;
}

export const AdminHelpModal = styled.div<IAdminHelpModal>`
  position: absolute;
  z-index: 2;
  top: 100px;
  bottom: 100px;
  left: 100px;
  right: 100px;
  background-color: #fff;
  display: ${(props) => (props.showModal ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 7px 10px 24px 0px rgba(0, 0, 0, 0.2);
`;

export const AdminHelpModalIframe = styled.iframe`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
`;

interface IAdminTextButton {
  isGreen: boolean;
  inactive: boolean;
}

export const AdminTextButton = styled.a<IAdminTextButton>`
  line-height: 1.6;
  padding-top: 8px;
  border: ${(props) =>
    props.inactive
      ? "4px solid gray;"
      : props.isGreen
      ? "4px solid #91a94e"
      : "4px solid #fff"};
  color: ${(props) =>
    props.inactive ? "gray" : props.isGreen ? "#91a94e" : "#fff"};
  font-size: 24px;
  height: 100px;
  width: 300px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  border-radius: 4px;
  text-decoration: none;
  font-family: "LithosPro-Black";
  text-align: center;
`;
