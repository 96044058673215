import React, { Fragment, useCallback } from "react";

import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link, useParams } from "react-router-dom";
import { ZodIssue } from "zod";
import { PageHeader } from "../components/PageHeader";
import { useVapianoConfigContext } from "./vapianoConfigContext";

export const Validation: React.FC = () => {
  const ctx = useVapianoConfigContext();

  const params = useParams<"menuId">();

  if (params.menuId === undefined) throw new Error("menuId undefined");

  const columns: ColumnsType<ZodIssue> = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
      render: (path: (string | number)[] | undefined) => {
        let text = "<undefined>";

        if (path === undefined) return text;
        if (params.menuId === undefined) return text;

        text = path.join(".");

        if (path.length >= 2) {
          if (path[1] === "products") {
            if (path.length > 2) {
              const id = ctx.document.menu.products[path[2] as number].id;
              return (
                <Link to={`/dashboard/menu/${params.menuId}/products/${id}`}>
                  {text}
                </Link>
              );
            }
          } else if (path[1] === "categories") {
            if (path.length > 2) {
              const id = ctx.document.menu.categories[path[2] as number].id;
              return (
                <Link to={`/dashboard/menu/${params.menuId}/categories/${id}`}>
                  {text}
                </Link>
              );
            }
          } else if (path[1] === "steps") {
            if (path.length > 2) {
              const id = ctx.document.menu.steps[path[2] as number].id;
              return (
                <Link to={`/dashboard/menu/${params.menuId}/steps/${id}`}>
                  {text}
                </Link>
              );
            }
          } else if (path[1] === "options") {
            if (path.length > 2) {
              const id = ctx.document.menu.options[path[2] as number].id;
              return (
                <Link to={`/dashboard/menu/${params.menuId}/options/${id}`}>
                  {text}
                </Link>
              );
            }
          }
        }
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
  ];

  const rowKey = useCallback((issue: ZodIssue) => {
    let path = "undefined";
    if (issue.path !== undefined) path = issue.path.join(".");
    return issue.code + path + issue.message;
  }, []);

  return (
    <Fragment>
      <PageHeader title="Validation Messages" />

      {ctx.validationError !== null && (
        <Table
          // onRow={onRow}
          rowKey={rowKey}
          columns={columns}
          tableLayout="auto"
          sticky={true}
          pagination={false}
          dataSource={ctx.validationError.issues}
        />
      )}
      {ctx.validationError === null && <p>All good 😀</p>}
    </Fragment>
  );
};
