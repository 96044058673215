import { Scalars } from "./schema.generated";

// first one is the default fallback language. see App.tsx IntlProvider.
export const expectedLanguages: readonly string[] = ["en", "hu"] as const;

export enum BuildMode {
  Production = "production",
  Development = "development",
}

export const ITEMS_PER_PAGE = 20;

export const buildMode: BuildMode =
  process.env["BUILD_MODE"] === "production"
    ? BuildMode.Production
    : BuildMode.Development;

// show possible taxRate misconfigurations in the console in Development buildMode
export const showPossibleTaxRateWarnings = false;

export const emptyTranslatedString: Scalars["TranslatedString"] = {
  en: "",
  hu: "",
  sk: "",
};
