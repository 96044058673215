import { Button } from "antd";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import DeviceDetails from "../deviceDetails/deviceDetails";

function KioskDetails() {
  const navigation = useNavigate();
  const params = useParams();

  const onBack = useCallback(() => {
    if (params.locationId !== undefined) {
      navigation("/dashboard/kiosks/" + params.locationId);
    }
  }, [navigation, params]);

  return (
    <div>
      <PageHeader
        title="Kiosk"
        extra={
          <Button onClick={onBack} type="primary">
            Back
          </Button>
        }
      />
      <DeviceDetails />
    </div>
  );
}

export default KioskDetails;
