import { LoadingOutlined } from "@ant-design/icons";

const LoadingPlaceholder = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <LoadingOutlined rev={undefined} style={{ fontSize: 44 }} spin />
    </div>
  );
};

export default LoadingPlaceholder;
