import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Router } from "./Router";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { IntlProvider } from "react-intl";
import hu from "./lang/hu.json";
import en from "./lang/en.json";
import i18next from "i18next";

// Egymásba ágyazott fordítások átalakítása
function flattenMessages(nestedMessages: Record<string, unknown>, prefix = "") {
  return Object.keys(nestedMessages).reduce(
    (messages: Record<string, string>, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix !== "" ? `${prefix}.${key}` : key;

      if (typeof value === "string") {
        messages[prefixedKey] = value;
      } else {
        Object.assign(
          messages,
          flattenMessages(value as Record<string, unknown>, prefixedKey)
        );
      }

      return messages;
    },
    {}
  );
}

const messages = {
  hu: flattenMessages(hu),
  en: flattenMessages(en),
};

i18next
  .init({
    lng: "hu", // if you're using a language detector, do not define the lng option
    //debug: true,
    resources: {
      hu: {
        translation: messages["hu"],
      },
      en: {
        translation: messages["en"],
      },
    },
  })
  .then(function (t) {
    // initialized and ready to go!
    //document.getElementById('output').innerHTML = i18next.t('key');
    console.log("i18next initialized", t);
  })
  .catch(function (err) {
    console.log("i18next failed to initialize", err);
  });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 0,
      networkMode: "online",
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (error instanceof AxiosError) {
          if (
            error !== null &&
            error.response !== undefined &&
            error.response.status === 401
          ) {
            void message.error("Permission denied :(");
            window.location.href = "/login";
          } else {
            if (
              error !== null &&
              error.response !== undefined &&
              error.response.status !== 418
            ) {
              void message.error("Something bad happened");
            }
          }
        } else {
          void message.error("Something bad happened");
        }
      },
    },
    mutations: {
      retry: false,
      cacheTime: 0,
      networkMode: "online",
      onError: (error) => {
        if (error instanceof AxiosError) {
          if (
            error !== null &&
            error.response !== undefined &&
            error.response.status === 401
          ) {
            void message.error("Permission denied :(");
            window.location.href = "/login";
          } else {
            void message.error("Something bad happened");
          }
        } else {
          void message.error("Something bad happened");
        }
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const lang = "hu";
root.render(
  <React.StrictMode>
    <IntlProvider
      locale={lang}
      defaultLocale={"hu"}
      messages={messages[lang]}
      fallbackOnEmptyString={true}
    >
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
