import React, { Fragment, useCallback, useEffect } from "react";
import { Button, Col, Row, Table, Upload, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { PageHeader } from "../components/PageHeader";
import { ITEMS_PER_PAGE } from "../lib/settings";
import { changeImage, getImages, saveImage } from "../api/client";
import { Image, SaveImageRequest, ChangeImageRequest } from "../generated";
import { RcFile } from "antd/es/upload";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useClientContext } from "../editor/context";
import { useQueryParam } from "use-query-params";
import { OwnStringParam, OwnNumberParam } from "../utils";
import { InputString } from "../editor/components";

export const Images: React.FC = () => {
  const clientContext = useClientContext();
  const [images, setImages] = React.useState<Array<Image>>([]);
  const {
    data: imageData,
    isLoading: isImageLoading,
    refetch: doGetImages,
  } = useQuery({
    queryKey: ["images", clientContext.selectedClient?.Id],
    queryFn: () =>
      getImages(
        clientContext.selectedClient !== null
          ? clientContext.selectedClient.Id
          : 0
      ),
  });
  const { data: saveImageData, mutate: doSaveImage } = useMutation({
    mutationKey: ["saveImage"],
    mutationFn: saveImage,
  });

  const { data: changeImageData, mutate: doChangeImage } = useMutation({
    mutationKey: ["changeImage"],
    mutationFn: changeImage,
  });

  const [page, setPage] = useQueryParam("page", OwnNumberParam);
  const [searchValue, setSearchValue] = useQueryParam(
    "keyword",
    OwnStringParam
  );

  const onSearchValueChange = useCallback(
    (value: string | null) => {
      if (value !== null) {
        setSearchValue(value);
      } else {
        setSearchValue("");
      }
    },
    [setSearchValue]
  );

  useEffect(() => {
    if (imageData !== undefined) {
      setImages(imageData.data);
    }
  }, [imageData]);

  useEffect(() => {
    if (saveImageData !== undefined) {
      void doGetImages();
      void message.success("Image saved");
    }
  }, [doGetImages, saveImageData]);

  useEffect(() => {
    if (changeImageData !== undefined) {
      void doGetImages();
      void message.success("Image changed");
    }
  }, [changeImageData, doGetImages]);

  // Single image upload
  // const uploadNewImage = useCallback(
  //   (file: RcFile) => {
  //     const isJpgPngWebp =
  //       file.type === "image/jpeg" ||
  //       file.type === "image/png" ||
  //       file.type === "image/webp";
  //     if (!isJpgPngWebp) {
  //       void message.error("You can only upload JPG/PNG/WEBP file!");
  //       return;
  //     }
  //     const isLt2M = file.size / 1024 / 1024 < 2;
  //     if (!isLt2M) {
  //       void message.error("Image must smaller than 2MB!");
  //       return;
  //     }
  //     const reader = new FileReader();

  //     reader.onload = (e: ProgressEvent<FileReader>) => {
  //       if (e.target === null) return;
  //       const base64ToSend = (e.target.result as string).split(",")[1];
  //       const image: SaveImageRequest = {
  //         Name: file.name,
  //         Data: base64ToSend,
  //         ClientId:
  //           clientContext.selectedClient !== null
  //             ? clientContext.selectedClient.Id
  //             : 0,
  //       };

  //       void doSaveImage(image);
  //     };
  //     reader.readAsDataURL(file);
  //     return false;
  //   },
  //   [clientContext.selectedClient, doSaveImage]
  // );

  const uploadNewImage = useCallback(
    async (file: RcFile) => {
      const isJpgPngWebp =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp";
      if (!isJpgPngWebp) {
        await message.error("You can only upload JPG/PNG/WEBP files!");
        return Upload.LIST_IGNORE; // Prevent default behavior
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        await message.error("Each image must be smaller than 2MB!");
        return Upload.LIST_IGNORE; // Prevent default behavior
      }
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target === null) return;
        const base64ToSend = (e.target.result as string).split(",")[1];
        const image: SaveImageRequest = {
          Name: file.name,
          Data: base64ToSend,
          ClientId:
            clientContext.selectedClient !== null
              ? clientContext.selectedClient.Id
              : 0,
        };

        doSaveImage(image);
      };
      reader.readAsDataURL(file);
      return Upload.LIST_IGNORE;
    },
    [clientContext.selectedClient, doSaveImage]
  );

  const changeCurrentImage = useCallback(
    (uuid: string) => (file: RcFile) => {
      const isJpgPngWebp =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp";
      if (!isJpgPngWebp) {
        void message.error("You can only upload JPG/PNG/WEBP file!");
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        void message.error("Image must smaller than 2MB!");
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target === null) return;
        const base64ToSend = (e.target.result as string).split(",")[1];
        const image: ChangeImageRequest = {
          Uuid: uuid,
          Data: base64ToSend,
          Name: file.name,
        };

        void doChangeImage(image);
      };
      reader.readAsDataURL(file);
      return false;
    },
    [doChangeImage]
  );

  const columns: ColumnsType<Image> = [
    {
      title: "Preview",
      dataIndex: "PreviewUrl",
      key: "PreviewUrl",
      render: (url: string) => {
        return (
          <img
            style={{ width: 100, height: 100, objectFit: "contain" }}
            src={url}
          />
        );
      },
    },
    {
      title: "Uuid",
      dataIndex: "Uuid",
      key: "Uuid",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Action",
      dataIndex: "Uuid",
      key: "Uuid",
      render: (uuid: string) => {
        return (
          <Upload
            showUploadList={false}
            name="change_image"
            beforeUpload={changeCurrentImage(uuid)}
          >
            <Button type="primary">Change image</Button>
          </Upload>
        );
      },
    },
  ];

  if (isImageLoading) return null;

  return (
    <Fragment>
      <PageHeader
        title="Images"
        extra={
          <Upload
            name="save_image"
            showUploadList={false}
            beforeUpload={uploadNewImage}
            multiple
          >
            <Button type="primary">Upload new images</Button>
          </Upload>
        }
      />
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        <Col span={8}>
          <InputString
            placeholder="Search in name"
            value={searchValue === undefined ? null : searchValue}
            onChange={onSearchValueChange}
          />
        </Col>
      </Row>
      <Table
        rowKey="Uuid"
        columns={columns}
        tableLayout="auto"
        sticky={true}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          current: page !== null && page !== undefined ? page : 1,
          onChange: (page) => {
            setPage(page);
          },
        }}
        dataSource={images.filter((image) => {
          const normalizedName = image.Name.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          const normalizedSearchValue = searchValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          return normalizedName.includes(normalizedSearchValue);
        })}
      />
    </Fragment>
  );
};
