import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useSelectedMenuItemKey = (
  items: ItemType[]
): string[] | undefined => {
  const location = useLocation();

  return useMemo(() => {
    const found = items.find(
      (item) =>
        item !== null && location.pathname.startsWith(item.key as string)
    );

    if (found !== undefined && found !== null) return [found.key as string];

    return undefined;
  }, [items, location.pathname]);
};

export const OwnStringParam = {
  encode(value: string): string | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (value.length === 0) {
      return undefined;
    }
    return `${value}`;
  },

  decode(strValue: string | (string | null)[] | null | undefined): string {
    if (strValue === null || strValue === undefined) {
      return "";
    }
    if (Array.isArray(strValue)) {
      return strValue.join(",");
    }
    return strValue;
  },
};

export const OwnNumberParam = {
  encode(value: number | undefined): string | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    return `${value}`;
  },

  decode(
    strValue: string | (string | null)[] | null | undefined
  ): number | undefined {
    if (strValue === null || strValue === undefined) {
      return undefined;
    }
    if (Array.isArray(strValue)) {
      return undefined;
    }
    const res = parseFloat(strValue);
    return isNaN(res) ? undefined : res;
  },
};

export const SortParam = {
  encode(
    value: { order: string | undefined; columnKey: string } | undefined
  ): string | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }
    if (value.order === undefined) {
      return undefined;
    }

    return `${value.columnKey}_${value.order.substring(0, 3)}`;
  },

  decode(
    strValue: string | undefined | (string | null)[] | null | undefined
  ): { order: string; columnKey: string } | undefined {
    if (strValue === null || strValue === undefined) {
      return undefined;
    }
    if (Array.isArray(strValue)) {
      return undefined;
    }
    const parts = strValue.split("_");
    if (parts.length !== 2) {
      return undefined;
    }
    if (parts[1] !== "asc" && parts[1] !== "des") {
      return undefined;
    }
    if (parts[0].length === 0) {
      return undefined;
    }
    return {
      order: parts[1] === "asc" ? "ascend" : "descend",
      columnKey: parts[0],
    };
  },
};
