import React, { useCallback, useEffect } from "react";

import { Button, Col, Row, Space } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  CheckboxNull,
  Image,
  InputNumber,
  InputString,
  InputTranslated,
  Select,
  ActionButton,
} from "../editor/components";
import {
  Category,
  CategoryMobile,
  Image as ImageType,
  ImageSource,
  Maybe,
} from "../lib/schema.generated";

import { useVapianoConfigContext } from "./vapianoConfigContext";
import { TranslatedString } from "../lib/scalars";
import {
  useClientContext,
  useEditorContext,
  useUserContext,
} from "../editor/context";
import { GetKeys } from "../lib/types";
import { setProperty, expandTranslatedString } from "../lib/utils";
import { PageHeader } from "../components/PageHeader";
import { findHiddenLanguage, isInputHidden } from "../editor/utils";
import LoadingPlaceholder from "../components/LoadingIcon";

export const CategoryDetails: React.FC = () => {
  const ctx = useVapianoConfigContext();
  const editorCtx = useEditorContext();
  const clientCtx = useClientContext();
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const params = useParams<"categoryId" | "menuId">();
  const [currentCategory, SetCurrentCategory] = React.useState<Category | null>(
    null
  );

  const location = useLocation();
  const state = location.state as { queryParams: string | undefined } | null;
  const queryParams =
    state !== null &&
    state.queryParams !== undefined &&
    state.queryParams !== null
      ? state.queryParams
      : undefined;

  // find category

  if (params.categoryId === undefined) throw new Error("categoryId undefined");
  if (params.menuId === undefined) throw new Error("menuId undefined");

  const categoryId = Number.parseInt(params.categoryId);

  if (Number.isNaN(categoryId))
    throw new Error(`categoryId '${params.categoryId}' is NaN`);

  const category = ctx.document.menu.categories.find(
    (c) => c.id === categoryId
  );

  const categoryIndex = ctx.document.menu.categories.findIndex(
    (c) => c.id === categoryId
  );

  // other logic

  if (category === undefined)
    throw new Error(`category with id ${categoryId} not found`);

  const optionsForProducts = ctx.document.menu.products.map((product) => {
    return {
      label: `[${product.id}] ${product.name[ctx.useLanguageKey]}`,
      value: product.id,
    };
  });

  useEffect(() => {
    SetCurrentCategory({
      ...category,
      name: expandTranslatedString(category.name),
      upsellName: expandTranslatedString(category.upsellName),
      upsellButton: expandTranslatedString(category.upsellButton),
    });
  }, [category]);

  const onCategoryValueChange = useCallback(
    (key: GetKeys<Category>) =>
      (
        value:
          | number
          | string
          | TranslatedString
          | null
          | (string | number)[]
          | Maybe<CategoryMobile>
          | Maybe<ImageSource>
          | (ImageType | null)
      ) => {
        if (currentCategory !== null) {
          const newCategory = setProperty(currentCategory, key, value);
          SetCurrentCategory(newCategory);
        }
      },
    [currentCategory, SetCurrentCategory]
  );

  const onSaveClick = useCallback(() => {
    // find category position in global state and replace that value with this new one
    // navigate back to categories list
    const categoryIndex = ctx.document.menu.categories.findIndex(
      (c) => c.id === category.id
    );
    editorCtx.dispatch({
      type: "replaceItem",
      path: ["menu", "categories"],
      item: currentCategory,
      index: categoryIndex,
    });
    if (params.menuId !== undefined)
      navigate({
        pathname: `/dashboard/menu/${params.menuId}/categories`,
        search: queryParams,
      });
  }, [
    category.id,
    ctx.document.menu.categories,
    currentCategory,
    editorCtx,
    navigate,
    params.menuId,
    queryParams,
  ]);

  if (currentCategory === null) return <LoadingPlaceholder />;

  return (
    <div className="form">
      <Row>
        <PageHeader
          title="Category details"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
          extra={
            <Space wrap>
              <ActionButton
                label="Remove"
                action="remove"
                type="primary"
                dataPath={["menu", "categories"]}
                value={ctx.document.menu.categories}
                defaultValue={categoryIndex}
                nav={
                  params.menuId !== undefined
                    ? {
                        pathname: `/dashboard/menu/${params.menuId}/categories`,
                        search: queryParams,
                      }
                    : undefined
                }
                danger
              />
              <Button type="primary" onClick={onSaveClick}>
                Save
              </Button>
            </Space>
          }
        />
      </Row>
      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "id",
            "categories",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="ID"
            value={currentCategory.id}
            disabled
            hidden={isInputHidden(
              "id",
              "categories",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "position",
            "categories",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Position"
            tooltipText="A kategóriák sorrendjében elfoglalt hely"
            onChange={onCategoryValueChange("position")}
            value={currentCategory.position}
            hidden={isInputHidden(
              "position",
              "categories",
              clientCtx.selectedClient,
              userCtx.user
            )}
            nullable
          />
        </Col>
      </Row>
      <Row>
        <Col
          span={6}
          hidden={isInputHidden(
            "editorName",
            "categories",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputString
            label="Editor name"
            tooltipText="Munkanév, csak az editorban jelenik meg"
            value={currentCategory.editorName}
            onChange={onCategoryValueChange("editorName")}
            hidden={isInputHidden(
              "editorName",
              "categories",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>

      <InputTranslated
        label="Name"
        tooltipText="A termék neve, ami megjelenik a kioskon"
        value={currentCategory.name}
        onChange={onCategoryValueChange("name")}
        hidden={isInputHidden(
          "name",
          "categories",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "categories"
        )}
      />

      <InputTranslated
        label="Upsell name"
        value={currentCategory.upsellName}
        onChange={onCategoryValueChange("upsellName")}
        hidden={isInputHidden(
          "upsellName",
          "categories",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "categories"
        )}
      />
      <InputTranslated
        label="Upsell button"
        value={currentCategory.upsellButton}
        onChange={onCategoryValueChange("upsellButton")}
        hidden={isInputHidden(
          "upsellButton",
          "categories",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "categories"
        )}
      />

      <Row gutter={8}>
        <Col span={24}>
          <CheckboxNull
            value={currentCategory.mobile}
            onChange={onCategoryValueChange("mobile")}
            defaultValue={{
              icon: "",
              menuCoverImage: {
                original: ctx.mediaFiles[0],
                generated: null,
              },
            }}
            hidden={isInputHidden(
              "mobile",
              "categories",
              clientCtx.selectedClient,
              userCtx.user
            )}
          >
            <h3>Enable picture</h3>
          </CheckboxNull>
          {currentCategory.mobile !== null && (
            <Row gutter={8}>
              <Col span={8}>
                <InputString
                  label="Icon"
                  tooltipText="Kategória ikonja"
                  value={currentCategory.mobile.icon}
                />
              </Col>
              <Col span={8}>
                <Image
                  label="Category Image"
                  nullable
                  onChange={onCategoryValueChange("mobile.menuCoverImage")}
                  value={currentCategory.mobile.menuCoverImage}
                  mediaFiles={ctx.mediaFiles}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Select
            label="Products"
            tooltipText="Termékek, amik ebbe a kategóriába tartoznak"
            multiple
            placeholder="select a product"
            value={currentCategory.productIds}
            options={optionsForProducts}
            onChange={onCategoryValueChange("productIds")}
            hidden={isInputHidden(
              "productIds",
              "categories",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col span={12}>
          <Select
            label="Upsell Product"
            tooltipText="Termékek, amik upsellelhetők ebből a kategóriából"
            multiple
            placeholder="select a product"
            value={currentCategory.upsellProductIds}
            options={optionsForProducts}
            onChange={onCategoryValueChange("upsellProductIds")}
            hidden={isInputHidden(
              "productIds",
              "categories",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
