// apimodel/user.go

export type LoginRequest = {
  Username: string; //`json:"email"`
  Password: string; //`json:"password"`
};

export enum UserRole {
  ADMIN = "admin",
  CLIENT = "client",
  LOCATION = "location",
  EMPLOYEE = "employee",
}

export type User = {
  Id: number;
  Username: string;
  Role: UserRole;
};

// dbmodel/client.go

export type Client = {
  Id: number;
  Name: string;
  Description: string;
  Logo: string | null;
  EditorConfig: unknown;
};

export type ClientLocations = {
  ClientId: number;
  LocationId: number;
};

// apimodel/image.go

export type Image = {
  Uuid: string;
  Name: string;
  PreviewUrl: string;
};

export type SaveImageRequest = {
  Name: string;
  Data: string;
  ClientId: number;
};

export type ChangeImageRequest = {
  Uuid: string;
  Data: string;
  Name: string;
};

// apimodel/location.go

export type Kiosk = {
  Id: number;
  Name: string;
  Description: string;
};

export type ActiveMenu = {
  Id: number;
  Name: string;
  IsValid: boolean;
};

export type Location = {
  Id: number;
  Name: string;
  Description: string;
  AdminLink: string;
  LastDeploy?: string;
  ActiveMenu?: ActiveMenu;
  Kiosks: Kiosk[];
};

export type LocationWithDisabled = {
  Id: number;
  Name: string;
  Description: string;
  AdminLink: string;
  LastDeploy?: string;
  ActiveMenu?: ActiveMenu;
  Kiosks: Kiosk[];
  DisabledOptionIds: number[];
  DisabledProductIds: number[];
  ClosedDays: string[];
  OpeningTimes: OpeningTimesStruct;
};

export type SaveLocationRequest = {
  ClientId: number;
  Id?: number;
  Name: string;
  Description: string;
  AdminLink: string;
  KioskIds: number[];
  ActiveMenuId: number;
};

export type UpdateProdOptVisibilityRequest = {
  ClientId: number;
  LocationId: number;
  Type: string;
  Id: number;
  IsVisible: boolean;
  ProductName: string;
};

export type UpdateOpeningTimesRequest = {
  ClientId: number;
  LocationId: number;
  OpeningTimes: OpeningTimesStruct;
};

export type UpdateClosedDaysRequest = {
  ClientId: number;
  LocationId: number;
  ClosedDays: string[];
};

// apimodel/menu.go

export type MenuResponse = {
  Id: number;
  Name: string;
  Description: string;
  IsValid: boolean;
};

export type GetMenuDetailsResponse = {
  Id: number;
  Name: string;
  Description: string;
  IsValid: boolean;
  ClientId: number;
  Data: unknown;
  KioskData: unknown;
};

export type SaveMenuRequest = {
  Id?: number;
  Name: string;
  Description: string;
  ClientId: number;
  Data: unknown;
};

export type DeployMenuRequest = {
  ClientId: number;
  LocationId: number;
};

export type CopyMenuRequest = {
  Id: number;
  Name: string;
  Description: string;
};

// ../../model/opening_times.go

export type OpeningTimesStruct = {
  Mon?: OpeningTime;
  Tue?: OpeningTime;
  Wed?: OpeningTime;
  Thu?: OpeningTime;
  Fri?: OpeningTime;
  Sat?: OpeningTime;
  Sun?: OpeningTime;
};

export type OpeningTime = {
  From: string | null;
  To: string | null;
};
