import React, { useCallback, useEffect } from "react";
import { Button, Col, Row, Table, Space } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Checkbox,
  InputNumber,
  InputString,
  InputTranslated,
  Select,
  ActionButton,
} from "../editor/components";
import {
  Step,
  StepOption,
  StepPresentation,
  Product,
} from "../lib/schema.generated";
import { useVapianoConfigContext } from "./vapianoConfigContext";
import { TranslatedString } from "../lib/scalars";
import {
  useClientContext,
  useEditorContext,
  useUserContext,
} from "../editor/context";
import { PageHeader } from "../components/PageHeader";
import { ColumnsType } from "antd/lib/table";
import { setProperty, expandTranslatedString } from "../lib/utils";
import { GetKeys } from "../lib/types";
import { findHiddenLanguage, isInputHidden } from "../editor/utils";
import LoadingPlaceholder from "../components/LoadingIcon";

export const StepDetails: React.FC = () => {
  const ctx = useVapianoConfigContext();
  const editorCtx = useEditorContext();
  const clientCtx = useClientContext();
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<"stepId" | "menuId">();
  const [currentStep, SetCurrentStep] = React.useState<Step | null>(null);

  const state = location.state as {
    queryParams: string | undefined;
  } | null;
  const queryParams =
    state !== null &&
    state.queryParams !== undefined &&
    state.queryParams !== null
      ? state.queryParams
      : undefined;

  // find category

  if (params.stepId === undefined) throw new Error("stepId undefined");
  if (params.menuId === undefined) throw new Error("menuId undefined");

  const stepId = Number.parseInt(params.stepId);

  if (Number.isNaN(stepId)) throw new Error(`stepId '${params.stepId}' is NaN`);

  const step = ctx.document.menu.steps.find((c) => c.id === stepId);

  const stepIndex = ctx.document.menu.steps.findIndex((c) => c.id === stepId);

  // other logic

  if (step === undefined) throw new Error(`step with id ${stepId} not found`);

  useEffect(() => {
    SetCurrentStep({
      ...step,
      name: expandTranslatedString(step.name),
      selectionText: expandTranslatedString(step.selectionText),
    });
  }, [step]);

  const [products] = React.useState<Product[]>(
    ctx.document.menu.products.filter(function (prod) {
      const stepOption = prod.productSteps.find(function (ps) {
        return ps.stepId === stepId;
      });
      return stepOption !== undefined;
    })
  );

  const onStepValueChange = useCallback(
    (key: GetKeys<Step>) =>
      (
        value:
          | number
          | string
          | TranslatedString
          | null
          | (string | number)[]
          | boolean
      ) => {
        if (currentStep !== null) {
          const newStep = setProperty(currentStep, key, value);
          SetCurrentStep(newStep);
        }
      },
    [currentStep, SetCurrentStep]
  );

  const onSaveClick = useCallback(() => {
    // find category position in global state and replace that value with this new one
    // navigate back to categories list
    const stepIndex = ctx.document.menu.steps.findIndex(
      (c) => c.id === step.id
    );
    editorCtx.dispatch({
      type: "replaceItem",
      path: ["menu", "steps"],
      item: currentStep,
      index: stepIndex,
    });
    if (params.menuId !== undefined)
      navigate({
        pathname: `/dashboard/menu/${params.menuId}/steps`,
        search: queryParams,
      });
  }, [
    ctx.document.menu.steps,
    editorCtx,
    currentStep,
    params.menuId,
    navigate,
    step.id,
    queryParams,
  ]);

  const stepOptionTableRowKey = useCallback(
    (so: StepOption, index: number | undefined): string =>
      index?.toString() ?? so.optionId.toString(),
    []
  );

  const onStepOptionValueChange = useCallback(
    (key: string, index: number) =>
      (value: string | number | null | (string | number)[]) => {
        if (currentStep !== null) {
          const copyOfStepOptions = [...currentStep.stepOptions];
          copyOfStepOptions[index] = {
            ...copyOfStepOptions[index],
            [key]: value,
          };
          const newStepOptions = setProperty(
            currentStep,
            "stepOptions",
            copyOfStepOptions
          );
          SetCurrentStep(newStepOptions);
        }
      },
    [currentStep, SetCurrentStep]
  );

  const onOptionDeleteButtonClick = useCallback(
    (index: number) => () => {
      if (currentStep !== null) {
        if (window.confirm("You sure about that?")) {
          const copyOfStepOptions = [...currentStep.stepOptions];
          copyOfStepOptions.splice(index, 1);
          const newStepOptions = setProperty(
            currentStep,
            "stepOptions",
            copyOfStepOptions
          );
          SetCurrentStep(newStepOptions);
        }
      }
    },
    [currentStep, SetCurrentStep]
  );

  const onAddOptionButtonClick = useCallback(() => {
    if (currentStep !== null) {
      const newStepOption: StepOption = {
        defaultAmount: 0,
        max: null,
        min: null,
        optionId: 0,
        position: 0,
        price: null,
        statPrice: null,
        containerPrice: null,
      };
      const copyOfStepOptions = [...currentStep.stepOptions];
      copyOfStepOptions.push(newStepOption);
      const newStepOptions = setProperty(
        currentStep,
        "stepOptions",
        copyOfStepOptions
      );
      SetCurrentStep(newStepOptions);
    }
  }, [currentStep, SetCurrentStep]);

  const productTableRowKey = useCallback(
    (p: Product, index: number | undefined): string => {
      return index?.toString() ?? p.id.toString();
    },
    []
  );

  const onProductRow = useCallback(
    (product: Product) => {
      return {
        onClick: () => {
          //navigate(`/dashboard/location/${product.Id}`);
          if (params.menuId !== undefined)
            navigate(`/dashboard/menu/${params.menuId}/products/${product.id}`);
        },
      };
    },
    [navigate, params.menuId]
  );

  if (currentStep === null) return <LoadingPlaceholder />;

  const optionsForOptionId = ctx.document.menu.options.map((option) => {
    return {
      label: `(${option.id}) ${option.editorName}`,
      value: option.id,
    };
  });

  const stepOptionColumns: ColumnsType<StepOption> = [
    {
      title: "Option ID",
      dataIndex: "optionId",
      key: "optionId",
      // sorter: (a: ProductStep, b: ProductStep) => a.stepId - b.stepId,
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <Select
          label=""
          placeholder="select an option"
          value={stepOption.optionId}
          options={optionsForOptionId}
          onChange={onStepOptionValueChange("optionId", index)}
        />
      ),
    },
    {
      title: "Def. Amount",
      dataIndex: "defaultAmount",
      key: "defaultAmount",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("defaultAmount", index)}
          value={stepOption.defaultAmount}
          integer
        />
      ),
      width: 130,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("position", index)}
          value={stepOption.position}
          nullable
          integer
        />
      ),
      width: 130,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("price", index)}
          value={stepOption.price}
          nullable
          integer
        />
      ),
      width: 150,
    },
    {
      title: "StatPrice",
      dataIndex: "statPrice",
      key: "statPrice",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("statPrice", index)}
          value={stepOption.statPrice}
          nullable
          integer
        />
      ),
      width: 150,
    },
    {
      title: "ContainerPrice",
      dataIndex: "containerPrice",
      key: "containerPrice",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("containerPrice", index)}
          value={stepOption.containerPrice}
          nullable
          integer
        />
      ),
      width: 150,
    },
    {
      title: "Min",
      dataIndex: "min",
      key: "min",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("min", index)}
          value={stepOption.min}
          nullable
          integer
        />
      ),
      width: 130,
    },
    {
      title: "Max",
      dataIndex: "max",
      key: "max",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <InputNumber
          label=""
          onChange={onStepOptionValueChange("max", index)}
          value={stepOption.max}
          nullable
          integer
        />
      ),
      width: 130,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (v: unknown, stepOption: StepOption, index: number) => (
        <Button type="link" onClick={onOptionDeleteButtonClick(index)}>
          Delete
        </Button>
      ),
      width: 100,
    },
  ];

  const productColumns: ColumnsType<Product> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "editorName",
      dataIndex: "editorName",
      key: "editorName",
      sorter: (a: Product, b: Product) =>
        a.editorName.localeCompare(b.editorName),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name[ctx.useLanguageKey],
      sorter: (a: Product, b: Product) =>
        a.name[ctx.useLanguageKey].localeCompare(b.name[ctx.useLanguageKey]),
    },
  ];

  {
    console.log("currentStep.presentation", currentStep.presentation);
    //console.warn("isExtra", currentStep.isExtra);
  }

  return (
    <div className="form">
      <Row>
        <PageHeader
          title="Step details"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
          extra={
            <Space wrap>
              <ActionButton
                label="Remove"
                action="remove"
                type="primary"
                dataPath={["menu", "steps"]}
                value={ctx.document.menu.steps}
                defaultValue={stepIndex}
                nav={
                  params.menuId !== undefined
                    ? `/dashboard/menu/${params.menuId}/steps`
                    : undefined
                }
                danger
              />
              <Button type="primary" onClick={onSaveClick}>
                Save
              </Button>
            </Space>
          }
        />
      </Row>
      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "id",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="ID"
            value={currentStep.id}
            disabled
            hidden={isInputHidden(
              "id",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col
          span={6}
          hidden={isInputHidden(
            "editorName",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputString
            label="Editor name"
            tooltipText="Munkanév, csak az editorban jelenik meg"
            value={currentStep.editorName}
            onChange={onStepValueChange("editorName")}
            hidden={isInputHidden(
              "editorName",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      <InputTranslated
        label="Name"
        value={currentStep.name}
        onChange={onStepValueChange("name")}
        hidden={isInputHidden(
          "name",
          "steps",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(clientCtx.selectedClient, "steps")}
      />
      <InputTranslated
        label="Description"
        value={currentStep.description}
        nullable
        multiLine
        onChange={onStepValueChange("description")}
        hidden={isInputHidden(
          "description",
          "steps",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(clientCtx.selectedClient, "steps")}
      />
      <InputTranslated
        label="Selection Text"
        tooltipText="A lépés neve alatt megjelenő szöveg"
        value={currentStep.selectionText}
        nullable
        onChange={onStepValueChange("selectionText")}
        hidden={isInputHidden(
          "selectionText",
          "steps",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(clientCtx.selectedClient, "steps")}
      />

      <Col
        span={4}
        hidden={isInputHidden(
          "presentation",
          "steps",
          clientCtx.selectedClient,
          userCtx.user
        )}
      >
        <Select
          label="Presentation"
          placeholder="presentation type"
          value={currentStep.presentation}
          options={[
            { label: "List", value: StepPresentation.List },
            {
              label: "Cards",
              value: StepPresentation.Cards,
            },
          ]}
          onChange={onStepValueChange("presentation")}
          hidden={isInputHidden(
            "presentation",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        />
      </Col>
      <Col
        span={4}
        hidden={isInputHidden(
          "columnCount",
          "steps",
          clientCtx.selectedClient,
          userCtx.user
        )}
      >
        <InputNumber
          label="Column count"
          value={currentStep.columnCount}
          onChange={onStepValueChange("columnCount")}
          hidden={isInputHidden(
            "columnCount",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        />
      </Col>
      <Row>
        <Col
          span={4}
          hidden={isInputHidden(
            "singleSelect",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Checkbox
            label="Single Select"
            value={currentStep.singleSelect}
            onChange={onStepValueChange("singleSelect")}
            nullable
            hidden={isInputHidden(
              "singleSelect",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "minDistinct",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Min (distinct)"
            value={currentStep.minDistinct}
            onChange={onStepValueChange("minDistinct")}
            nullable
            hidden={isInputHidden(
              "minDistinct",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "maxDistinct",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Max (distinct)"
            value={currentStep.maxDistinct}
            onChange={onStepValueChange("maxDistinct")}
            nullable
            hidden={isInputHidden(
              "maxDistinct",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "minTotal",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Min (total)"
            value={currentStep.minTotal}
            onChange={onStepValueChange("minTotal")}
            nullable
            hidden={isInputHidden(
              "minTotal",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "maxTotal",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="Max (total)"
            value={currentStep.maxTotal}
            onChange={onStepValueChange("maxTotal")}
            nullable
            hidden={isInputHidden(
              "maxTotal",
              "steps",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      <PageHeader
        title="Options"
        extra={[
          <Button
            key="AddOptionButton"
            onClick={onAddOptionButtonClick}
            type="primary"
          >
            Add Option
          </Button>,
        ]}
      />
      <Table
        rowKey={stepOptionTableRowKey}
        columns={stepOptionColumns}
        tableLayout="fixed"
        sticky={true}
        pagination={false}
        dataSource={currentStep.stepOptions}
      />
      {products.length > 0 && (
        <Row>
          <Col span={24}>
            <PageHeader title="Products" />

            {
              <Table
                onRow={onProductRow}
                rowKey={productTableRowKey}
                columns={productColumns}
                tableLayout="fixed"
                sticky={true}
                pagination={false}
                dataSource={products}
                scroll={{ x: 500 }}
              />
            }
          </Col>
        </Row>
      )}
    </div>
  );
};
