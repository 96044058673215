import { FC } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  //useNavigate,
  //useLocation,
} from "react-router-dom";
import { Root } from "./Root";
import { Categories } from "./src/Categories";
import { CategoryDetails } from "./src/CategoryDetails";
import { ProductDetails } from "./src/ProductDetails";
import { Products } from "./src/Products";
import { KioskLocations } from "./src/KioskLocations";
import KioskList from "./src/kioskList/KioskList";
import { Validation } from "./src/Validation";
import { MenuEditor } from "./src/MenuEditor";
import { Options } from "./src/Options";
import { Steps } from "./src/Steps";
import { StepDetails } from "./src/StepDetails";
import { OptionDetails } from "./src/OptionDetails";
import { Images } from "./src/Images";
import { Clients } from "./src/Clients";
import { Locations } from "./src/Locations";
import { Menus } from "./src/Menus";
import { Login } from "./src/Login";
import { LocationDetails } from "./src/LocationDetails";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";
import DeviceDetails from "./src/deviceDetails/deviceDetails";
import KioskDetails from "./src/kioskList/KioskDetails";
import { Export } from "./src/Export";

export const MainRoutes = {
  LOGIN: "/login",
  DEVICEDETAILS: "/deviceDetails",
  ROOT: "/",
  DASHBOARD: "dashboard",
};

export const DashboardRoutes = {
  IMAGES: "images",
  CLIENTS: "clients",
  LOCATIONS: "locations",
  MENUS: "menus",
  KIOSKS: "kiosks",
};

export const extraPath = (path: string, id: string) => {
  return path + `/${id}`;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <Outlet />
      </QueryParamProvider>
    ),
    children: [
      {
        id: "login",
        path: MainRoutes.LOGIN,
        element: <Login />,
      },
      {
        id: "device-details",
        path: extraPath(MainRoutes.DEVICEDETAILS, ":balena_id"),
        element: <DeviceDetails />,
      },
      {
        id: "root",
        path: MainRoutes.ROOT,
        element: <Root />,
        errorElement: <div>Not Found</div>,
        children: [
          {
            id: "dashboard",
            path: MainRoutes.DASHBOARD,
            children: [
              {
                id: "images",
                path: DashboardRoutes.IMAGES,
                element: <Images />,
              },
              {
                id: "clients",
                path: DashboardRoutes.CLIENTS,
                element: <Clients />,
              },
              {
                id: "locations",
                path: DashboardRoutes.LOCATIONS,
                element: <Locations />,
              },
              {
                id: "kioskLocations",
                path: DashboardRoutes.KIOSKS,
                element: <KioskLocations />,
              },
              {
                id: "kiosksList",
                path: "kiosks/:locationId",
                element: <KioskList />,
              },
              {
                id: "kiosksDetails",
                path: "kiosks/:locationId/:balena_id",
                element: <KioskDetails />,
              },
              {
                id: "locationDetails",
                path: "location/:locationId",
                element: <LocationDetails />,
              },
              {
                id: "menus",
                path: DashboardRoutes.MENUS,
                element: <Menus />,
              },
              {
                id: "menu",
                path: "menu/:menuId",
                element: <MenuEditor />,
                children: [
                  {
                    id: "categories",
                    path: "categories",
                    element: <Categories />,
                  },

                  {
                    id: "categoryDetails",
                    path: "categories/:categoryId",
                    element: <CategoryDetails />,
                  },
                  {
                    id: "products",
                    path: "products",
                    element: <Products />,
                  },
                  {
                    id: "productDetails",
                    path: "products/:productId",
                    element: <ProductDetails />,
                  },
                  {
                    id: "options",
                    path: "options",
                    element: <Options />,
                  },
                  {
                    id: "optionDetails",
                    path: "options/:optionId",
                    element: <OptionDetails />,
                  },
                  {
                    id: "steps",
                    path: "steps",
                    element: <Steps />,
                  },
                  {
                    id: "stepDetails",
                    path: "steps/:stepId",
                    element: <StepDetails />,
                  },
                  {
                    id: "validation",
                    path: "validation",
                    element: <Validation />,
                  },
                  {
                    id: "export",
                    path: "export",
                    element: <Export />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const Router: FC = () => {
  return <RouterProvider router={router} />;
};
