import { Radio, RadioChangeEvent } from "antd";
import React from "react";
import { useCallback } from "react";

export type CheckboxProps = {
  label?: string;
  value: boolean | null;
  disabled?: boolean;
  nullable?: boolean;
  onChange?: (value: boolean | null) => void;
  hidden?: boolean;
};

// FC<CheckboxProps<T>>
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Checkbox = ({
  label,
  value,
  disabled,
  nullable,
  onChange: onBooleanChange,
  hidden,
}: CheckboxProps) => {
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      if (e.target.value === undefined) return;

      if (onBooleanChange !== undefined)
        onBooleanChange(e.target.value as boolean);
    },
    [onBooleanChange]
  );

  return (
    <div
      style={{
        marginBottom: 5,
        marginTop: 5,
        display: hidden === true ? "none" : "auto",
      }}
    >
      {label !== undefined && <h3>{label}</h3>}
      <Radio.Group
        onChange={onChange}
        value={value === null ? -1 : value}
        disabled={disabled}
        optionType="button"
        buttonStyle="solid"
      >
        <Radio value={true}>yes</Radio>
        <Radio value={false}>no</Radio>
        {nullable === true && <Radio value={-1}>null</Radio>}
      </Radio.Group>
    </div>
  );
};

const CheckboxMemo = React.memo(Checkbox);
export { CheckboxMemo as Checkbox };
