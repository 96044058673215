import axios, { AxiosError, AxiosResponse } from "axios";
import {
  LoginRequest,
  User,
  Client,
  Location,
  LocationWithDisabled,
  SaveLocationRequest,
  MenuResponse,
  GetMenuDetailsResponse,
  DeployMenuRequest,
  Image,
  SaveImageRequest,
  SaveMenuRequest,
  CopyMenuRequest,
  ChangeImageRequest,
  UpdateProdOptVisibilityRequest,
  UpdateOpeningTimesRequest,
  UpdateClosedDaysRequest,
  OpeningTimesStruct,
} from "../generated";
import { AnyArray } from "immer/dist/internal";

const client = axios.create({
  baseURL:
    process.env.REACT_APP_API !== undefined
      ? process.env.REACT_APP_API
      : "http://localhost:8989",
  withCredentials: true,
});

export const postLogin = (loginBody: LoginRequest): Promise<User> => {
  return client.post<User>("/login", loginBody).then((res) => res.data);
};

export const postLogout = (): Promise<AxiosResponse<null, AxiosError>> => {
  return client.post("/logout");
};

export const getClients = (): Promise<
  AxiosResponse<Array<Client>, AxiosError>
> => {
  return client.get("/clients");
};

export const getUser = (): Promise<AxiosResponse<User, AxiosError>> => {
  return client.get("/user");
};

export const getLocations = (
  clientId: number
): Promise<AxiosResponse<Array<Location>, AxiosError>> => {
  return client.get(`/locations?ClientId=${clientId}`);
};

export type LocationDetailsQueryParams = {
  LocationId: number;
  ClientId: number;
};

export const getLocationDetails = (
  params: LocationDetailsQueryParams
): Promise<AxiosResponse<LocationWithDisabled, AxiosError>> => {
  return client.get(
    `/location?LocationId=${params.LocationId}&ClientId=${params.ClientId}`
  );
};

export const postLocationDetails = (
  params: SaveLocationRequest
): Promise<AxiosResponse<LocationWithDisabled, AxiosError>> => {
  return client.post("/save_location", params);
};

export const getMenus = (
  clientId: number
): Promise<AxiosResponse<Array<MenuResponse>, AxiosError>> => {
  return client.get(`/menus?ClientId=${clientId}`);
};

export const getMenu = (
  menuId: number
): Promise<AxiosResponse<GetMenuDetailsResponse, AxiosError>> => {
  return client.get(`/menu?MenuId=${menuId}`);
};

export const postDeployMenu = (
  deployMenu: DeployMenuRequest
): Promise<AxiosResponse<GetMenuDetailsResponse, AxiosError>> => {
  return client.post("/deploy_menu", deployMenu);
};

export const getImages = (
  clientId: number
): Promise<AxiosResponse<Array<Image>, AxiosError>> => {
  return client.get(`/images?ClientId=${clientId}`);
};

export const saveImage = (
  image: SaveImageRequest
): Promise<AxiosResponse<Image, AxiosError>> => {
  return client.post("/save_image", image);
};

export const saveMenu = (
  menu: SaveMenuRequest
): Promise<AxiosResponse<GetMenuDetailsResponse, AxiosError>> => {
  return client.post("/save_menu", menu);
};

export const postCopyMenu = (
  copyMenu: CopyMenuRequest
): Promise<AxiosResponse<CopyMenuRequest, AxiosError>> => {
  return client.post("/copy_menu", copyMenu);
};

export const changeImage = (
  image: ChangeImageRequest
): Promise<AxiosResponse<Image, AxiosError>> => {
  return client.post("/change_image", image);
};

export const postUpdateProdOptVisibility = (
  params: UpdateProdOptVisibilityRequest
): Promise<AxiosResponse<Array<number>, AxiosError>> => {
  return client.post("/save_prod_opt_visibility", params);
};

export const downloadProductVisibilityHistories = (menuId: number) => {
  // Configure the request for a binary response (blob)
  const config = {
    responseType: "blob" as const,
    params: {
      MenuId: menuId,
    },
  };

  client
    .get<Blob>(`/product_visibility_histories`, config)
    .then((response: AxiosResponse<Blob>) => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `ProductVisibilityHistories_${new Date().toLocaleString("hu-HU")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading the file:", error);
    });
};

export type States = {
  ID: number;
  Version: number;
  KioskId: number;
  HardwareType: string; // this should be enum?
  Data: {
    Status: string;
    Command: string;
    Response: string;
    Message: string;
  };
  LogCreatedAt: string;
  CreatedAt: string;
};

export type RestaurantSystemStates = {
  ID: number;
  Version: number;
  KioskId: number;
  SessionId: number;
  RestaurantSystemType: string;
  Data: {
    Status: string;
    Command: string;
    Response: string;
  };
  LogCreatedAt: string;
  CreatedAt: string;
};

export type KioskLog = {
  AEEStates: Array<States>;
  AEECriticalStatuses: Array<string> | null;
  AEEInfos: Array<string> | null;
  RestaurantSystemStates: Array<RestaurantSystemStates>;
  CardReaderStates: Array<States>;
  BarCodeReaderStates: Array<States>;
  UPStates: Array<States>;
  CardReaderStatus: string;
  CardReaderLastPayErrors: Array<string> | null;
  AEEStatus: string;
  UPSStatus: string;
  UPSCriticalStatuses: Array<string> | null;
  RestaurantSystemStatus: string;
  BarCodeReaderStatus: string;
  Name: string;
  OrderStatus: string;
};

export type Status = {
  status: string;
};

export type CommandResponse = {
  status: string;
  error_msg: string;
};

export const getKioskLogs = (
  balenaId: string | undefined,
  token: string | undefined | null
): Promise<AxiosResponse<KioskLog, AxiosError>> => {
  if (token !== undefined && token !== null) {
    return client.get(`/log?balena_id=${balenaId ?? ""}&token=${token}`);
  }
  return client.get(`/log?balena_id=${balenaId ?? ""}`);
};

export const postKioskCommand = (body: {
  balenaId: string | undefined;
  command: string;
  token: string | undefined | null;
  params: unknown;
}): Promise<AxiosResponse<CommandResponse, AxiosError>> => {
  if (body.token !== undefined && body.token !== null) {
    return client.post(`/command?token=${body.token}`, {
      balena_id: body.balenaId,
      command: body.command,
      params: body.params,
    });
  }
  return client.post("/command", {
    balena_id: body.balenaId,
    command: body.command,
    params: body.params,
  });
};

export const postPing = (
  balenaId: string | undefined,
  token: string | undefined | null
): Promise<AxiosResponse<Status, AxiosError>> => {
  if (token !== undefined && token !== null) {
    return client.post(`/ping?token=${token}`, {
      balena_id: balenaId,
    });
  }
  return client.post("/ping", {
    balena_id: balenaId,
  });
};

export type Kiosk = {
  BalenaID: string;
  Name: string;
  IsOnline: boolean;
  AEEStatus: string;
  CardReaderStatus: string;
  BarCodeReaderStatus: string;
  UPSStatus: string;
  RestaurantSystemStatus: string;
  CurrentSessionID: number;
  OrderStatus: string;
};

export const getKiosks = (
  locationId: number
): Promise<AxiosResponse<{ kiosks: Array<Kiosk> }, AxiosError>> => {
  return client.get(`/kiosks_admin?LocationId=${locationId}`);
};

export const postOpeningTimes = (
  params: UpdateOpeningTimesRequest
): Promise<AxiosResponse<OpeningTimesStruct, AxiosError>> => {
  return client.post("/save_opening_times", params);
};

export const postClosedDays = (
  params: UpdateClosedDaysRequest
): Promise<AxiosResponse<string[], AxiosError>> => {
  return client.post("/save_closed_days", params);
};
