import { Col, Row, Typography, Tooltip } from "antd";
import { FC, useCallback } from "react";
import { emptyTranslatedString } from "../../editor/empty";
import { TranslatedString } from "../../lib/scalars";
import { CheckboxNull } from "./ChechboxNull";
import { InputString } from "./InputString";
import TextArea from "antd/es/input/TextArea";
import { InfoCircleOutlined } from "@ant-design/icons";

export type InputTranslatedProps = {
  label: string;
  value: TranslatedString | null;
  disabled?: boolean;
  onChange?: (value: TranslatedString | null) => void;
  nullable?: boolean;
  multiLine?: boolean;
  tooltipText?: string;
  hidden?: boolean;
  hiddenLanguages?: string[];
};

export const InputTranslated: FC<InputTranslatedProps> = ({
  label,
  value,
  disabled,
  onChange: onTranslationChange,
  nullable,
  multiLine,
  tooltipText,
  hidden,
  hiddenLanguages,
}: InputTranslatedProps) => {
  const renderedValue = value !== null ? value : emptyTranslatedString();
  const formatDataForChange = useCallback(
    (language: string) => (newValue: string | null) => {
      const dataForChange = {
        ...value,
        [language]: newValue,
      } as TranslatedString;
      onTranslationChange !== undefined && onTranslationChange(dataForChange);
    },
    [onTranslationChange, value]
  );

  const onTextAreaChange = useCallback(
    (language: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const dataForChange = {
        ...value,
        [language]: e.target.value,
      } as TranslatedString;
      onTranslationChange !== undefined && onTranslationChange(dataForChange);
    },
    [onTranslationChange, value]
  );

  const cols = Object.keys(renderedValue).map((language) => {
    if (multiLine === true) {
      return (
        <Col
          span={6}
          key={language}
          hidden={hiddenLanguages?.includes(language)}
        >
          <TextArea
            value={renderedValue[language]}
            placeholder={language.toUpperCase()}
            onChange={onTextAreaChange(language)}
            style={{ marginBottom: 5, marginTop: 8 }}
          />
        </Col>
      );
    }
    return (
      <Col span={6} key={language} hidden={hiddenLanguages?.includes(language)}>
        <InputString
          label=""
          suffix={
            <Typography.Text disabled>{language.toUpperCase()}</Typography.Text>
          }
          value={renderedValue[language]}
          onChange={formatDataForChange(language)}
          disabled={disabled}
        />
      </Col>
    );
  });

  const onCheckBoxChange = useCallback(
    (value: Record<string, string> | null) => {
      if (onTranslationChange !== undefined) {
        console.log(value);
        if (value === null) {
          onTranslationChange(null);
        } else {
          onTranslationChange(value);
        }
      }
    },
    [onTranslationChange]
  );

  const renderLabelWithTooltip = () => (
    <>
      {tooltipText !== undefined && (
        <Tooltip title={tooltipText} placement="topLeft">
          <h3 style={{ marginBottom: 0, width: "fit-content" }}>
            {label}
            <InfoCircleOutlined
              style={{ fontSize: 12, position: "absolute", marginLeft: 3 }}
            />
          </h3>
        </Tooltip>
      )}
      {tooltipText === undefined && (
        <h3 style={{ marginBottom: 0, width: "fit-content" }}>{label}</h3>
      )}
    </>
  );

  return (
    <div
      style={{
        marginBottom: 5,
        marginTop: 5,
        display: hidden === true ? "none" : "auto",
      }}
    >
      {nullable === true && (
        <CheckboxNull
          value={value}
          onChange={onCheckBoxChange}
          defaultValue={emptyTranslatedString()}
        >
          {renderLabelWithTooltip()}
        </CheckboxNull>
      )}
      {nullable !== true && renderLabelWithTooltip()}
      {value !== null && <Row gutter={8}>{cols}</Row>}
    </div>
  );
};
