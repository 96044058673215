import React, { CSSProperties, ReactNode } from "react";

export type PageHeaderProps = {
  title: string;
  extra?: ReactNode;
  style?: CSSProperties;
};

const PageHeader = ({ title, extra, style }: PageHeaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 12,
        alignItems: "center",
        marginTop: 12,
        ...style,
      }}
    >
      <h2 style={{ display: "inline", margin: 0 }}>{title}</h2>
      <div>{extra}</div>
    </div>
  );
};

const PageHeaderMemo = React.memo(PageHeader);
export { PageHeaderMemo as PageHeader };
