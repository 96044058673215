import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { FC, useCallback } from "react";
import {
  Image as ImageGenerated,
  ImageSource,
} from "../../lib/schema.generated";
import { CheckboxNull } from "./ChechboxNull";

export type ImageProps = {
  label: string;
  value: ImageGenerated | null;
  mediaFiles: ImageSource[];
  onChange?: (value: ImageGenerated | null) => void;
  nullable?: boolean;
  disabled?: boolean;
  hidden?: boolean;
};

export const Image: FC<ImageProps> = ({
  label,
  value,
  mediaFiles,
  nullable,
  onChange: onImageChange,
  disabled,
  hidden,
}: ImageProps) => {
  const onChange = useCallback(
    (selectedValue: string) => {
      const selectedMediaFile = mediaFiles.find(
        (mf) => mf.value === selectedValue
      );

      if (selectedMediaFile === undefined)
        throw new Error(`mediaFile '${selectedValue} not found'`);

      const nextValue: ImageGenerated = {
        original: selectedMediaFile,
        generated: null,
      };

      if (onImageChange !== undefined) onImageChange(nextValue);
    },
    [mediaFiles, onImageChange]
  );

  const filterOption = useCallback(
    (inputValue: string, option?: DefaultOptionType) =>
      option !== undefined &&
      (option.label ?? "")
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          inputValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        ),
    []
  );

  const onCheckBoxChange = useCallback(
    (value: ImageGenerated | null) => {
      if (value === null && onImageChange !== undefined) {
        onImageChange(null);
      } else if (value !== null) {
        onChange(value.original.value);
      }
    },
    [onChange, onImageChange]
  );

  if (mediaFiles.length === 0) return <p>media files uploaded yet</p>;

  let renderedValue = "";

  if (value !== null) {
    const currentMediaFile = mediaFiles.find(
      (mf) => mf.value === value.original.value
    );

    if (currentMediaFile !== undefined) renderedValue = currentMediaFile.value;
  }

  return (
    <div
      style={{
        marginBottom: 5,
        marginTop: 5,
        display: hidden === true ? "none" : "auto",
      }}
    >
      {nullable === true && (
        <CheckboxNull
          value={value}
          defaultValue={{
            original: {
              value: mediaFiles[0].value,
              label: mediaFiles[0].label,
            },
            generated: null,
          }}
          onChange={onCheckBoxChange}
        >
          <h3>{label}</h3>
        </CheckboxNull>
      )}
      {nullable !== true && <h3>{label}</h3>}
      {value !== null && (
        <Select
          disabled={disabled}
          allowClear
          style={{ width: "100%" }}
          showSearch
          placeholder="select a file"
          optionFilterProp="label"
          onChange={onChange}
          filterOption={filterOption}
          options={mediaFiles}
          dropdownMatchSelectWidth={false}
          value={renderedValue}
        />
      )}
    </div>
  );
};
