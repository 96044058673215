import { Badge, Button } from "antd";
import React, { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainRoutes, DashboardRoutes, extraPath } from "../../Router";
import { Kiosk, getKiosks } from "../../api/client";
import { useQuery } from "@tanstack/react-query";
import { PulseCard } from "./style";
import { PageHeader } from "../../components/PageHeader";

const ONLINE_COLOR = "#52C41A";
const OFFLINE_COLOR = "#F5222D";

function KioskList() {
  const navigation = useNavigate();
  const params = useParams();
  const [kiosks, setKiosks] = React.useState<Array<Kiosk>>([]);
  const { data: kioskData } = useQuery({
    queryKey: ["kiosks"],
    queryFn: () =>
      getKiosks(
        params.locationId !== undefined ? parseInt(params.locationId) : 0
      ),
    refetchInterval: 2000,
  });
  /*queryFn: () =>
    getLocations(
      clientContext.selectedClient !== null
        ? clientContext.selectedClient.Id
        : 0
    ),*/
  useEffect(() => {
    if (kioskData !== undefined) {
      setKiosks(kioskData.data.kiosks);
    }
  }, [kioskData]);

  const onClick = useCallback(
    (i: number) => () => {
      if (params.locationId !== undefined) {
        navigation(
          "/" +
            MainRoutes.DASHBOARD +
            "/" +
            DashboardRoutes.KIOSKS +
            "/" +
            params.locationId +
            "/" +
            kiosks[i].BalenaID
          //dashboard/kiosks/3/kiosk//d26421aef6f855d53f8a2343a70d3adc
        );
      } else {
        console.log("locationId is undefined");
      }
    },
    [navigation, kiosks, params]
  );

  const onBack = useCallback(() => {
    navigation("/dashboard/kiosks");
  }, [navigation]);

  const renderDeviceCards = (): JSX.Element[] => {
    const elementsToRender: JSX.Element[] = [];
    for (let i = 0; i < kiosks.length; i++) {
      elementsToRender.push(
        <PulseCard
          key={"kiosk" + i.toString()}
          title={kiosks[i].Name}
          pulse={kiosks[i].OrderStatus === "WAIT_UNLOCK" ? true : false}
          //headStyle={{ backgroundColor: "black" }}
          extra={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                //backgroundColor: "black",
              }}
            >
              <span>SessionID: {kiosks[i].CurrentSessionID}</span>
              {/* TODO: this is not used  currently we need the information from balena to use this */}
              {/* <Badge
                status="processing"
                color={kiosks[i].IsOnline ? ONLINE_COLOR : OFFLINE_COLOR}
                text="Online"
              /> */}
            </div>
          }
          style={{
            width: 300,
            margin: "20px",
            /*backgroundColor: "pink",*/
          }}
          onClick={onClick(i)}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 100,
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: 5,
                backgroundColor: "white",
              }}
            >
              <Badge
                status="processing"
                color={
                  kiosks[i].AEEStatus === "READY" ? ONLINE_COLOR : OFFLINE_COLOR
                }
                text="AEE"
              />
            </div>
            <div
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 100,
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: 5,
                backgroundColor: "white",
              }}
            >
              <Badge
                status="processing"
                color={
                  kiosks[i].CardReaderStatus === "READY"
                    ? ONLINE_COLOR
                    : OFFLINE_COLOR
                }
                text="TERMINAL"
              />
            </div>
            <div
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 100,
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: 5,
                backgroundColor: "white",
              }}
            >
              <Badge
                status="processing"
                color={
                  kiosks[i].UPSStatus === "READY" ? ONLINE_COLOR : OFFLINE_COLOR
                }
                text="UPS"
              />
            </div>
            <div
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 100,
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: 5,
                backgroundColor: "white",
              }}
            >
              <Badge
                status="error"
                color={
                  kiosks[i].BarCodeReaderStatus === "READY"
                    ? ONLINE_COLOR
                    : OFFLINE_COLOR
                }
                text="BARCODE"
              />
            </div>
            <div
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                minWidth: 100,
                width: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                margin: 5,
                backgroundColor: "white",
              }}
            >
              <Badge
                status="processing"
                color={
                  kiosks[i].RestaurantSystemStatus === "READY"
                    ? ONLINE_COLOR
                    : OFFLINE_COLOR
                }
                text="RESTAURANT SYSTEM"
              />
            </div>
          </div>
        </PulseCard>
      );
    }
    return elementsToRender;
  };
  return (
    <div>
      {/*<Divider orientation="left">Kioskok</Divider>*/}
      <PageHeader
        title="Kiosks"
        extra={
          <Button onClick={onBack} type="primary">
            Back
          </Button>
        }
      />
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {renderDeviceCards()}
        </div>
      </div>
    </div>
  );
}

export default KioskList;
