import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Table, Tooltip, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { ITEMS_PER_PAGE } from "../lib/settings";
import { getLocations, postDeployMenu } from "../api/client";
import { Location, ActiveMenu, Kiosk, UserRole } from "../generated";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useClientContext } from "../editor/context";

export const KioskLocations: React.FC = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState<Array<Location>>([]);
  const clientContext = useClientContext();

  const {
    data: locationData,
    isLoading: isLocationsLoading,
    refetch: fetchLocations,
  } = useQuery({
    queryKey: ["locations", clientContext.selectedClient?.Id],
    queryFn: () =>
      getLocations(
        clientContext.selectedClient !== null
          ? clientContext.selectedClient.Id
          : 0
      ),
    enabled: false,
  });
  const { data: deployMenuData, mutate: doDeployMenu } = useMutation({
    mutationKey: ["deployMenu"],
    mutationFn: postDeployMenu,
  });

  useEffect(() => {
    if (deployMenuData !== undefined) {
      void message.success("Menu deployed");
      void fetchLocations();
    }
  }, [deployMenuData, fetchLocations]);

  useEffect(() => {
    if (clientContext.selectedClient !== null) {
      void fetchLocations();
    }
  }, [fetchLocations, clientContext.selectedClient]);

  useEffect(() => {
    if (locationData !== undefined) {
      setLocations(locationData.data);
    }
  }, [locationData]);

  const basicColums: ColumnsType<Location> = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Last deployed",
      dataIndex: "LastDeploy",
      key: "LastDeploy",
      render: (lastDeploy: string | null) => {
        if (lastDeploy === null) return "No last deploy";
        return DateTime.fromISO(lastDeploy).toFormat("yyyy-MM-dd HH:mm");
      },
    },
  ];

  const onRow = useCallback(
    (location: Location) => {
      return {
        onClick: () => {
          navigate(`/dashboard/kiosks/${location.Id}`);
        },
      };
    },
    [navigate]
  );

  if (isLocationsLoading) return null;

  return (
    <Fragment>
      <PageHeader title="Locations" />

      <Table
        onRow={onRow}
        rowKey="Id"
        columns={basicColums}
        tableLayout="auto"
        sticky={true}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        dataSource={locations.sort((a, b) => a.Id - b.Id)}
        sortDirections={["descend", "ascend"]}
      />
    </Fragment>
  );
};
