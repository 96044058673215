import React, { Dispatch, useContext } from "react";
import { EditorAction } from "./logic";
import { Client, User } from "../generated";

export type EditorContextType = {
  dispatch: Dispatch<EditorAction>;
};

export const emptyEditorContext: EditorContextType = {
  dispatch: () => void 0,
};

export const EditorContext =
  React.createContext<EditorContextType>(emptyEditorContext);

export const EditorContextProvider = EditorContext.Provider;

export const useEditorContext = () => {
  return useContext<EditorContextType>(EditorContext);
};

export type ClientContextType = {
  selectedClient: Client | null;
};

export const ClientContext = React.createContext<ClientContextType>({
  selectedClient: null,
});

export const ClientContextProvider = ClientContext.Provider;

export const useClientContext = () => {
  return useContext<ClientContextType>(ClientContext);
};

export type UserContextType = {
  user: User | null;
};

export const UserContext = React.createContext<UserContextType>({
  user: null,
});

export const UserContextProvider = UserContext.Provider;

export const useUserContext = () => {
  return useContext<UserContextType>(UserContext);
};
