import React, { Fragment, useCallback } from "react";

import { Button, Col, Row, Table, TableProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { ActionButton, InputString } from "../editor/components";
import { TranslatedString } from "../lib/scalars";
import {
  Option,
  OptionPresentation,
  TaxRate,
  OptionVendel,
} from "../lib/schema.generated";

import { useVapianoConfigContext } from "./vapianoConfigContext";
import { ITEMS_PER_PAGE, emptyTranslatedString } from "../lib/settings";
import { useEditorContext } from "../editor/context";
import { useQueryParam } from "use-query-params";
import { OwnStringParam, OwnNumberParam, SortParam } from "../utils";
import { SortOrder } from "antd/es/table/interface";

export const Options: React.FC = () => {
  const ctx = useVapianoConfigContext();
  const ctxEditor = useEditorContext();
  const navigate = useNavigate();
  //const [searchValue, setSearchValue] = React.useState<string>("");

  const [page, setPage] = useQueryParam("page", OwnNumberParam);
  const [searchValue, setSearchValue] = useQueryParam(
    "keyword",
    OwnStringParam
  );
  const [sort, setSort] = useQueryParam("order", SortParam);

  const getQueryParams = useCallback((): string => {
    const params: Record<string, string> = {};
    page !== null && page !== undefined
      ? (params["page"] = page.toString())
      : null;
    searchValue !== "" ? (params["keyword"] = searchValue) : null;
    sort !== undefined
      ? (params["order"] = `${sort.columnKey}_${sort.order.substring(0, 3)}`)
      : null;
    return createSearchParams(params).toString();
  }, [page, searchValue, sort]);

  const columns: ColumnsType<Option> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: Option, b: Option) => a.id - b.id,
      sortOrder:
        sort !== undefined && sort.columnKey === "id"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "External ID",
      dataIndex: "vendel",
      key: "vendel",
      render: (vendel: OptionVendel) => vendel.externalId,
    },
    /*{
      title: "Index",
      dataIndex: "id",
      key: "id",
      render: (v: number) => {
        return ctx.document.menu.options.findIndex((option) => option.id === v);
      },
      sorter: (a: Option, b: Option) => {
        const indexA = ctx.document.menu.options.findIndex(
          (option) => option.id === a.id
        );
        const indexB = ctx.document.menu.options.findIndex(
          (option) => option.id === b.id
        );

        return indexA - indexB;
      },
    },*/
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name[ctx.useLanguageKey],
      sorter: (a: Option, b: Option) =>
        a.name[ctx.useLanguageKey].localeCompare(b.name[ctx.useLanguageKey]),
      sortOrder:
        sort !== undefined && sort.columnKey === "name"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "EditorName",
      dataIndex: "editorName",
      key: "editorName",
      sorter: (a: Option, b: Option) =>
        a.editorName.localeCompare(b.editorName),
      sortOrder:
        sort !== undefined && sort.columnKey === "editorName"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_id: number, data: Option) => {
        const optionIndex = ctx.document.menu.options.findIndex(
          (option) => option.id === data.id
        );
        return (
          <ActionButton
            label="Remove"
            action="remove"
            type="dashed"
            dataPath={["menu", "options"]}
            value={ctx.document.menu.options}
            defaultValue={optionIndex}
          />
        );
      },
    },
  ];

  const onRow = useCallback(
    (option: Option) => {
      return {
        onClick: () => {
          navigate(`${option.id}`, {
            state: {
              queryParams: getQueryParams(),
            },
          });
        },
      };
    },
    [navigate, getQueryParams]
  );

  const onSearchValueChange = useCallback(
    (value: string | null) => {
      if (value !== null) {
        setSearchValue(value);
      } else {
        setSearchValue("");
      }
    },
    [setSearchValue]
  );

  const ids = ctx.document.menu.options.map((option) => option.id);
  const maxId = Math.max(...ids);

  const onAddNewOptionClick = useCallback(() => {
    const newOption: Option = {
      id: maxId === Infinity || maxId === -Infinity ? 1 : maxId + 1,
      name: emptyTranslatedString,
      editorName: "",
      mobile: null,
      description: null,
      presentation: OptionPresentation.Image,
      properties: [],
      taxRates: {
        dineIn: TaxRate.A,
        takeAway: TaxRate.A,
      },
      taxModifier: null,
      vendel: {
        externalId: "",
      },
    };

    ctxEditor.dispatch({
      type: "addItem",
      path: ["menu", "options"],
      item: newOption,
    });
    navigate(`${newOption.id}`, {
      state: {
        queryParams: getQueryParams(),
      },
    });
  }, [ctxEditor, maxId, navigate, getQueryParams]);

  const onTableChange: TableProps<Option>["onChange"] = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pagination,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filters,
    sorter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extra
  ) => {
    setSort(sorter as { order: string; columnKey: string });
  };

  return (
    <Fragment>
      <PageHeader
        title="Options"
        extra={
          <Button type="primary" onClick={onAddNewOptionClick}>
            Add New Option
          </Button>
        }
      />
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        <Col span={8}>
          <InputString
            placeholder="Search in name"
            value={searchValue}
            onChange={onSearchValueChange}
          />
        </Col>
      </Row>
      <Table
        onRow={onRow}
        rowKey="id"
        columns={columns}
        tableLayout="auto"
        sticky={true}
        showSorterTooltip={false}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onTableChange}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          current: page !== null && page !== undefined ? page : 1,
          onChange: (page) => {
            setPage(page);
          },
        }}
        dataSource={ctx.document.menu.options.filter((option) => {
          return (
            option.name["hu"]
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            option.name["en"]
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            option.editorName
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            option.id === Number(searchValue) ||
            (option.vendel.externalId !== null &&
              option.vendel.externalId.toString() ===
                searchValue.toLocaleLowerCase())
          );
        })}
      />
    </Fragment>
  );
};
