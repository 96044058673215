// we need to use the non-secure variant, because secure variant uses hw crypto module

import {
  Menu,
  Option,
  Product,
  ProductPresentation,
  Scalars,
  Maybe,
  Step,
  StepOption,
} from "./schema.generated";
import { emptyTranslatedString } from "./settings";

export const unique = <T>(list: T[]): T[] => {
  return list.filter((item, index) => list.indexOf(item) === index);
};

// compare to javascript Dates based on year, month and date
export const compareDates = (a: string, b: Date): number => {
  const dateA = new Date(a);
  if (dateA.getFullYear() !== b.getFullYear())
    return dateA.getFullYear() - b.getFullYear();

  if (dateA.getMonth() !== b.getMonth()) return dateA.getMonth() - b.getMonth();

  if (dateA.getDate() !== b.getDate()) return dateA.getDate() - b.getDate();

  return 0;
};

export const round = (value: number, roundToDecimals: number): number => {
  const scale = Math.pow(10, roundToDecimals);
  return Math.round(value * scale) / scale;
};

// TODO: check this function, there is an any at the moment
export const setProperty = <T>(obj: T, path: string, value: unknown): T => {
  const [head, ...rest] = path.split(".");

  return {
    ...obj,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    [head]:
      rest.length > 0
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
          setProperty((obj as any)[head], rest.join("."), value)
        : value,
  };
};

// TODO: only one step matters for the product or should we check all steps?
export const canUserTurnOffOptionOnly = (
  optionId: number,
  disabledOptionIds: Array<number>,
  disabledProductIds: Array<number>,
  currentMenu: Menu
): Array<Product> => {
  const productsToTurnOff: Array<Product> = [];
  for (const product of currentMenu.products) {
    // product presentation is configurable and product is not disabled
    if (
      product.presentation === ProductPresentation.Configurable &&
      !disabledProductIds.includes(product.id)
    ) {
      for (const productStep of product.productSteps) {
        const currentStep = currentMenu.steps.find(
          (x: Step) => x.id === productStep.stepId
        );
        if (currentStep !== undefined) {
          // find optionId in stepOptions and match the step rules
          const currentOption = currentStep.stepOptions.find(
            (x: StepOption) => x.optionId === optionId
          );
          if (
            currentOption !== undefined &&
            currentOption.min !== null &&
            currentOption.min > 0
          ) {
            // turn off product
            productsToTurnOff.push(product);
          } else if (currentOption !== undefined) {
            // check if all other options are disabled
            const otherOptions = currentStep.stepOptions.filter(
              (x: StepOption) =>
                x.optionId !== optionId &&
                !disabledOptionIds.includes(x.optionId)
            );
            let maxSelectableOptions = 0;
            for (const option of otherOptions) {
              if (option.max !== null) {
                maxSelectableOptions += option.max;
              }
            }
            if (otherOptions.length === 0) {
              productsToTurnOff.push(product);
            } else if (
              currentStep.minTotal !== null &&
              currentStep.minTotal < maxSelectableOptions
            ) {
              productsToTurnOff.push(product);
            }
          }
        }
      }
    }
  }
  return productsToTurnOff;
};

export const canUserTurnOffProductOnly = (
  productId: number,
  disabledOptionIds: Array<number>,
  currentMenu: Menu
): Array<Option> => {
  const optionsThatBlockProductTurnOff: Array<Option> = [];
  // find current product
  const currentProduct = currentMenu.products.find(
    (product: Product) => product.id === productId
  );
  if (
    currentProduct !== undefined &&
    currentProduct.presentation === ProductPresentation.Configurable
  ) {
    for (const productStep of currentProduct.productSteps) {
      const currentDisabledOptionIds: Array<number> = [];
      const currentStep = currentMenu.steps.find(
        (x: Step) => x.id === productStep.stepId
      );
      if (currentStep !== undefined) {
        const optionIds = currentStep.stepOptions.map(
          (x: StepOption) => x.optionId
        );
        for (const optionId of optionIds) {
          if (disabledOptionIds.includes(optionId)) {
            currentDisabledOptionIds.push(optionId);
          }
        }
        if (currentDisabledOptionIds.length > 0) {
          // we found options that are disabled so we need to check if the steps rules are met
          // options that are not disabled
          const currentOptions = currentStep.stepOptions.filter(
            (x: StepOption) => !currentDisabledOptionIds.includes(x.optionId)
          );
          let maxSelectableOptions = 0;
          for (const option of currentOptions) {
            if (option.max !== null) {
              maxSelectableOptions += option.max;
            }
          }
          if (currentOptions.length === 0) {
            currentDisabledOptionIds.forEach((optionId: number) => {
              if (!currentDisabledOptionIds.includes(optionId))
                currentDisabledOptionIds.push(optionId);
            });
          } else if (
            currentStep.minTotal !== null &&
            currentStep.minTotal < maxSelectableOptions
          ) {
            currentDisabledOptionIds.forEach((optionId: number) => {
              if (!currentDisabledOptionIds.includes(optionId))
                currentDisabledOptionIds.push(optionId);
            });
          }
        }
      }
      for (const optionId of currentDisabledOptionIds) {
        // find option
        const option = currentMenu.options.find(
          (x: Option) => x.id === optionId
        );
        if (option !== undefined) {
          optionsThatBlockProductTurnOff.push(option);
        }
      }
    }
  }
  return optionsThatBlockProductTurnOff;
};

export const expandTranslatedString = (
  trans: Scalars["TranslatedString"] | Maybe<Scalars["TranslatedString"]>
): Scalars["TranslatedString"] => {
  return {
    ...emptyTranslatedString,
    ...trans,
  };
};
