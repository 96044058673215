import { z } from "zod";
import { TranslatedString } from "./scalars";
import {
  Menu,
  Option,
  Product,
  ProductLabels,
  ProductPresentation,
  Step,
  StepOption,
  TaxRate,
} from "./schema.generated";
import { BuildMode, buildMode, showPossibleTaxRateWarnings } from "./settings";

// TODO:
// if product presentation is simple, it should not have steps in it

// entity with id must have a unique id
export const refinementKeysMustExistFactory =
  (keys: readonly string[]) =>
  (str: TranslatedString, ctx: z.RefinementCtx) => {
    keys.forEach((key) => {
      const index = Object.keys(str).findIndex(
        (translationKey) => translationKey === key
      );

      if (index === -1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `translation key '${key}' must exist`,
        });
      }
    });
  };

export interface HasId {
  id: number;
}

// entity with id must have a unique id
export const refinementUniqueId = (list: HasId[], ctx: z.RefinementCtx) => {
  const ids = list.map((item) => item.id);

  ids.forEach((id, index) => {
    if (ids.indexOf(id) !== index)
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `duplicate id ${id}`,
        path: [index],
      });
  });
};

// productSteps must have a unique stepId
export const refinementUniqueStepId = (
  product: Product,
  ctx: z.RefinementCtx
) => {
  const ids = product.productSteps.map((productStep) => productStep.stepId);

  product.productSteps.forEach((productStep, index) => {
    if (ids.indexOf(productStep.stepId) !== index) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `duplicate stepId ${productStep.stepId} within productSteps`,
        path: ["productSteps", index],
      });
    }
  });
};

// stepOptions must have a unique optionId
export const refinementUniqueOptionId = (step: Step, ctx: z.RefinementCtx) => {
  const ids = step.stepOptions.map((step) => step.optionId);

  step.stepOptions.forEach((stepOption, index) => {
    if (ids.indexOf(stepOption.optionId) !== index) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `duplicate optionId ${stepOption.optionId} within stepOptions`,
        path: ["stepOptions", index],
      });
    }
  });
};

export interface EntityWithPosition {
  position: number | null;
}

// entity with position must have a unique position, so it is clearly orderable based on position
export const refinementUniquePosition = (
  list: EntityWithPosition[],
  ctx: z.RefinementCtx
) => {
  const ids = list.map((ps) => ps.position);

  list.forEach((entity, index) => {
    if (entity.position === null) return;

    if (ids.indexOf(entity.position) !== index) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `duplicate position ${entity.position}`,
        path: [index],
      });
    }
  });
};

// productStep's stepId references a valid step
export const refinementStepIdExistsInProductStep = (
  menu: Menu,
  ctx: z.RefinementCtx
) => {
  const idSet = new Set(menu.steps.map((step) => step.id));

  menu.products.forEach((product, productIndex) => {
    product.productSteps.map((productStep, productStepIndex) => {
      if (!idSet.has(productStep.stepId)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `step in productStep with stepId ${productStep.stepId} does not exist`,
          path: ["products", productIndex, "productSteps", productStepIndex],
        });
      }
    });
  });
};

// stepOption's optionId references a valid option
export const refinementOptionIdExistsInStepOption = (
  menu: Menu,
  ctx: z.RefinementCtx
) => {
  const idSet = new Set(menu.options.map((option) => option.id));

  menu.steps.forEach((step, stepIndex) => {
    step.stepOptions.forEach((so, stepOptionIndex) => {
      if (!idSet.has(so.optionId)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `option in stepOption with optionId ${so.optionId} does not exist`,
          path: ["steps", stepIndex, "stepOptions", stepOptionIndex],
        });
      }
    });
  });
};

// category's productIds and upsellProductIds lists reference valid products
export const refinementProductIdExistsInCategoryKeyFactory =
  (key: "productIds" | "upsellProductIds") =>
  (menu: Menu, ctx: z.RefinementCtx) => {
    const idSet = new Set(menu.products.map((product) => product.id));

    menu.categories.forEach((category) => {
      const list = category[key];

      if (list === undefined || list === null) return;

      list.forEach((id) => {
        if (!idSet.has(id)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `category with id ${category.id} has product id ${id} in '${key}' which does not exist`,
          });
        }
      });
    });
  };

// stepOption limits are valid
export const refinementStepOptionLimits = (
  stepOption: StepOption,
  ctx: z.RefinementCtx
) => {
  // min <= max
  if (
    stepOption.min !== null &&
    stepOption.max !== null &&
    stepOption.min > stepOption.max
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `min ${stepOption.min} must be <= max ${stepOption.max}`,
    });
  }

  // min <= defaultAmount
  if (
    stepOption.min !== null &&
    stepOption.defaultAmount !== null &&
    stepOption.min > stepOption.defaultAmount
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `min ${stepOption.min} must be <= defaultAmount ${stepOption.defaultAmount}`,
    });
  }

  // defaultAmount <= max
  if (
    stepOption.defaultAmount !== null &&
    stepOption.max !== null &&
    stepOption.defaultAmount > stepOption.max
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `defaultAmount ${stepOption.defaultAmount} must be <= max ${stepOption.max}`,
    });
  }
};

// productStep's limits are valid
export const refinementProductStepLimits = (
  step: Step,
  ctx: z.RefinementCtx
) => {
  // minTotal <= maxTotal
  if (
    step.minTotal !== null &&
    step.maxTotal !== null &&
    step.minTotal > step.maxTotal
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `minTotal ${step.minTotal} must be <= maxTotal ${step.maxTotal}`,
    });
  }

  // minDistinct <= maxDistinct
  if (
    step.minDistinct !== null &&
    step.maxDistinct !== null &&
    step.minDistinct > step.maxDistinct
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `minDistinct ${step.minDistinct} must be <= maxDistinct ${step.maxDistinct}`,
    });
  }
};

// if productStep's availableForFulfillment must be compatible with its stepOptions' availableForFulfillment
export const refinementFulfillmentTypeMatchesProductStep = (
  product: Product,
  ctx: z.RefinementCtx
) => {
  product.productSteps.forEach((ps, index) => {
    if (
      product.availableForFulfillment !== null &&
      ps.availableForFulfillment.length > 0 &&
      !ps.availableForFulfillment.includes(product.availableForFulfillment)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `product with id ${product.id} has availableForFulfillment ${
          product.availableForFulfillment
        } which does not match productStep stepId ${
          ps.stepId
        } availableForFulfillment ${ps.availableForFulfillment.toString()}`,
        path: [index],
      });
    }
  });
};

// if product is configurable, then it should have at least one productStep
// (at least one stepOption will be required, but it is enforced by the schema)
export const refinementOnlyConfigurableProductHasProductSteps = (
  product: Product,
  ctx: z.RefinementCtx
) => {
  if (product.presentation === ProductPresentation.Configurable) {
    if (product.productSteps.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `product with id ${product.id} is configurable but has no productSteps`,
      });
    }
  } else if (product.presentation === ProductPresentation.Simple) {
    if (product.productSteps.length > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `product with id ${product.id} is simple but has productSteps`,
      });
    }
  } else {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `product with id ${product.id} has invalid presentation`,
    });
  }
};

export const refinementProductLabel = (menu: Menu, ctx: z.RefinementCtx) => {
  menu.products.forEach((product, productIndex) => {
    if (product.labels.length > 0) {
      if (product.labels.includes(ProductLabels.CustomConfig)) {
        // check that this is the only productLabel
        // the product must be configurable
        if (product.presentation !== ProductPresentation.Configurable) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `product with id ${product.id} has label ${ProductLabels.CustomConfig} but is not configurable`,
            path: ["products", productIndex],
          });
        }
        const numberOfProductsWithLabelCustomConfig = menu.products.filter(
          (p) =>
            p.id !== product.id && p.labels.includes(ProductLabels.CustomConfig)
        );
        if (numberOfProductsWithLabelCustomConfig.length > 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `product with id ${product.id} has label ${ProductLabels.CustomConfig} but there are other products with this label`,
            path: ["products", productIndex],
          });
        }
      }
    }
  });
};

// product's taxRates must match taxRates in all related options in productSteps

// export const refinementProductTaxRatesMatchesOptionsTaxRates = (
//   menu: Menu,
//   ctx: z.RefinementCtx
// ) => {
//   menu.products.forEach((product, productIndex) => {
//     product.productSteps.forEach((productStep, productStepIndex) => {
//       productStep.stepOptions.forEach((stepOption, stepOptionIndex) => {
//         const option = mustFindOption(menu, stepOption.optionId);

//         if (
//           product.taxRates.dineIn !== option.taxRates.dineIn ||
//           product.taxRates.takeAway !== option.taxRates.takeAway
//         ) {
//           ctx.addIssue({
//             code: z.ZodIssueCode.custom,
//             message: `product with id ${product.id} has incompatible taxRates with option with id ${stepOption.optionId}`,
//             path: [
//               "products",
//               productIndex,
//               "productSteps",
//               productStepIndex,
//               "stepOptions",
//               stepOptionIndex,
//             ],
//           });
//         }
//       });
//     });
//   });
// };

// multiple checks to make sure productSteps limits are compatible with its stepOptions' limits
export const refinementProductStepAndStepOptionLimits = (
  step: Step,
  ctx: z.RefinementCtx
) => {
  // for each stepOption
  step.stepOptions.forEach((stepOption, index) => {
    // min <= maxTotal
    if (
      stepOption.min !== null &&
      step.maxTotal !== null &&
      stepOption.min > step.maxTotal
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `min ${stepOption.min} must be <= step.maxTotal ${step.maxTotal}`,
        path: [index],
      });
    }

    // max >= minTotal
    if (
      stepOption.max !== null &&
      step.minTotal !== null &&
      stepOption.max < step.minTotal
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `max ${stepOption.max} must be >= productStep.minTotal ${step.minTotal}`,
        path: [index],
      });
    }
  });

  const minDistinctOptions = step.stepOptions.reduce(
    (acc, stepOption) =>
      stepOption.min !== null && stepOption.min > 0 ? acc + 1 : acc,
    0
  );

  const maxDistinctOptions = step.stepOptions.reduce(
    (acc, stepOption) =>
      stepOption.max !== null && stepOption.max > 0 ? acc + 1 : acc,
    0
  );

  // minDistinctOptions <= maxDistinct
  if (step.maxDistinct !== null && minDistinctOptions > step.maxDistinct) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `the number of options with min defined is ${minDistinctOptions}, which must be <= maxDistinct ${step.maxDistinct}`,
    });
  }

  // maxDistinctOptions >= minDistinct
  if (step.minDistinct !== null && maxDistinctOptions < step.minDistinct) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `the number of options with max defined is ${maxDistinctOptions}, which must be >= minDistinct ${step.minDistinct}`,
    });
  }

  const maxTotalOptions = step.stepOptions.reduce(
    (acc, stepOption) => (stepOption.max !== null ? acc + stepOption.max : acc),
    0
  );

  const minTotalOptions = step.stepOptions.reduce(
    (acc, stepOption) => (stepOption.min !== null ? acc + stepOption.min : acc),
    0
  );

  // minTotalOptions <= maxTotal
  if (step.maxTotal !== null && minTotalOptions > step.maxTotal) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `aggregated option min is ${minTotalOptions}, which must be <= maxTotal ${step.maxTotal}`,
    });
  }

  // maxTotalOptions >= minTotal
  if (step.minTotal !== null && maxTotalOptions < step.minTotal) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `aggregated option max is ${maxTotalOptions}, which must be >= minTotal ${step.minTotal}`,
    });
  }
};

// check if singleSelect step's limits and defaultAmount parameters are valid
export const refinementSingleSelectStepValid = (
  step: Step,
  ctx: z.RefinementCtx
) => {
  // if singleSelect is true, then minDistinct, maxDistinct, minTotal, maxTotal must be null
  if (step.singleSelect === true) {
    if (
      step.minDistinct !== null ||
      step.maxDistinct !== null ||
      step.minTotal !== null ||
      step.maxTotal !== null
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `singleSelect is true, so minDistinct, maxDistinct, minTotal, maxTotal must be null`,
      });
    }

    let totalDefaultAmount = 0;

    // stepOptions must have min and max null
    step.stepOptions.forEach((stepOption, index) => {
      totalDefaultAmount += stepOption.defaultAmount;

      if (stepOption.min !== null || stepOption.max !== null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `singleSelect is true, so stepOptions must have min and max null`,
          path: ["stepOptions", index],
        });
      }
    });

    // defaultAmount can't be greater then 1 for all options
    if (totalDefaultAmount > 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `singleSelect is true, so aggregated defaultAmount for all options in this step can't be greater then 1`,
      });
    }
  }
};

// check if productId is exists in any categories productIds
export const refinementProductIdExistsInCategory = (
  menu: Menu,
  ctx: z.RefinementCtx
) => {
  const productsNotFound: Array<{ productId: number; productIndex: number }> =
    [];
  const allProductIdsThatAreInCategories: Array<number> = [];
  for (const category of menu.categories) {
    allProductIdsThatAreInCategories.push(...category.productIds);
  }
  menu.products.forEach((product, productIndex) => {
    if (
      !allProductIdsThatAreInCategories.includes(product.id) &&
      !product.labels.includes(ProductLabels.CustomConfig)
    ) {
      productsNotFound.push({ productId: product.id, productIndex });
    }
  });
  for (const product of productsNotFound) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `product with id ${product.productId} is not in any category's productIds`,
      path: ["products", product.productIndex],
    });
  }
};

// check if stepId is exists in any product's productSteps
export const refinementStepIdExistsInProduct = (
  menu: Menu,
  ctx: z.RefinementCtx
) => {
  const stepsNotFound: Array<{ stepId: number; stepIndex: number }> = [];
  const allStepIdsThatAreInProducts: Array<number> = [];
  for (const product of menu.products) {
    if (product.presentation === ProductPresentation.Configurable) {
      allStepIdsThatAreInProducts.push(
        ...product.productSteps.map((productStep) => productStep.stepId)
      );
    }
  }
  menu.steps.forEach((step, stepIndex) => {
    if (!allStepIdsThatAreInProducts.includes(step.id)) {
      stepsNotFound.push({ stepId: step.id, stepIndex });
    }
  });
  for (const step of stepsNotFound) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `step with id ${step.stepId} is not in any product's productSteps`,
      path: ["steps", step.stepIndex],
    });
  }
};

// check if optionId is exists in any step's stepOptions
export const refinementOptionIdExistsInAnyStepOption = (
  menu: Menu,
  ctx: z.RefinementCtx
) => {
  const optionsNotFound: Array<{ optionId: number; optionIndex: number }> = [];
  const allOptionIdsThatAreInSteps: Array<number> = [];
  for (const step of menu.steps) {
    allOptionIdsThatAreInSteps.push(
      ...step.stepOptions.map((stepOption) => stepOption.optionId)
    );
  }
  menu.options.forEach((option, optionIndex) => {
    if (!allOptionIdsThatAreInSteps.includes(option.id)) {
      optionsNotFound.push({ optionId: option.id, optionIndex });
    }
  });
  for (const option of optionsNotFound) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `option with id ${option.optionId} is not in any step's stepOptions`,
      path: ["options", option.optionIndex],
    });
  }
};

// const vendelIdRegex = /^([0-9]|-){1,3}$/;

// // check the structure of a vendelId
// export const refinementValidVendelId = (
//   vendelId: string,
//   ctx: z.RefinementCtx
// ) => {
//   // empty, zero length vendelId string is okay
//   if (vendelId.length === 0) return;

//   const parts = vendelId.split(";");

//   // check if parts mathes regex
//   if (!parts.every((part) => vendelIdRegex.test(part))) {
//     ctx.addIssue({
//       code: z.ZodIssueCode.custom,
//       message: `invalid vendelId format`,
//     });
//   }
// };

// validate taxRates. if dineIn, it must always be A (5%), if takeAway than it
// must be C (27%) except for pizzas in which case it must be B (18%)
export const refinementHungarianTaxRates = (
  entity: Product | Option,
  ctx: z.RefinementCtx
) => {
  if (buildMode === BuildMode.Production || !showPossibleTaxRateWarnings)
    return;

  if (entity.taxRates.dineIn !== TaxRate.A) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `dineIn taxRate must be A`,
    });

    if (buildMode === BuildMode.Development) {
      console.log(
        "refinementHungarianTaxRates: dineIn taxRate must be A",
        entity
      );
    }
  }

  const isPizza = Object.keys(entity.name).some((key) =>
    entity.name[key].toLowerCase().includes("pizza")
  );

  if (isPizza) {
    if (entity.taxRates.takeAway !== TaxRate.B) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `takeAway pizza must have a taxRate of B`,
      });

      if (buildMode === BuildMode.Development) {
        console.log(
          "refinementHungarianTaxRates: takeAway pizza must have a taxRate of B",
          entity
        );
      }
    }
  } else {
    if (entity.taxRates.takeAway !== TaxRate.C) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `takeAway taxRate must be C`,
      });

      if (buildMode === BuildMode.Development) {
        console.log(
          "refinementHungarianTaxRates: takeAway taxRate must be C",
          entity
        );
      }
    }
  }
};
