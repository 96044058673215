import React, { Fragment, useCallback } from "react";

import { Button, Col, Row, Table, TableProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { ActionButton, InputString } from "../editor/components";
import { TranslatedString } from "../lib/scalars";
import { Category } from "../lib/schema.generated";
import { useVapianoConfigContext } from "./vapianoConfigContext";
import { ITEMS_PER_PAGE, emptyTranslatedString } from "../lib/settings";
import { useEditorContext } from "../editor/context";
import { useQueryParam } from "use-query-params";
import { OwnStringParam, OwnNumberParam, SortParam } from "../utils";
import { SortOrder } from "antd/es/table/interface";

export const Categories: React.FC = () => {
  const ctx = useVapianoConfigContext();
  const ctxEditor = useEditorContext();
  const navigate = useNavigate();
  //const [searchValue, setSearchValue] = React.useState<string>("");
  //const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});

  const [page, setPage] = useQueryParam("page", OwnNumberParam);
  const [searchValue, setSearchValue] = useQueryParam(
    "keyword",
    OwnStringParam
  );
  const [sort, setSort] = useQueryParam("order", SortParam);

  const getQueryParams = useCallback((): string => {
    const params: Record<string, string> = {};
    page !== null && page !== undefined
      ? (params["page"] = page.toString())
      : null;
    searchValue !== "" ? (params["keyword"] = searchValue) : null;
    sort !== undefined
      ? (params["order"] = `${sort.columnKey}_${sort.order.substring(0, 3)}`)
      : null;
    return createSearchParams(params).toString();
  }, [page, searchValue, sort]);

  const columns: ColumnsType<Category> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: Category, b: Category) => a.id - b.id,
      sortOrder:
        sort !== undefined && sort.columnKey === "id"
          ? (sort.order as SortOrder)
          : undefined,
    },
    /*{
      title: "Index",
      dataIndex: "id",
      key: "id",
      render: (v: number) => {
        return ctx.document.menu.categories.findIndex(
          (category) => category.id === v
        );
      },
      sorter: (a: Category, b: Category) => {
        const indexA = ctx.document.menu.categories.findIndex(
          (category) => category.id === a.id
        );
        const indexB = ctx.document.menu.categories.findIndex(
          (category) => category.id === b.id
        );

        return indexA - indexB;
      },
    },*/
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name[ctx.useLanguageKey],
      sorter: (a: Category, b: Category) =>
        a.name[ctx.useLanguageKey].localeCompare(b.name[ctx.useLanguageKey]),
      sortOrder:
        sort !== undefined && sort.columnKey === "name"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "EditorName",
      dataIndex: "editorName",
      key: "editorName",
      sorter: (a: Category, b: Category) =>
        a.editorName.localeCompare(b.editorName),
      sortOrder:
        sort !== undefined && sort.columnKey === "editorName"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      sorter: (a: Category, b: Category) =>
        (a.position !== null ? a.position : -Infinity) -
        (b.position !== null ? b.position : -Infinity),
      sortOrder:
        sort !== undefined && sort.columnKey === "position"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_id: number, data: Category) => {
        const categoryIndex = ctx.document.menu.categories.findIndex(
          (category) => category.id === data.id
        );
        return (
          <ActionButton
            label="Remove"
            action="remove"
            type="dashed"
            dataPath={["menu", "categories"]}
            value={ctx.document.menu.categories}
            defaultValue={categoryIndex}
          />
        );
      },
    },
  ];

  const onRow = useCallback(
    (category: Category) => {
      return {
        onClick: () => {
          navigate(`${category.id}`, {
            state: {
              queryParams: getQueryParams(),
            },
          });
        },
      };
    },
    [navigate, getQueryParams]
  );

  const onSearchValueChange = useCallback(
    (value: string | null) => {
      if (value !== null) {
        setSearchValue(value);
      } else {
        setSearchValue("");
      }
    },
    [setSearchValue]
  );

  const onTableChange: TableProps<Category>["onChange"] = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pagination,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filters,
    sorter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extra
  ) => {
    setSort(sorter as { order: string; columnKey: string });
  };

  const ids = ctx.document.menu.categories.map((category) => category.id);
  const maxId = Math.max(...ids);

  const onAddNewCategoryClick = useCallback(() => {
    const newCategory: Category = {
      id: maxId === Infinity || maxId === -Infinity ? 1 : maxId + 1,
      name: emptyTranslatedString,
      editorName: "",
      mobile: null,
      position: 0,
      productIds: [],
      upsellProductIds: [],
      upsellName: emptyTranslatedString,
      upsellButton: emptyTranslatedString,
    };
    ctxEditor.dispatch({
      type: "addItem",
      path: ["menu", "categories"],
      item: newCategory,
    });
    navigate(`${newCategory.id}`, {
      state: {
        queryParams: getQueryParams(),
      },
    });
  }, [ctxEditor, maxId, navigate, getQueryParams]);

  return (
    <Fragment>
      <PageHeader
        title="Categories"
        extra={
          <Button type="primary" onClick={onAddNewCategoryClick}>
            Add New Category
          </Button>
        }
      />
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        <Col span={8}>
          <InputString
            placeholder="Search in name"
            value={searchValue}
            onChange={onSearchValueChange}
          />
        </Col>
      </Row>
      <Table
        onRow={onRow}
        rowKey="id"
        columns={columns}
        tableLayout="auto"
        sticky={true}
        showSorterTooltip={false}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onTableChange}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          current: page !== null && page !== undefined ? page : 1,
          onChange: (page) => {
            setPage(page);
          },
        }}
        dataSource={ctx.document.menu.categories.filter((category) => {
          return (
            category.name["hu"]
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            category.name["en"]
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            category.editorName
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase()) ||
            category.id === Number(searchValue)
          );
        })}
      />
    </Fragment>
  );
};
