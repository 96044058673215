import React, { useCallback, useEffect } from "react";
import {
  OpeningTimesStruct,
  UpdateClosedDaysRequest,
  UpdateOpeningTimesRequest,
} from "../generated";
import {
  Button,
  Col,
  DatePicker,
  Row,
  TimePicker,
  Typography,
  message,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useMutation } from "@tanstack/react-query";
import { postClosedDays, postOpeningTimes } from "../api/client";
import { useClientContext } from "../editor/context";

const findLabel = (day: string) => {
  switch (day) {
    case "Mon":
      return "Monday";
    case "Tue":
      return "Tuesday";
    case "Wed":
      return "Wednesday";
    case "Thu":
      return "Thursday";
    case "Fri":
      return "Friday";
    case "Sat":
      return "Saturday";
    case "Sun":
      return "Sunday";
    default:
      return "Unknown";
  }
};

const TimePickerWithLabel: React.FC<{
  label: "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
  to?: string | null;
  from?: string | null;
  openingTimesState: OpeningTimesStruct;
  setOpeningTimesState: React.Dispatch<
    React.SetStateAction<OpeningTimesStruct>
  >;
}> = ({ label, to, from, openingTimesState, setOpeningTimesState }) => {
  const [error, setError] = React.useState<boolean>(false);

  const handleTimeChange = (type: "to" | "from", value: Dayjs | null) => {
    const formattedValue = value !== null ? value.format("HH:mm") : null;
    const updatedOpeningTimes = {
      [label]: {
        To: type === "to" ? formattedValue : openingTimesState[label]?.To,
        From: type === "from" ? formattedValue : openingTimesState[label]?.From,
      },
    };
    const newOpeningTimesState = {
      ...openingTimesState,
      ...updatedOpeningTimes,
    };

    // Validation
    const fromTime = newOpeningTimesState[label]?.From;
    const toTime = newOpeningTimesState[label]?.To;

    if (fromTime !== null && toTime !== null) {
      const isFromGreaterThanTo = dayjs(fromTime, "HH:mm").isAfter(
        dayjs(toTime, "HH:mm")
      );
      const isToLowerThanFrom = dayjs(toTime, "HH:mm").isBefore(
        dayjs(fromTime, "HH:mm")
      );

      if (isFromGreaterThanTo || isToLowerThanFrom) {
        setError(true);
        void message.error("From must be before to! Or to must be after from!");
        return;
      } else {
        setError(false);
      }
    }

    setOpeningTimesState(newOpeningTimesState);
  };

  return (
    <Row style={{ marginBottom: 14 }}>
      <Col style={{ width: 100 }}>
        <label>{findLabel(label)}</label>
      </Col>
      <Col span={8} pull={1}>
        <TimePicker
          format="HH:mm"
          defaultValue={
            from !== null ? dayjs(from, "HH:mm") : dayjs("00:00", "HH:mm")
          }
          onChange={(value) => handleTimeChange("from", value)}
        />
      </Col>
      <Col span={8}>
        <TimePicker
          format="HH:mm"
          defaultValue={
            to !== null ? dayjs(to, "HH:mm") : dayjs("00:00", "HH:mm")
          }
          onChange={(value) => handleTimeChange("to", value)}
          status={error ? "error" : undefined}
        />
      </Col>
    </Row>
  );
};

export const OpeningHours: React.FC<{
  closedDays: string[] | null;
  openingTimes: OpeningTimesStruct | null;
  LocationId: number;
  setShowOpeningHoursModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ closedDays, openingTimes, LocationId, setShowOpeningHoursModal }) => {
  const [openingTimesState, setOpeningTimesState] =
    React.useState<OpeningTimesStruct>(
      openingTimes !== null
        ? openingTimes
        : {
            Fri: {
              To: "20:00",
              From: "10:00",
            },
            Mon: {
              To: "20:00",
              From: "10:00",
            },
            Sat: {
              To: "20:00",
              From: "10:00",
            },
            Sun: {
              To: "20:00",
              From: "10:00",
            },
            Thu: {
              To: "20:00",
              From: "10:00",
            },
            Tue: {
              To: "20:00",
              From: "10:00",
            },
            Wed: {
              To: "20:00",
              From: "10:00",
            },
          }
    );

  const [closedDaysState, setClosedDaysState] = React.useState<string[] | null>(
    closedDays !== null ? closedDays : []
  );

  const addNewClosedDate = (newDay: string) => {
    if (closedDaysState !== null) {
      const updatedClosedDays = closedDaysState.concat(newDay);
      setClosedDaysState(updatedClosedDays);
    }
  };

  const deleteClosedDay = (dayToDelete: string) => {
    if (closedDaysState !== null) {
      const updatedClosedDays = closedDaysState.filter(
        (day) => day !== dayToDelete
      );
      setClosedDaysState(updatedClosedDays);
    }
  };

  const handleClosedDateChange = (value: Dayjs | null, index: number) => {
    if (closedDaysState !== null && value !== null) {
      const updatedClosedDays = [...closedDaysState];
      updatedClosedDays[index] = value.format("YYYY-MM-DD"); // Convert to string or use the appropriate format
      setClosedDaysState(updatedClosedDays);
    }
  };

  const clientContext = useClientContext();

  const { data: postClosedDaysData, mutate: doPostClosedDays } = useMutation({
    mutationKey: ["ClosedDaysMutation"],
    mutationFn: postClosedDays,
  });

  const onClosedDaysSave = useCallback(() => {
    if (
      closedDaysState !== null &&
      clientContext.selectedClient !== null &&
      LocationId !== null
    ) {
      const closedDaysToSend: UpdateClosedDaysRequest = {
        ClientId: clientContext.selectedClient.Id,
        LocationId: LocationId,
        ClosedDays: closedDaysState,
      };
      void doPostClosedDays(closedDaysToSend);
    }
  }, [clientContext.selectedClient, doPostClosedDays, closedDaysState]);

  useEffect(() => {
    if (postClosedDaysData !== undefined && postClosedDaysData.status === 200) {
      setClosedDaysState(postClosedDaysData.data);
      void message.success("Closed days saved!");
      setShowOpeningHoursModal(false);
    }
  }, [postClosedDaysData]);

  const { data: postOpeningTimesData, mutate: doPostOpeningTimes } =
    useMutation({
      mutationKey: ["openingTimesMutation"],
      mutationFn: postOpeningTimes,
    });

  const onOpeningTimesSave = useCallback(() => {
    if (
      openingTimesState !== null &&
      clientContext.selectedClient !== null &&
      LocationId !== null
    ) {
      const openingTimesToSend: UpdateOpeningTimesRequest = {
        ClientId: clientContext.selectedClient.Id,
        LocationId: LocationId,
        OpeningTimes: openingTimesState,
      };
      void doPostOpeningTimes(openingTimesToSend);
    }
  }, [clientContext.selectedClient, doPostOpeningTimes, openingTimesState]);

  useEffect(() => {
    if (
      postOpeningTimesData !== undefined &&
      postOpeningTimesData.status === 200
    ) {
      setOpeningTimesState(postOpeningTimesData.data);
      void message.success("Opening times saved!");
      setShowOpeningHoursModal(false);
    }
  }, [postOpeningTimesData]);

  return (
    <>
      <Row style={{ marginTop: 16 }}>
        <Row style={{ marginBottom: 8 }}>
          <Col style={{ width: 100 }}>
            <Typography>Day</Typography>
          </Col>
          <Col style={{ width: 116 }} pull={1}>
            <Typography>From</Typography>
          </Col>
          <Col style={{ width: 100 }}>
            <Typography>To</Typography>
          </Col>
        </Row>
        <TimePickerWithLabel
          label="Mon"
          from={openingTimesState?.Mon?.From}
          to={openingTimesState?.Mon?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
        <TimePickerWithLabel
          label="Tue"
          from={openingTimesState?.Tue?.From}
          to={openingTimesState?.Tue?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
        <TimePickerWithLabel
          label="Wed"
          from={openingTimesState?.Wed?.From}
          to={openingTimesState?.Wed?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
        <TimePickerWithLabel
          label="Thu"
          from={openingTimesState?.Thu?.From}
          to={openingTimesState?.Thu?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
        <TimePickerWithLabel
          label="Fri"
          from={openingTimesState?.Fri?.From}
          to={openingTimesState?.Fri?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
        <TimePickerWithLabel
          label="Sat"
          from={openingTimesState?.Sat?.From}
          to={openingTimesState?.Sat?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
        <TimePickerWithLabel
          label="Sun"
          from={openingTimesState?.Sun?.From}
          to={openingTimesState?.Sun?.To}
          openingTimesState={openingTimesState}
          setOpeningTimesState={setOpeningTimesState}
        />
      </Row>
      <Row style={{ justifyContent: "end" }}>
        <Button type="primary" onClick={onOpeningTimesSave}>
          Save opening hours
        </Button>
      </Row>
      <Row style={{ width: "100%", marginBottom: 12 }}>
        <Typography style={{ fontWeight: 600, fontSize: 16 }}>
          Closed days
        </Typography>
      </Row>
      <Row>
        {closedDaysState !== null &&
          closedDaysState.length > 0 &&
          closedDaysState.map((day, i) => (
            <Row key={i + 1} style={{ width: "100%", marginBottom: 12 }}>
              <DatePicker
                value={dayjs(day)}
                onChange={(v) => handleClosedDateChange(v, i)}
              />
              <Button type="text" onClick={() => deleteClosedDay(day)}>
                Delete
              </Button>
            </Row>
          ))}
      </Row>
      <Row style={{ marginBottom: 16, justifyContent: "space-between" }}>
        <Button
          type="default"
          onClick={() => addNewClosedDate(dayjs().format("YYYY-MM-DD"))}
        >
          Add closed day
        </Button>
        <Button type="primary" onClick={onClosedDaysSave}>
          Save closed days
        </Button>
      </Row>
    </>
  );
};
