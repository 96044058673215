import { Button } from "antd";
import { ButtonType } from "antd/lib/button";
import React from "react";
import { useCallback } from "react";
import { useEditorContext } from "../context";
import { Path } from "../logic";
import { useNavigate, To } from "react-router-dom";

export type ActionButtonProps<T> = {
  action: "add" | "remove" | "save";
  type?: ButtonType;
  label: string;
  dataPath: Path;
  value: Array<T>;
  defaultValue: T;
  onSave?: () => void;
  disabled?: boolean;
  danger?: boolean | undefined;
  nav?: To;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ActionButton = <T extends unknown>({
  action,
  type,
  label,
  dataPath,
  defaultValue,
  disabled,
  onSave,
  danger,
  nav,
}: ActionButtonProps<T>) => {
  const ctx = useEditorContext();
  const navigate = useNavigate();

  const onClick = useCallback(
    (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      e.preventDefault();
      e.stopPropagation();
      if (action === "add") {
        ctx.dispatch({
          type: "addItem",
          path: dataPath,
          item: defaultValue,
        });
      } else if (action === "remove") {
        if (window.confirm("Are you sure you want to delete this item?")) {
          ctx.dispatch({
            type: "removeItem",
            path: dataPath,
            item: defaultValue,
          });
          console.warn(nav);
          if (nav !== undefined) {
            navigate(nav);
          }
        }
      } else if (action === "save") {
        onSave !== undefined ? onSave() : console.log("NEED_SAVE_FUNCTION");
      }
    },
    [action, ctx, dataPath, defaultValue, onSave, navigate, nav]
  );

  return (
    <Button type={type} disabled={disabled} onClick={onClick} danger={danger}>
      {label}
    </Button>
  );
};

const ActionButtonMemo = React.memo(ActionButton);
export { ActionButtonMemo as ActionButton };
