import { TranslatedString } from "../lib/scalars";
import { expectedLanguages } from "../lib/settings";

export const emptyTranslatedString = (): TranslatedString => {
  const ts: TranslatedString = {};

  expectedLanguages.forEach((key) => {
    ts[key] = "";
  });

  return ts;
};
