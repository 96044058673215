import { default as AntSelect } from "antd/lib/select";
import { Tooltip } from "antd";
import { Fragment, ReactNode, useCallback } from "react";
import { Path } from "../../editor/logic";
import { CheckboxNull } from "./ChechboxNull";
import { InfoCircleOutlined } from "@ant-design/icons";

export type TagProps = {
  label: string;
  placeholder?: ReactNode;
  dataPath: Path;
  value: string[] | null;
  nullable?: boolean;
  disabled?: boolean;
  onChange?: (value: string[] | null) => void;
  tooltipText?: string;
  hidden?: boolean;
};

export const Tag = ({
  label,
  placeholder,
  value,
  onChange: onTagChange,
  nullable,
  disabled,
  tooltipText,
  hidden,
}: TagProps) => {
  const onChange = useCallback(
    (v: string[] | null) => {
      if (onTagChange !== undefined) onTagChange(v);
    },
    [onTagChange]
  );

  const onCheckBoxChange = useCallback(
    (value: string[] | null) => {
      if (value === null) {
        onChange(null);
      } else {
        onChange(value);
      }
    },
    [onChange]
  );

  const renderLabelWithTooltip = () => (
    <>
      {tooltipText !== undefined && (
        <Tooltip title={tooltipText} placement="topLeft">
          <h3 style={{ width: "fit-content" }}>
            {label}
            <InfoCircleOutlined
              style={{ fontSize: 12, position: "absolute", marginLeft: 3 }}
            />
          </h3>
        </Tooltip>
      )}
      {tooltipText === undefined && (
        <h3 style={{ width: "fit-content" }}>{label}</h3>
      )}
    </>
  );

  return (
    <div style={{ display: hidden === true ? "none" : "auto" }}>
      {nullable === true && (
        <CheckboxNull
          onChange={onCheckBoxChange}
          value={value}
          defaultValue={[]}
        >
          {renderLabelWithTooltip()}
        </CheckboxNull>
      )}
      {nullable !== true && renderLabelWithTooltip()}
      {value !== null && (
        <AntSelect
          disabled={disabled}
          mode="tags"
          style={{ width: "100%" }}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={value}
        />
      )}
    </div>
  );
};
