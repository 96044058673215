import React, { useCallback, useEffect } from "react";
import { Button, Col, Modal, Row, Spin, Switch, Table, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActionButton,
  InputNumber,
  InputString,
  Select,
} from "../editor/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  downloadProductVisibilityHistories,
  getImages,
  getLocationDetails,
  getMenu,
  getMenus,
  postLocationDetails,
  postUpdateProdOptVisibility,
} from "../api/client";
import {
  SaveLocationRequest,
  MenuResponse,
  LocationWithDisabled as LocationDetailsType,
  UserRole,
  UpdateProdOptVisibilityRequest,
} from "../generated";
import { GetKeys } from "../lib/types";
import {
  //canUserTurnOffOptionOnly,
  //canUserTurnOffProductOnly,
  setProperty,
} from "../lib/utils";
import { ITEMS_PER_PAGE } from "../lib/settings";
import { Category, Config, Option, Product } from "../lib/schema.generated";
import { ColumnsType } from "antd/lib/table";
import { TranslatedString } from "../lib/scalars";
import { PageHeader } from "../components/PageHeader";
import { useClientContext, useUserContext } from "../editor/context";
import { OpeningHours } from "./OpeningHours";
import LoadingPlaceholder from "../components/LoadingIcon";
// import { TransformKioskMenuToEditorMenu } from "../lib/editorTransform";
// import kioskMenuJson from "../data/vapiano_becsi_original.json";
// import { KioskMenu } from "../lib/kioskMenu";

export const LocationDetails: React.FC = () => {
  const clientContext = useClientContext();
  const userContext = useUserContext();
  const navigate = useNavigate();
  const params = useParams<"locationId">();
  const [locationDetails, setLocationDetails] =
    React.useState<LocationDetailsType | null>(null);
  const [menuOptions, setMenuOptions] = React.useState<
    Array<{ label: string; value: number }>
  >([]);
  const [currentMenu, setCurrentMenu] = React.useState<Config | null>(null);
  const [selectedTableType, setSelectedTableType] = React.useState<
    "Products" | "Options"
  >("Products");
  const [showDisableOptionModal, setShowDisableOptionModal] =
    React.useState<boolean>(false);
  const [showOpeningHoursModal, setShowOpeningHoursModal] =
    React.useState<boolean>(false);
  const [optionDisableModalData, setOptionDisableModalData] = React.useState<{
    products: Array<Product>;
    optionId: number;
  }>({ products: [], optionId: 0 });
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [productVisibilityProcess, setProductVisibilityProcess] =
    React.useState<Map<number, boolean>>(new Map<number, boolean>());
  const [optionVisibilityProcess, setOptionVisibilityProcess] = React.useState<
    Map<number, boolean>
  >(new Map<number, boolean>());

  const { data: menuData, isLoading: isMenuLoading } = useQuery({
    queryKey: ["locationDetailsMenus", clientContext.selectedClient?.Id],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => getMenus(clientContext.selectedClient!.Id),
  });
  const { data: currentMenuData, refetch: fetchCurrentMenu } = useQuery({
    queryKey: ["locationDetailsMenu", locationDetails?.ActiveMenu?.Id ?? 0],
    queryFn: () => getMenu(locationDetails?.ActiveMenu?.Id ?? 0),
    enabled: false,
  });
  const { data: imageData } = useQuery({
    queryKey: ["images", clientContext.selectedClient?.Id],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => getImages(clientContext.selectedClient!.Id),
  });
  const { data: postLocationDetailsData, mutate: doPostLocationDetails } =
    useMutation({
      mutationKey: ["locationDetailsMutation"],
      mutationFn: postLocationDetails,
    });

  const { mutateAsync: doPostUpdateProdOptVisibility } = useMutation({
    mutationKey: ["updateProdOptVisibilityMutation"],
    mutationFn: postUpdateProdOptVisibility,
  });

  useEffect(() => {
    if (
      postLocationDetailsData !== undefined &&
      postLocationDetailsData.status === 200
    ) {
      void message.success("Location details saved!");
      navigate("/dashboard/locations");
    }
  }, [navigate, postLocationDetailsData]);

  useEffect(() => {
    if (locationDetails !== null) {
      void fetchCurrentMenu();
    }
  }, [fetchCurrentMenu, locationDetails]);

  useEffect(() => {
    console.warn(productVisibilityProcess);
  }, [productVisibilityProcess]);

  useEffect(() => {
    if (currentMenuData !== undefined) {
      /*
        - type menu
        - save menu to json
        - render products and options from menuHistory
      */
      setCurrentMenu(currentMenuData.data.Data as Config);
    }
  }, [currentMenuData]);

  useEffect(() => {
    if (menuData !== undefined && imageData !== undefined) {
      const formattedMenuData = menuData.data.map((menu: MenuResponse) => {
        return {
          label: menu.Name,
          value: menu.Id,
        };
      });
      setMenuOptions(formattedMenuData);
      // const kisokMenu = kioskMenuJson as KioskMenu;
      // const menuConfig = TransformKioskMenuToEditorMenu(
      //   kisokMenu,
      //   imageData.data
      // );
      // console.log(JSON.stringify(menuConfig));
    }
  }, [menuData, imageData]);

  if (params.locationId === undefined) throw new Error("locationId undefined");

  const locationId = Number.parseInt(params.locationId);

  if (Number.isNaN(locationId))
    throw new Error(`locationId '${params.locationId}' is NaN`);

  const {
    data: locationDetailsData,
    isLoading: isLocationDetailsLoading,
    refetch: fetchLocationDetails,
  } = useQuery({
    queryKey: ["locationDetails", clientContext.selectedClient?.Id, locationId],
    queryFn: () =>
      getLocationDetails({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ClientId: clientContext.selectedClient!.Id,
        LocationId: locationId,
      }),
    enabled: false,
  });

  useEffect(() => {
    if (locationDetailsData !== undefined) {
      setLocationDetails(locationDetailsData.data);
    }
  }, [locationDetailsData]);

  useEffect(() => {
    if (clientContext.selectedClient !== null) {
      void fetchLocationDetails();
    }
  }, [clientContext.selectedClient, fetchLocationDetails]);

  const onLocationDetailsSave = useCallback(() => {
    if (locationDetails !== null && clientContext.selectedClient !== null) {
      const locationDetailsToSend: SaveLocationRequest = {
        ClientId: clientContext.selectedClient.Id,
        Id: locationDetails.Id,
        Name: locationDetails.Name,
        Description: locationDetails.Description,
        AdminLink: locationDetails.AdminLink,
        KioskIds: locationDetails.Kiosks.map((kiosk) => kiosk.Id),
        ActiveMenuId:
          locationDetails.ActiveMenu !== undefined
            ? locationDetails.ActiveMenu.Id
            : 0,
        //DisabledOptionIds: locationDetails.DisabledOptionIds,
        //DisabledProductIds: locationDetails.DisabledProductIds,
      };
      void doPostLocationDetails(locationDetailsToSend);
    }
  }, [clientContext.selectedClient, doPostLocationDetails, locationDetails]);

  const onLocationDetailsChange = useCallback(
    (key: GetKeys<LocationDetailsType>) =>
      (value: number | string | null | (string | number)[]) => {
        if (locationDetails !== null) {
          const newLocationDetails = setProperty(locationDetails, key, value);
          setLocationDetails(newLocationDetails);
        }
      },
    [locationDetails]
  );

  const onOptionVisibilityChange = useCallback(
    (option: Option) => (checked: boolean) => {
      if (locationDetails !== null && clientContext.selectedClient !== null) {
        const updateVisibilityToSend: UpdateProdOptVisibilityRequest = {
          ClientId: clientContext.selectedClient.Id,
          LocationId: locationDetails.Id,
          Id: option.id,
          Type: "option",
          IsVisible: checked,
          ProductName: option.editorName,
        };
        setOptionVisibilityProcess(
          new Map(optionVisibilityProcess.set(option.id, true))
        );
        doPostUpdateProdOptVisibility(updateVisibilityToSend)
          .then((data) => {
            const newLocationDetails = setProperty(
              locationDetails,
              "DisabledOptionIds",
              data.data
            );
            setLocationDetails(newLocationDetails);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            optionVisibilityProcess.delete(option.id);
            setOptionVisibilityProcess(new Map(optionVisibilityProcess));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      locationDetails,
      clientContext.selectedClient,
      doPostUpdateProdOptVisibility,
      setOptionVisibilityProcess,
    ]
  );

  const onProductVisibilityChange = useCallback(
    (product: Product) => (checked: boolean) => {
      if (locationDetails !== null && clientContext.selectedClient !== null) {
        const updateVisibilityToSend: UpdateProdOptVisibilityRequest = {
          ClientId: clientContext.selectedClient.Id,
          LocationId: locationDetails.Id,
          Id: product.id,
          Type: "product",
          IsVisible: checked,
          ProductName: product.editorName,
        };

        setProductVisibilityProcess(
          new Map(productVisibilityProcess.set(product.id, true))
        );
        doPostUpdateProdOptVisibility(updateVisibilityToSend)
          .then((data) => {
            const newLocationDetails = setProperty(
              locationDetails,
              "DisabledProductIds",
              data.data
            );
            setLocationDetails(newLocationDetails);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            productVisibilityProcess.delete(product.id);
            setProductVisibilityProcess(new Map(productVisibilityProcess));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      locationDetails,
      clientContext.selectedClient,
      doPostUpdateProdOptVisibility,
      setProductVisibilityProcess,
    ]
  );

  const optionColumns: ColumnsType<Option> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: Option, b: Option) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name["hu"],
      sorter: (a: Option, b: Option) =>
        a.name["hu"].localeCompare(b.name["hu"]),
    },
    {
      title: "EditorName",
      dataIndex: "editorName",
      key: "editorName",
      sorter: (a: Option, b: Option) =>
        a.editorName.localeCompare(b.editorName),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "id",
      render: (_currentData: boolean, data: Option) => {
        if (locationDetails !== null) {
          const optionId = locationDetails.DisabledOptionIds.find(
            (currentOptionId) => data.id === currentOptionId
          );
          if (optionVisibilityProcess.has(data.id)) {
            return <Spin size="small" style={{ width: 44 }} />;
          }
          return (
            <Switch
              checked={optionId === undefined}
              onChange={onOptionVisibilityChange(data)}
            />
          );
        }
        return null;
      },
    },
  ];

  const productColumns: ColumnsType<Product> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: Product, b: Product) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name["hu"],
      sorter: (a: Product, b: Product) =>
        a.name["hu"].localeCompare(b.name["hu"]),
    },
    {
      title: "EditorName",
      dataIndex: "editorName",
      key: "editorName",
      sorter: (a: Product, b: Product) =>
        a.editorName.localeCompare(b.editorName),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Visible",
      dataIndex: "visible",
      key: "id",
      render: (currentData: boolean, data: Product) => {
        if (locationDetails !== null) {
          const productId = locationDetails.DisabledProductIds.find(
            (currentProductId) => data.id === currentProductId
          );

          if (productVisibilityProcess.has(data.id)) {
            return <Spin size="small" style={{ width: 44 }} />;
          }
          return (
            <Switch
              checked={productId === undefined}
              onChange={onProductVisibilityChange(data)}
            />
          );
        }
        return null;
      },
    },
  ];

  const getProductRowKey = useCallback(
    (record: Product) => "PRODUCT_" + record.id.toString(),
    []
  );

  const getOptionRowKey = useCallback(
    (record: Option) => "OPTION_" + record.id.toString(),
    []
  );

  const allProductsId = currentMenu?.menu.products.map((product) => product.id);
  const [selectedCategory, setSelectedCategory] = React.useState<number>(0);
  const [filteredProducts, setFilteredProducts] = React.useState<number[]>(
    allProductsId !== undefined ? allProductsId : []
  );

  React.useEffect(() => {
    if (allProductsId !== undefined) setFilteredProducts(allProductsId);
  }, [currentMenu?.menu.products]);

  const handleFilterCategory = (category: Category | undefined) => {
    if (category === undefined) {
      setSelectedCategory(0);
      if (allProductsId !== undefined) {
        setFilteredProducts([...allProductsId]);
      } else {
        setFilteredProducts([]);
      }
    }
    if (category !== undefined && category.id === selectedCategory) {
      setSelectedCategory(0);
      if (allProductsId !== undefined) {
        setFilteredProducts([...allProductsId]);
      } else {
        setFilteredProducts([]);
      }
    }
    if (category !== undefined && category.id !== selectedCategory) {
      setSelectedCategory(category.id);
      setFilteredProducts(category.productIds);
    }
  };

  const renderProductsOrOptionsTable = useCallback((): JSX.Element | null => {
    if (currentMenu !== null) {
      if (selectedTableType === "Products") {
        return (
          <Table
            rowKey={getProductRowKey}
            columns={productColumns}
            tableLayout="auto"
            sticky={true}
            pagination={{
              pageSize: ITEMS_PER_PAGE,
              position: ["topLeft"],
              hideOnSinglePage: true,
              showSizeChanger: false,
            }}
            dataSource={currentMenu.menu.products
              .filter(
                (product) =>
                  product.name["hu"]
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase()) ||
                  product.name["en"]
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase()) ||
                  String(product.id).includes(searchValue) ||
                  product.editorName
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase())
              )
              .filter((product) => filteredProducts.includes(product.id))}
          />
        );
      }
      return (
        <Table
          rowKey={getOptionRowKey}
          columns={optionColumns}
          tableLayout="auto"
          sticky={true}
          pagination={{
            pageSize: ITEMS_PER_PAGE,
            position: ["topLeft"],
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
          dataSource={currentMenu.menu.options.filter(
            (option) =>
              option.name["hu"]
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase()) ||
              option.name["en"]
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase()) ||
              option.id === Number(searchValue)
          )}
        />
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMenu,
    selectedTableType,
    locationDetails,
    searchValue,
    productVisibilityProcess,
    optionVisibilityProcess,
    filteredProducts,
  ]);

  const onTableTypeChange = useCallback(
    (value: "Products" | "Options") => () => {
      setSelectedTableType(value);
      // init search value
      setSearchValue("");
    },
    []
  );

  const onDisableOptionsModalOk = useCallback(() => {
    if (locationDetails !== null) {
      // disable products
      const productIds = optionDisableModalData.products.map(
        (product) => product.id
      );
      let newLocationDetails = setProperty(
        locationDetails,
        "DisabledProductIds",
        [...locationDetails.DisabledProductIds, ...productIds]
      );

      // disable options
      newLocationDetails = setProperty(
        newLocationDetails,
        "DisabledOptionIds",
        [
          ...newLocationDetails.DisabledOptionIds,
          optionDisableModalData.optionId,
        ]
      );
      setLocationDetails(newLocationDetails);
    }
    setOptionDisableModalData({ products: [], optionId: 0 });
    setShowDisableOptionModal(false);
  }, [locationDetails, optionDisableModalData]);

  const onOpeningHoursModalOk = useCallback(() => {
    setShowOpeningHoursModal(false);
  }, []);

  const onDisableOptionsModalCancel = useCallback(() => {
    setOptionDisableModalData({ products: [], optionId: 0 });
    setShowDisableOptionModal(false);
  }, []);

  const onOpeningHoursModalCancel = useCallback(() => {
    setShowOpeningHoursModal(false);
  }, []);

  const renderLocationsKiosks = useCallback((): Array<JSX.Element> => {
    const kioskElements: Array<JSX.Element> = [];
    if (locationDetails !== null) {
      for (const kiosk of locationDetails.Kiosks) {
        kioskElements.push(
          <div
            key={"KIOSK_" + kiosk.Id.toString()}
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ paddingRight: 10, fontWeight: "bold" }}>Id:</div>
              <div>{kiosk.Id}</div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ paddingRight: 10, fontWeight: "bold" }}>Name:</div>
              <div>{kiosk.Name}</div>
            </div>
          </div>
        );
      }
    }
    return kioskElements;
  }, [locationDetails]);

  const onSearchValueChange = useCallback((value: string | null) => {
    if (value !== null) {
      setSearchValue(value);
    } else {
      setSearchValue("");
    }
  }, []);

  if (isLocationDetailsLoading || locationDetails === null || isMenuLoading)
    return <LoadingPlaceholder />;

  return (
    <div className="form">
      <Row style={{ justifyContent: "space-between" }}>
        <PageHeader title="Location details" />
        <ActionButton
          action="save"
          type="primary"
          label="Save"
          dataPath={[]}
          value={[]}
          defaultValue={""}
          onSave={onLocationDetailsSave}
        />
      </Row>
      <Row gutter={8}>
        <Col span={4}>
          <InputNumber label="ID" value={locationDetails.Id} disabled={true} />
        </Col>
      </Row>

      <Row>
        <div
          style={{
            fontWeight: 600,
            fontSize: 16,
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          Kiosks:
        </div>
        {renderLocationsKiosks()}
      </Row>

      <Row gutter={8}>
        <Col span={6}>
          <InputString
            label="Name"
            value={locationDetails.Name}
            disabled={true}
            onChange={onLocationDetailsChange("Name")}
          />
        </Col>
        <Col span={6}>
          <InputString
            label="Description"
            value={locationDetails.Description}
            disabled={true}
            onChange={onLocationDetailsChange("Description")}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={6}>
          <Select
            label="Active menu"
            value={
              locationDetails.ActiveMenu !== undefined
                ? locationDetails.ActiveMenu.Id
                : 0
            }
            disabled={
              userContext.user !== null &&
              (userContext.user.Role === UserRole.ADMIN ||
                userContext.user.Role === UserRole.CLIENT)
                ? false
                : true
            }
            options={menuOptions}
            onChange={onLocationDetailsChange("ActiveMenu.Id")}
          />
        </Col>
        <Col span={6}>
          <InputString
            label="Admin url"
            value={locationDetails.AdminLink}
            disabled={true}
            onChange={onLocationDetailsChange("AdminLink")}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 12 }}>
        <Button type="primary" onClick={() => setShowOpeningHoursModal(true)}>
          Opening hours / Closed days
        </Button>
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              padding: 30,
              marginRight: 20,
              borderWidth: 1,
              borderStyle: "dashed",
              borderColor: selectedTableType === "Products" ? "red" : "black",
            }}
            onClick={onTableTypeChange("Products")}
          >
            Products
          </div>
          <div
            style={{
              padding: 30,
              borderWidth: 1,
              borderStyle: "dashed",
              borderColor: selectedTableType === "Options" ? "red" : "black",
            }}
            onClick={onTableTypeChange("Options")}
          >
            Options
          </div>
        </div>
      </Row>
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        <Col span={8}>
          <InputString
            placeholder="Search in name"
            value={searchValue}
            onChange={onSearchValueChange}
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        {currentMenu?.menu.categories !== undefined &&
          selectedTableType === "Products" &&
          currentMenu.menu.categories.map((category) => {
            if (category.name["hu"].length > 0)
              return (
                <Button
                  key={category.id}
                  onClick={() => handleFilterCategory(category)}
                  type={
                    selectedCategory === category.id ? "primary" : undefined
                  }
                >
                  {category.name["hu"]}
                </Button>
              );
          })}
      </Row>
      <Row style={{ minHeight: 1000 }}>{renderProductsOrOptionsTable()}</Row>

      {/* MODALS */}
      <Modal
        title="Disable option"
        open={showDisableOptionModal}
        onOk={onDisableOptionsModalOk}
        onCancel={onDisableOptionsModalCancel}
        maskClosable={false}
      >
        <div>
          If you want to disable this option we will disable the following
          products:{" "}
          <ul>
            {optionDisableModalData.products.map((product) => (
              <li key={"PRODUCT_DIS_" + product.id.toString()}>
                {product.name["hu"] + " - " + product.id.toString()}
              </li>
            ))}
          </ul>
        </div>
        <div>Do you want to proceed?</div>
      </Modal>
      <Modal
        title="Opening hours"
        open={showOpeningHoursModal}
        onCancel={onOpeningHoursModalCancel}
        footer={null}
      >
        <OpeningHours
          openingTimes={locationDetails.OpeningTimes}
          closedDays={locationDetails.ClosedDays}
          LocationId={locationDetails.Id}
          setShowOpeningHoursModal={setShowOpeningHoursModal}
        />
      </Modal>
    </div>
  );
};
