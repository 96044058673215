import { Fragment } from "react";
import { useMatches } from "react-router";
import { Breadcrumb as BC } from "antd";
import { Link, useParams } from "react-router-dom";

export const Breadcrumb = () => {
  const matches = useMatches();
  const last = matches[matches.length - 1];
  const params = useParams<"menuId">();

  if (params.menuId === undefined) throw new Error("menuId undefined");

  if (last.id === "productDetails") {
    return (
      <BC>
        <BC.Item>
          <Link to={`/dashboard/menu/${params.menuId}/products`}>Products</Link>
        </BC.Item>
        <BC.Item>Product Details</BC.Item>
      </BC>
    );
  } else if (last.id === "categoryDetails") {
    return (
      <BC>
        <BC.Item>
          <Link to={`/dashboard/menu/${params.menuId}/categories`}>
            Categories
          </Link>
        </BC.Item>
        <BC.Item>Category Details</BC.Item>
      </BC>
    );
  } else if (last.id === "stepDetails") {
    return (
      <BC>
        <BC.Item>
          <Link to={`/dashboard/menu/${params.menuId}/steps`}>Steps</Link>
        </BC.Item>
        <BC.Item>Step Details</BC.Item>
      </BC>
    );
  } else if (last.id === "optionDetails") {
    return (
      <BC>
        <BC.Item>
          <Link to={`/dashboard/menu/${params.menuId}/options`}>Options</Link>
        </BC.Item>
        <BC.Item>Option Details</BC.Item>
      </BC>
    );
  }

  return <Fragment />;
};
