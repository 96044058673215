import React, { useCallback, useEffect } from "react";
import { Button, Col, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  InputNumber,
  InputString,
  InputTranslated,
  Select,
  Image,
  CheckboxNull,
  ActionButton,
} from "../editor/components";
import {
  Image as ImageType,
  Maybe,
  Option,
  OptionMobile,
  OptionPresentation,
  TaxRate,
  Step,
  //StepOption,
} from "../lib/schema.generated";

import { useVapianoConfigContext } from "./vapianoConfigContext";
import { TranslatedString } from "../lib/scalars";
import {
  useClientContext,
  useEditorContext,
  useUserContext,
} from "../editor/context";
import { setProperty, expandTranslatedString } from "../lib/utils";
import { GetKeys } from "../lib/types";
import { PageHeader } from "../components/PageHeader";
import { findHiddenLanguage, isInputHidden } from "../editor/utils";
import LoadingPlaceholder from "../components/LoadingIcon";

export const OptionDetails: React.FC = () => {
  const ctx = useVapianoConfigContext();
  const editorCtx = useEditorContext();
  const clientCtx = useClientContext();
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const params = useParams<"optionId" | "menuId">();
  const [currentOption, SetCurrentOption] = React.useState<Option | null>(null);

  const location = useLocation();
  const state = location.state as { queryParams: string | undefined } | null;
  const queryParams =
    state !== null &&
    state.queryParams !== undefined &&
    state.queryParams !== null
      ? state.queryParams
      : undefined;

  // find category

  if (params.optionId === undefined) throw new Error("optionId undefined");
  if (params.menuId === undefined) throw new Error("menuId undefined");

  const optionId = Number.parseInt(params.optionId);

  if (Number.isNaN(optionId))
    throw new Error(`optionId '${params.optionId}' is NaN`);

  const option = ctx.document.menu.options.find((c) => c.id === optionId);

  const optionIndex = ctx.document.menu.options.findIndex(
    (option) => option.id === optionId
  );

  const [steps, setSteps] = React.useState<Step[]>(
    ctx.document.menu.steps.filter(function (st) {
      const stepOption = st.stepOptions.find(function (sto) {
        return sto.optionId === optionId;
      });
      return stepOption !== undefined;
    })
  );

  // find steps for this option
  /*const steps = ctx.document.menu.steps.filter(function (st) {
    const stepOption = st.stepOptions.find(function (sto) {
      return sto.optionId === optionId;
    });
    return stepOption !== undefined;
  });*/

  // other logic

  if (option === undefined)
    throw new Error(`option with id ${optionId} not found`);

  useEffect(() => {
    SetCurrentOption({
      ...option,
      name: expandTranslatedString(option.name),
      description: expandTranslatedString(option.description),
    });
  }, [option]);

  const onOptionValueChange = useCallback(
    (key: GetKeys<Option>) =>
      (
        value:
          | number
          | string
          | TranslatedString
          | null
          | (string | number)[]
          | ImageType
          | Maybe<OptionMobile>
      ) => {
        if (currentOption !== null) {
          const newOption = setProperty(currentOption, key, value);
          SetCurrentOption(newOption);
        }
      },
    [currentOption, SetCurrentOption]
  );

  const onSaveClick = useCallback(() => {
    // find category position in global state and replace that value with this new one
    // navigate back to categories list
    const optionIndex = ctx.document.menu.options.findIndex(
      (c) => c.id === option.id
    );
    editorCtx.dispatch({
      type: "replaceItem",
      path: ["menu", "options"],
      item: currentOption,
      index: optionIndex,
    });

    steps.map((step) => {
      const stepIndex = ctx.document.menu.steps.findIndex(
        (s) => s.id === step.id
      );

      step.stepOptions.map((stepOption, stepOptionIndex) => {
        editorCtx.dispatch({
          type: "setValue",
          path: [
            "menu",
            "steps",
            stepIndex,
            "stepOptions",
            stepOptionIndex,
            "price",
          ],
          //item: currentOption,
          //index: optionIndex,
          value: stepOption.price,
        });
      });
    });

    if (params.menuId !== undefined)
      navigate({
        pathname: `/dashboard/menu/${params.menuId}/options`,
        search: queryParams,
      });
  }, [
    ctx.document.menu.options,
    ctx.document.menu.steps,
    editorCtx,
    currentOption,
    params.menuId,
    navigate,
    option.id,
    steps,
    queryParams,
  ]);

  const stepTableRowKey = useCallback(
    (ps: Step, index: number | undefined): string => {
      return index?.toString() ?? ps.id.toString();
    },
    []
  );

  const onStepOptionPriceChange = useCallback(
    (so: Step, index: number) => (value: number | null) => {
      if (steps !== null) {
        const stepIndex = steps.findIndex((s) => s.id === so.id);

        const copyOfSteps = [...steps];
        const copyOfStepOptions = [...copyOfSteps[stepIndex].stepOptions];
        copyOfStepOptions[index] = {
          ...copyOfStepOptions[index],
          ["price"]: value,
        };

        copyOfSteps[stepIndex] = {
          ...copyOfSteps[stepIndex],
          ["stepOptions"]: copyOfStepOptions,
        };

        setSteps(copyOfSteps);
      }
    },
    [steps, setSteps]
  );

  const stepColumns: ColumnsType<Step> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "editorName",
      dataIndex: "editorName",
      key: "editorName",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name[ctx.useLanguageKey],
      sorter: (a: Step, b: Step) =>
        a.name[ctx.useLanguageKey].localeCompare(b.name[ctx.useLanguageKey]),
    },
    {
      title: "Price",
      render: (s: Step) => {
        const found = s.stepOptions.find((s) => s.optionId === optionId);
        const index = s.stepOptions.findIndex((s) => s.optionId === optionId);
        if (found === undefined) return null;
        return (
          <InputNumber
            label="Price"
            value={found.price}
            nullable
            onChange={onStepOptionPriceChange(s, index)}
          />
        );
      },
      sorter: (a: Step, b: Step) =>
        a.name[ctx.useLanguageKey].localeCompare(b.name[ctx.useLanguageKey]),
    },
  ];

  if (currentOption === null) return <LoadingPlaceholder />;

  return (
    <div className="form">
      <Row>
        <PageHeader
          title="Option details"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
          extra={
            <Space wrap>
              <ActionButton
                label="Remove"
                action="remove"
                type="primary"
                dataPath={["menu", "options"]}
                value={ctx.document.menu.options}
                defaultValue={optionIndex}
                nav={
                  params.menuId !== undefined
                    ? {
                        pathname: `/dashboard/menu/${params.menuId}/options`,
                        search: queryParams,
                      }
                    : undefined
                }
                danger
              />

              <Button type="primary" onClick={onSaveClick}>
                Save
              </Button>
            </Space>
          }
        />
      </Row>
      <Row gutter={8}>
        <Col
          span={4}
          hidden={isInputHidden(
            "id",
            "options",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputNumber
            label="ID"
            value={currentOption.id}
            disabled
            hidden={isInputHidden(
              "id",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "externalId",
            "steps",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputString
            label="External ID"
            onChange={onOptionValueChange("vendel.externalId")}
            value={currentOption.vendel.externalId}
            hidden={isInputHidden(
              "externalId",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "presentation",
            "options",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Presentation"
            placeholder="presentation type"
            value={currentOption.presentation}
            options={[
              {
                label: "Image",
                value: OptionPresentation.Image,
              },
              { label: "Text", value: OptionPresentation.Text },
              { label: "Nothing", value: OptionPresentation.Nothing },
            ]}
            onChange={onOptionValueChange("presentation")}
            hidden={isInputHidden(
              "presentation",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col
          span={6}
          hidden={isInputHidden(
            "editorName",
            "options",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <InputString
            label="Editor name"
            value={currentOption.editorName}
            onChange={onOptionValueChange("editorName")}
            hidden={isInputHidden(
              "editorName",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>

      <InputTranslated
        label="Name"
        value={currentOption.name}
        onChange={onOptionValueChange("name")}
        hidden={isInputHidden(
          "name",
          "options",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "options"
        )}
      />

      <InputTranslated
        label="Description"
        value={currentOption.description}
        nullable
        multiLine
        onChange={onOptionValueChange("description")}
        hidden={isInputHidden(
          "description",
          "options",
          clientCtx.selectedClient,
          userCtx.user
        )}
        hiddenLanguages={findHiddenLanguage(
          clientCtx.selectedClient,
          "options"
        )}
      />
      <Row gutter={8}>
        <Col span={24}>
          <CheckboxNull
            value={currentOption.mobile}
            defaultValue={{ productStepOptionImage: null }}
            onChange={onOptionValueChange("mobile")}
            hidden={isInputHidden(
              "mobile",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          >
            <h3>Enable picture</h3>
          </CheckboxNull>
          {currentOption.mobile !== null && (
            <Row gutter={8}>
              <Col span={8}>
                <Image
                  label="Option Image"
                  nullable
                  value={currentOption.mobile.productStepOptionImage}
                  mediaFiles={ctx.mediaFiles}
                  onChange={onOptionValueChange(
                    "mobile.productStepOptionImage"
                  )}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col
          span={4}
          hidden={isInputHidden(
            "dineIn",
            "options",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Dine In Tax Rate"
            value={currentOption.taxRates.dineIn}
            options={[
              { label: "A", value: TaxRate.A },
              { label: "B", value: TaxRate.B },
              { label: "C", value: TaxRate.C },
              { label: "D", value: TaxRate.D },
              { label: "E", value: TaxRate.E },
            ]}
            onChange={onOptionValueChange("taxRates.dineIn")}
            hidden={isInputHidden(
              "dineIn",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={4}
          hidden={isInputHidden(
            "takeAway",
            "options",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Take Away Tax Rate"
            value={currentOption.taxRates.takeAway}
            options={[
              { label: "A", value: TaxRate.A },
              { label: "B", value: TaxRate.B },
              { label: "C", value: TaxRate.C },
              { label: "D", value: TaxRate.D },
              { label: "E", value: TaxRate.E },
            ]}
            onChange={onOptionValueChange("taxRates.takeAway")}
            hidden={isInputHidden(
              "takeAway",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
        <Col
          span={6}
          hidden={isInputHidden(
            "taxModifier",
            "options",
            clientCtx.selectedClient,
            userCtx.user
          )}
        >
          <Select
            label="Tax rate modifier"
            value={currentOption.taxModifier}
            nullable
            options={[
              { label: "A", value: TaxRate.A },
              { label: "B", value: TaxRate.B },
              { label: "C", value: TaxRate.C },
              { label: "D", value: TaxRate.D },
              { label: "E", value: TaxRate.E },
            ]}
            onChange={onOptionValueChange("taxModifier")}
            hidden={isInputHidden(
              "taxModifier",
              "options",
              clientCtx.selectedClient,
              userCtx.user
            )}
          />
        </Col>
      </Row>
      {steps.length > 0 && (
        <Row>
          <Col span={24}>
            <PageHeader title="Steps" />

            {
              <Table
                rowKey={stepTableRowKey}
                columns={stepColumns}
                tableLayout="fixed"
                sticky={true}
                pagination={false}
                dataSource={steps}
              />
            }
          </Col>
        </Row>
      )}
    </div>
  );
};
