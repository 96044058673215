import { createContext, useContext } from "react";
import { ZodError } from "zod";
import { Config, ImageSource } from "../lib/schema.generated";

export type VapianoConfigContext = {
  expectedLanguages: readonly string[];
  useLanguageKey: string;
  mediaFiles: ImageSource[];
  document: Config;
  validationError: ZodError<Config> | null;
};

const VapianoConfigContext = createContext<VapianoConfigContext | undefined>(
  undefined
);

export const VapianoConfigContextProvider = VapianoConfigContext.Provider;

export const useVapianoConfigContext = (): VapianoConfigContext => {
  const config = useContext(VapianoConfigContext);

  if (config === undefined)
    throw new Error(`VapianoEditorContext is undefined`);

  return config;
};
