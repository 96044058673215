import React, { Fragment, useCallback } from "react";
import { Button, Col, Row, Table, TableProps } from "antd";
import type { ColumnsType } from "antd/es/table";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { TranslatedString } from "../lib/scalars";
import {
  Category,
  Product,
  ProductPresentation,
  ProductVendel,
  TaxRate,
} from "../lib/schema.generated";
import { useVapianoConfigContext } from "./vapianoConfigContext";
import { ActionButton } from "../editor/components/ActionButton";
import { ITEMS_PER_PAGE, emptyTranslatedString } from "../lib/settings";
import { useEditorContext } from "../editor/context";
import { InputString } from "../editor/components";
import { useQueryParam } from "use-query-params";
import { OwnStringParam, OwnNumberParam, SortParam } from "../utils";
import { SortOrder } from "antd/es/table/interface";

export const Products: React.FC = () => {
  const ctx = useVapianoConfigContext();
  const ctxEditor = useEditorContext();
  const navigate = useNavigate();
  //const [searchValue, setSearchValue] = React.useState<string>("");
  const [page, setPage] = useQueryParam("page", OwnNumberParam);
  const [searchValue, setSearchValue] = useQueryParam(
    "keyword",
    OwnStringParam
  );
  const [sort, setSort] = useQueryParam("order", SortParam);

  const columns: ColumnsType<Product> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: Product, b: Product) => a.id - b.id,
      sortOrder:
        sort !== undefined && sort.columnKey === "id"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "External ID",
      dataIndex: "vendel",
      key: "vendel",
      render: (vendel: ProductVendel) => vendel.externalId,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: TranslatedString) => name[ctx.useLanguageKey],
      sorter: (a: Product, b: Product) =>
        a.name[ctx.useLanguageKey].localeCompare(b.name[ctx.useLanguageKey]),
      sortOrder:
        sort !== undefined && sort.columnKey === "name"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "EditorName",
      dataIndex: "editorName",
      key: "editorName",
      sorter: (a: Product, b: Product) =>
        a.editorName.localeCompare(b.editorName),
      sortOrder:
        sort !== undefined && sort.columnKey === "editorName"
          ? (sort.order as SortOrder)
          : undefined,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_id: number, data: Product) => {
        const productIndex = ctx.document.menu.products.findIndex(
          (product) => product.id === data.id
        );
        return (
          <ActionButton
            label="Remove"
            action="remove"
            type="dashed"
            dataPath={["menu", "products"]}
            value={ctx.document.menu.products}
            defaultValue={productIndex}
          />
        );
      },
    },
  ];

  const getQueryParams = useCallback((): string => {
    const params: Record<string, string> = {};
    page !== null && page !== undefined
      ? (params["page"] = page.toString())
      : null;
    searchValue !== "" ? (params["keyword"] = searchValue) : null;
    sort !== undefined
      ? (params["order"] = `${sort.columnKey}_${sort.order.substring(0, 3)}`)
      : null;
    return createSearchParams(params).toString();
  }, [page, searchValue, sort]);

  const onRow = useCallback(
    (record: Product) => {
      return {
        onClick: () => {
          navigate(`${record.id}`, {
            state: {
              queryParams: getQueryParams(),
            },
          });
        },
      };
    },
    [navigate, getQueryParams]
  );

  const onSearchValueChange = useCallback(
    (value: string | null) => {
      if (value !== null) {
        setSearchValue(value);
      } else {
        setSearchValue("");
      }
    },
    [setSearchValue]
  );

  const ids = ctx.document.menu.products.map((product) => product.id);
  const maxId = Math.max(...ids);

  const onAddNewProductClick = useCallback(() => {
    const newProduct: Product = {
      availableDates: null,
      availableForFulfillment: null,
      description: null,
      id: maxId === Infinity || maxId === -Infinity ? 1 : maxId + 1,
      mobile: null,
      name: emptyTranslatedString,
      editorName: "",
      position: 0,
      presentation: ProductPresentation.Simple,
      price: 0,
      statPrice: null,
      pager: false,
      labels: [],
      productSteps: [],
      taxRates: {
        dineIn: TaxRate.A,
        takeAway: TaxRate.A,
      },
      vendel: {
        externalId: "",
      },
      receiptMark: false,
      containerPrice: null,
    };
    ctxEditor.dispatch({
      type: "addItem",
      path: ["menu", "products"],
      item: newProduct,
    });
    navigate(`${newProduct.id}`, {
      state: {
        queryParams: getQueryParams(),
      },
    });
  }, [ctxEditor, maxId, navigate, getQueryParams]);

  const onTableChange: TableProps<Product>["onChange"] = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pagination,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filters,
    sorter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extra
  ) => {
    setSort(sorter as { order: string; columnKey: string });
  };

  const allProductsId = ctx.document?.menu.products.map(
    (product) => product.id
  );
  const [selectedCategory, setSelectedCategory] = React.useState<number>(0);
  const [filteredProducts, setFilteredProducts] = React.useState<number[]>(
    allProductsId !== undefined ? allProductsId : []
  );

  React.useEffect(() => {
    if (allProductsId !== undefined) setFilteredProducts(allProductsId);
  }, [ctx.document?.menu.products]);

  const handleFilterCategory = (category: Category | undefined) => {
    if (category === undefined) {
      setSelectedCategory(0);
      if (allProductsId !== undefined) {
        setFilteredProducts([...allProductsId]);
      } else {
        setFilteredProducts([]);
      }
    }
    if (category !== undefined && category.id === selectedCategory) {
      setSelectedCategory(0);
      if (allProductsId !== undefined) {
        setFilteredProducts([...allProductsId]);
      } else {
        setFilteredProducts([]);
      }
    }
    if (category !== undefined && category.id !== selectedCategory) {
      setSelectedCategory(category.id);
      setFilteredProducts(category.productIds);
    }
  };

  return (
    <Fragment>
      <PageHeader
        title="Products"
        extra={
          <Button type="primary" onClick={onAddNewProductClick}>
            Add New Product
          </Button>
        }
      />
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        <Col span={8}>
          <InputString
            placeholder="Search in name"
            value={searchValue === undefined ? null : searchValue}
            onChange={onSearchValueChange}
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", marginBottom: 20 }}>
        {ctx.document?.menu.categories !== undefined &&
          ctx.document.menu.categories.map((category) => {
            if (category.name["hu"].length > 0)
              return (
                <Button
                  key={category.id}
                  onClick={() => handleFilterCategory(category)}
                  type={
                    selectedCategory === category.id ? "primary" : undefined
                  }
                >
                  {category.name["hu"]}
                </Button>
              );
          })}
      </Row>
      <Table
        onRow={onRow}
        rowKey="id"
        columns={columns}
        tableLayout="auto"
        sticky={true}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onTableChange}
        showSorterTooltip={false}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
          current: page !== null && page !== undefined ? page : 1,
          onChange: (page) => {
            setPage(page);
          },
        }}
        dataSource={ctx.document.menu.products
          .filter((product) => {
            return (
              product.name["hu"]
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(
                  searchValue
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) ||
              product.name["en"]
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLocaleLowerCase()
                .includes(
                  searchValue
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                ) ||
              product.editorName
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLocaleLowerCase()
                .includes(
                  searchValue
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLocaleLowerCase()
                ) ||
              product.id === Number(searchValue) ||
              (product.vendel.externalId !== null &&
                product.vendel.externalId.toString() ===
                  searchValue
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLocaleLowerCase())
            );
          })
          .filter((product) => filteredProducts.includes(product.id))}
      />
    </Fragment>
  );
};
