import React, { useEffect, useCallback } from "react";
import {
  Button,
  Divider,
  Table,
  Tabs,
  TabsProps,
  Modal,
  DatePicker,
  DatePickerProps,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import {
  AdminHelpModal,
  AdminHelpModalIframe,
  AdminTextButton,
  CommandButtonsContainer,
  Container,
  StatusContainer,
  StatusDefault,
  StatusError,
  StatusReady,
  StatusTitle,
  StatusWait,
  StatusWarning,
  SubContainer,
  SubStatusTitle,
  SubStatusValue,
  TabTitle,
} from "./style";
import { DateTime } from "luxon";
import {
  KioskLog,
  RestaurantSystemStates,
  States,
  getKioskLogs,
  postKioskCommand,
  postPing,
} from "../../api/client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import i18next from "i18next";
import dayjs from "dayjs";

export type Command = {
  command: string;
  name: string;
  isCommandInProgress: boolean;
  modal?: string;
};

function DeviceDetails() {
  //const { Option } = Select;
  const intl = useIntl();
  const [kioskLogs, setKioskLogs] = React.useState<KioskLog | null>(null);
  const [showHelp, setShowHelp] = React.useState<boolean>(false);
  const [showEkasaModal, setShowEkasaModal] = React.useState<boolean>(false);
  const [actualCommand, setActualCommand] = React.useState<number>(0);
  const [kioskAvailable, setKioskAvailable] = React.useState<boolean>(false);
  const [ekasaDate, setEkasaDate] = React.useState<DatePickerProps["value"]>(
    dayjs(new Date())
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [devMode, _setDevMode] = useQueryParam("dev_mode", BooleanParam);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bankCardButtons, _setBankCardButtons] = useQueryParam(
    "bank_card_buttons",
    BooleanParam
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [novopayButtons, _setNovopayButtons] = useQueryParam(
    "novopay_buttons",
    BooleanParam
  );

  //novopay_buttons;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, _setToken] = useQueryParam("token", StringParam);
  const [lang, _setLang] = useQueryParam("lang", StringParam);

  const [aeeType, _setAeeType] = useQueryParam("aee_type", StringParam);
  const [cardReaderType, _setCardReaderType] = useQueryParam(
    "card_reader_type",
    StringParam
  );

  const params = useParams();
  const { data: kioskLogData } = useQuery({
    queryKey: ["kiosklog", params.balena_id, token],
    queryFn: () => getKioskLogs(params.balena_id, token),
    refetchInterval: 2000,
  });

  const { data: kioskPingData, error: kioskPingError } = useQuery({
    queryKey: ["postping", params.balena_id, token],
    queryFn: () => postPing(params.balena_id, token),
    refetchInterval: 2000,
  });

  const { mutateAsync: doPostKioskCommand } = useMutation({
    mutationKey: ["kioskCommand"],
    mutationFn: postKioskCommand,
  });

  useEffect(() => {
    if (kioskLogData !== undefined) setKioskLogs(kioskLogData.data);
  }, [kioskLogData]);

  useEffect(() => {
    if (kioskPingError instanceof AxiosError) {
      setKioskAvailable(false);
    } else {
      if (kioskPingData !== undefined && kioskPingData.data.status === "ok") {
        setKioskAvailable(true);
      } else {
        setKioskAvailable(false);
      }
    }
  }, [kioskPingData, kioskPingError]);

  const defaultCommands: Array<Command> = [
    {
      command: "unlock",
      name: i18next.t("admin.unlock", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }),
      isCommandInProgress: false,
    },

    {
      command: "kiosk_restart",
      name: i18next.t("admin.terminal_restart", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }) /*intl.formatMessage({
        id: "admin.terminal_restart",
      })*/, //"Kiosk újraindítása",
      isCommandInProgress: false,
    },
    {
      command: "kiosk_shutdown",
      name: i18next.t("admin.terminal_shutdown", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }),
      isCommandInProgress: false,
    },
    {
      command: "terminal_lock",
      name: i18next.t("admin.terminal_lock", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }),
      isCommandInProgress: false,
    },
    {
      command: "terminal_unlock",
      name: i18next.t("admin.terminal_unlock", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }),
      isCommandInProgress: false,
    },
  ];
  const ekasaCommands: Array<Command> = [
    {
      command: "ekasa_close_day",
      name: i18next.t("admin.aee_close", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }),
      isCommandInProgress: false,
      modal: "ekasa",
    },
  ];

  const fiscatCommands: Array<Command> = [
    {
      command: "restart_aee",
      name: "AEE újraindítása",
      isCommandInProgress: false,
    },
    {
      command: "aee_open_day",
      name: "AEE nap nyitás",
      isCommandInProgress: false,
    },
    {
      command: "aee_close_day",
      name: "AEE nap zárás",
      isCommandInProgress: false,
    },
    {
      command: "hardware_restart_aee",
      name: "AEE hardver újraindítása",
      isCommandInProgress: false,
    },
    {
      command: "open_aee_software",
      name: "AEE szoftver megnyitása",
      isCommandInProgress: false,
    },
    {
      command: "open_aee_paring",
      name: "AEE szoftver párosítás",
      isCommandInProgress: false,
    },
    {
      command: "close_receipt",
      name: "Blokk lezárása",
      isCommandInProgress: false,
    },
  ];

  const otpCommands: Array<Command> = [
    {
      command: "bank_card_reader_login",
      name: "Napnyitás (Bankkártya)",
      isCommandInProgress: false,
    },
    {
      command: "bank_card_reader_logout",
      name: "Napi zárás (Bankkártya)",
      isCommandInProgress: false,
    },
  ];

  const novopayCommands: Array<Command> = [
    {
      command: "bank_card_reader_logout_wallee",
      name: "Napi zárás (Bankkártya)",
      isCommandInProgress: false,
    },
    {
      command: "bank_card_reader_logout_sca",
      name: "Napi zárás (Szépkártya)",
      isCommandInProgress: false,
    },
  ];

  const printecCommands: Array<Command> = [
    {
      command: "bank_card_reader_logout",
      name: i18next.t("admin.card_reader_close", {
        lng: lang !== undefined && lang !== null ? lang : undefined,
      }),
      isCommandInProgress: false,
    },
  ];

  const [commands, setCommands] = React.useState<Array<Command>>(
    [
      ...(bankCardButtons !== undefined && bankCardButtons !== null
        ? otpCommands
        : []),
      ...(novopayButtons !== undefined && novopayButtons !== null
        ? novopayCommands
        : []),
      ...(cardReaderType !== undefined &&
      novopayButtons !== null &&
      cardReaderType === "novopay"
        ? novopayCommands
        : []),
      ...(cardReaderType !== undefined &&
      novopayButtons !== null &&
      cardReaderType === "otp"
        ? otpCommands
        : []),
      ...(cardReaderType !== undefined &&
      novopayButtons !== null &&
      cardReaderType === "printec"
        ? printecCommands
        : []),

      ...(aeeType !== undefined && aeeType !== null && aeeType === "ekasa"
        ? ekasaCommands
        : fiscatCommands),
      ...defaultCommands,
    ]
    /*bankCardButtons !== undefined && bankCardButtons !== null && bankCardButtons
      ? [...bankCardCommands, ...defaultCommands]
      : defaultCommands*/
  );

  const getStatus = (status: string) => {
    // status should be enum
    switch (status) {
      case "READY":
        return <StatusReady />;
      case "ERROR":
        return <StatusError />;
      case "WARNING":
        return <StatusWarning />;
      case "WAIT":
        return <StatusWait />;
      default:
        return <StatusDefault />;
    }
  };

  const aeeColumns: ColumnsType<States> = [
    {
      title: "SessionId",
      dataIndex: "SessionId",
    },
    {
      title: "Parancs",
      dataIndex: ["Data", "Command"],
      render: (text: string) => (text !== "" ? text : <i>Nincs parancs</i>),
    },
    {
      title: "Válasz",
      dataIndex: ["Data", "Response"],
      render: (text: string) => (text !== "" ? text : <i>Nincs válasz</i>),
    },
    {
      title: "Üzenet",
      dataIndex: ["Data", "Message"],
      render: (text: string) => (text !== "" ? text : <i>Nincs üzenet</i>),
    },
    {
      title: "Státusz",
      dataIndex: ["Data", "Status"],
    },
    {
      title: "Dátum",
      dataIndex: "LogCreatedAt",
      render: (text: string) =>
        DateTime.fromISO(text).toFormat("yyyy-MM-dd HH:mm"),
    },
  ];

  const restaurantSystemColumns: ColumnsType<RestaurantSystemStates> = [
    {
      title: "Parancs",
      dataIndex: ["Data", "Command"],
      render: (text: string) => (text !== "" ? text : <i>Nincs parancs</i>),
    },
    {
      title: "Válasz",
      dataIndex: ["Data", "Response"],
      render: (text: string) => (text !== "" ? text : <i>Nincs válasz</i>),
    },
    {
      title: "Státusz",
      dataIndex: ["Data", "Status"],
    },
    {
      title: "Dátum",
      dataIndex: "LogCreatedAt",
      render: (text: string) =>
        DateTime.fromISO(text).toFormat("yyyy-MM-dd HH:mm"),
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <TabTitle>
          <div>Adóügyi egység</div>
        </TabTitle>
      ),
      children: (
        <Table
          size="small"
          style={{ width: "60%", marginLeft: 60 }}
          columns={aeeColumns}
          dataSource={kioskLogs !== null ? kioskLogs.AEEStates : []}
          bordered
          pagination={false}
        />
      ),
    },
    {
      key: "2",
      label: (
        <TabTitle>
          <div>Bankkártya terminál</div>
        </TabTitle>
      ),
      children: (
        <Table
          size="small"
          style={{ width: "60%", marginLeft: 60 }}
          columns={aeeColumns}
          dataSource={kioskLogs !== null ? kioskLogs.CardReaderStates : []}
          bordered
          pagination={false}
        />
      ),
    },
    {
      key: "3",
      label: (
        <TabTitle>
          <div>Éttermi rendszer</div>
        </TabTitle>
      ),
      children: (
        <Table
          size="small"
          style={{ width: "60%", marginLeft: 60 }}
          columns={restaurantSystemColumns}
          dataSource={
            kioskLogs !== null ? kioskLogs.RestaurantSystemStates : []
          }
          bordered
          pagination={false}
        />
      ),
    },
    {
      key: "4",
      label: (
        <TabTitle>
          <div>Vonalkód olvasó</div>
        </TabTitle>
      ),
      children: (
        <Table
          size="small"
          style={{ width: "60%", marginLeft: 60 }}
          columns={aeeColumns}
          dataSource={kioskLogs !== null ? kioskLogs.BarCodeReaderStates : []}
          bordered
          pagination={false}
        />
      ),
    },
    {
      key: "5",
      label: (
        <TabTitle>
          <div>Szünetmentes táp</div>
        </TabTitle>
      ),
      children: (
        <Table
          size="small"
          style={{ width: "60%", marginLeft: 60 }}
          columns={aeeColumns}
          dataSource={kioskLogs !== null ? kioskLogs.UPStates : []}
          bordered
          pagination={false}
        />
      ),
    },
  ];

  const callKioskCommand = useCallback((i: number, commandParams: unknown) => {
    const copyCommands = [...commands];
    copyCommands[i].isCommandInProgress = true;
    setCommands(copyCommands);

    doPostKioskCommand({
      balenaId:
        params.balena_id !== undefined ? params.balena_id.split("&")[0] : "",
      command: commands[i].command,
      token: "bjcz33TRrpHZDEcdeYHZcn53CwQbNkw8",
      params: commandParams,
    })
      .then((response) => {
        if (response.data.status === "success") {
          Modal.success({
            title: "Sikeres",
            content: commands[i].name,
          });
        } else {
          Modal.error({
            title: "Something bad happened",
            content: response.data.error_msg,
          });
        }
      })
      .catch((error: AxiosError) => {
        console.error(error);
        Modal.error({
          title: "Something bad happened",
          content: error.message,
        });
      })
      .finally(() => {
        const copyCommands = [...commands];
        copyCommands[i].isCommandInProgress = false;
        setCommands(copyCommands);
      });
  }, []);

  const onCommandButtonClick = useCallback(
    (i: number) => () => {
      setActualCommand(i);
      if (commands[i].modal !== undefined) {
        setShowEkasaModal(true);
      } else {
        callKioskCommand(i, {});
      }
    },
    [doPostKioskCommand, params.balena_id, commands]
  );

  const onHelpButtonClick = useCallback(
    () => () => {
      setShowHelp(true);
    },
    []
  );

  const renderCommandButtons = (): JSX.Element[] => {
    const commandButtons: JSX.Element[] = [];
    for (let i = 0; i < commands.length; i++) {
      commandButtons.push(
        <Button
          style={{ margin: 5 }}
          type="primary"
          loading={commands[i].isCommandInProgress}
          danger={
            commands[i].name === "Unlock" &&
            kioskLogs !== null &&
            kioskLogs.OrderStatus === "WAIT_UNLOCK"
          }
          onClick={onCommandButtonClick(i)}
        >
          {commands[i].name}
        </Button>
      );
    }
    return commandButtons;
  };

  const renderAdminHelpModal = (): JSX.Element => {
    return (
      <AdminHelpModal showModal={showHelp}>
        <AdminHelpModalIframe
          src={
            "https://docs.google.com/document/u/0/d/10w54ULYAPAFGSBQdu3rr6LUsRyAXFsfbX53iRJIxEao/mobilebasic#h.7l9tjvgozivt"
          }
        />
        {/*<AdminTextButton
          inactive={false}
          isGreen={true}
          onClick={handleHelpModalClose}
        >
          Help bezárása
        </AdminTextButton>*/}
        <Button
          style={{ margin: 5 }}
          type="primary"
          onClick={handleHelpModalClose}
        >
          Help bezárása
        </Button>
      </AdminHelpModal>
    );
  };

  const handleHelpModalClose = (): void => {
    setShowHelp(false);
  };

  return (
    <>
      <Container>
        <SubContainer>
          <Divider orientation="left">
            {i18next.t("admin.statuses", {
              lng: lang !== undefined && lang !== null ? lang : undefined,
            })}
          </Divider>
          <StatusContainer>
            <StatusTitle>
              ID: {params.balena_id?.substring(0, 7)} {kioskLogs?.Name}
            </StatusTitle>
            <StatusTitle>
              {i18next.t("admin.ups", {
                lng: lang !== undefined && lang !== null ? lang : undefined,
              })}
              : {getStatus(kioskLogs !== null ? kioskLogs.UPSStatus : "")}
              {kioskLogs !== null &&
                kioskLogs.UPSCriticalStatuses !== null &&
                kioskLogs.UPSCriticalStatuses.length > 0 && (
                  <SubStatusTitle>
                    Hibák:{" "}
                    <SubStatusValue isCriticalStatus={true}>
                      {kioskLogs.UPSCriticalStatuses.join(", ")}
                    </SubStatusValue>
                  </SubStatusTitle>
                )}
            </StatusTitle>
            <StatusTitle>
              {i18next.t("admin.aee", {
                lng: lang !== undefined && lang !== null ? lang : undefined,
              })}
              : {getStatus(kioskLogs !== null ? kioskLogs.AEEStatus : "")}
              {kioskLogs !== null &&
                kioskLogs?.AEECriticalStatuses !== null &&
                kioskLogs.AEECriticalStatuses.length > 0 && (
                  <SubStatusTitle>
                    Hibák:{" "}
                    <SubStatusValue isCriticalStatus={true}>
                      {kioskLogs.AEECriticalStatuses.join(", ")}
                    </SubStatusValue>
                  </SubStatusTitle>
                )}
              {kioskLogs !== null &&
                kioskLogs?.AEEInfos !== null &&
                kioskLogs.AEEInfos.length > 0 && (
                  <SubStatusTitle>
                    Infók:{" "}
                    <SubStatusValue isCriticalStatus={false}>
                      {kioskLogs.AEEInfos.join(", ")}
                    </SubStatusValue>
                  </SubStatusTitle>
                )}
            </StatusTitle>
            <StatusTitle>
              {i18next.t("admin.card_reader", {
                lng: lang !== undefined && lang !== null ? lang : undefined,
              })}
              :{" "}
              {getStatus(kioskLogs !== null ? kioskLogs.CardReaderStatus : "")}
              {kioskLogs !== null &&
                kioskLogs?.CardReaderLastPayErrors !== null &&
                kioskLogs.CardReaderLastPayErrors.length > 0 && (
                  <SubStatusTitle>
                    Hibák:{" "}
                    <SubStatusValue isCriticalStatus={true}>
                      {kioskLogs?.CardReaderLastPayErrors.join(", ")}
                    </SubStatusValue>
                  </SubStatusTitle>
                )}
            </StatusTitle>
            <StatusTitle>
              {i18next.t("admin.restaurant_system", {
                lng: lang !== undefined && lang !== null ? lang : undefined,
              })}
              :{" "}
              {getStatus(
                kioskLogs !== null ? kioskLogs.RestaurantSystemStatus : ""
              )}
            </StatusTitle>
            <StatusTitle>
              {i18next.t("admin.barcode_reader", {
                lng: lang !== undefined && lang !== null ? lang : undefined,
              })}
              :{" "}
              {getStatus(
                kioskLogs !== null ? kioskLogs.BarCodeReaderStatus : ""
              )}
            </StatusTitle>
          </StatusContainer>
          {devMode !== null && devMode !== undefined && devMode && (
            <>
              <Divider orientation="left">Info</Divider>
              <Tabs defaultActiveKey="1" items={items} />
            </>
          )}
          <Divider orientation="left">
            {i18next.t("admin.commands", {
              lng: lang !== undefined && lang !== null ? lang : undefined,
            })}
          </Divider>
          <CommandButtonsContainer>
            {
              /*kioskAvailable || true ? */ <>
                {renderCommandButtons()}
                <Button
                  style={{ margin: 5 }}
                  type="primary"
                  onClick={onHelpButtonClick()}
                >
                  HELP
                </Button>
              </> /*: (
            <StatusTitle>Kiosk not available</StatusTitle>
          )*/
            }
          </CommandButtonsContainer>

          {renderAdminHelpModal()}
        </SubContainer>
      </Container>
      <Modal
        open={showEkasaModal}
        title={i18next.t("admin.aee_close", {
          lng: lang !== undefined && lang !== null ? lang : undefined,
        })}
        onOk={() => {
          console.log("ok");
        }}
        onCancel={() => {
          setShowEkasaModal(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              //console.log("ok2");
              //console.log(ekasaDate?.format("YYYY/MM/DD"));
              if (ekasaDate === null || ekasaDate === undefined) {
                return;
              }
              callKioskCommand(actualCommand, {
                year: ekasaDate?.year(),
                month: ekasaDate?.month() + 1,
                day: ekasaDate?.date(),
              });
            }}
            disabled={ekasaDate === null}
          >
            Submit
          </Button>,
        ]}
      >
        <DatePicker
          value={ekasaDate}
          onChange={(
            value: DatePickerProps["value"] | null,
            dateString: string
          ) => {
            setEkasaDate(value);
          }}
          format={"YYYY/MM/DD"}
          disabledDate={(current) => {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            return current && current > dayjs().endOf("day");
          }}
        />
      </Modal>
    </>
  );
}

export default DeviceDetails;
