import { Fragment } from "react";
import { PageHeader } from "../components/PageHeader";
import { useVapianoConfigContext } from "./vapianoConfigContext";

export const Export: React.FC = () => {
  const ctx = useVapianoConfigContext();

  return (
    <Fragment>
      <PageHeader title="Export" />
      <div>
        {ctx.document.menu.categories.map((category) => (
          <div key={category.id}>
            <h1
              style={{
                fontSize: 28,
                borderBottom: "2px solid #000",
              }}
            >
              {category.name["hu"]}
            </h1>
            <div>
              {category.productIds.map((productId) => (
                <div key={productId}>
                  <div
                    style={{
                      display: "flex",
                      fontSize: 22,
                      marginLeft: 44,
                      height: "min-content",
                      marginTop: 20,
                    }}
                  >
                    <p
                      style={{
                        marginRight: 12,
                        borderBottom: "2px solid #000",
                      }}
                    >
                      {
                        ctx.document.menu.products.find(
                          (product) => product.id === productId
                        )?.name["hu"]
                      }
                      :
                    </p>
                    <p
                      style={{
                        marginBottom: "10px !important",
                      }}
                    >
                      {
                        ctx.document.menu.products.find(
                          (product) => product.id === productId
                        )?.price
                      }
                      {" Ft"}
                    </p>
                  </div>
                  {ctx.document.menu.products
                    .find((product) => product.id === productId)
                    ?.productSteps.map((productStep) => (
                      <div key={productStep.stepId}>
                        <p style={{ fontSize: 18, marginLeft: 88 }}>
                          {
                            ctx.document.menu.steps.find(
                              (step) => step.id === productStep.stepId
                            )?.name["hu"]
                          }
                        </p>

                        <div>
                          {ctx.document.menu.steps
                            .find((step) => step.id === productStep.stepId)
                            ?.stepOptions.map((stepOption) => (
                              <div
                                key={stepOption.optionId}
                                style={{ display: "flex" }}
                              >
                                <p style={{ fontSize: 14, marginLeft: 100 }}>
                                  {
                                    ctx.document.menu.options.find(
                                      (option) =>
                                        option.id === stepOption.optionId
                                    )?.name["hu"]
                                  }
                                </p>
                                <p style={{ fontSize: 14, marginLeft: 10 }}>
                                  {"+ "}
                                  {stepOption.price !== null
                                    ? stepOption.price
                                    : 0}
                                  {" Ft"}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
