import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Modal, Table, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { ITEMS_PER_PAGE } from "../lib/settings";
import {
  downloadProductVisibilityHistories,
  getMenus,
  postCopyMenu,
  saveMenu,
} from "../api/client";
import {
  CopyMenuRequest,
  SaveMenuRequest,
  MenuResponse as MenuType,
} from "../generated";
import { InputString, Select } from "../editor/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useClientContext } from "../editor/context";

const emptyMenu: SaveMenuRequest = {
  Id: undefined,
  Name: "",
  Description: "",
  ClientId: 0,
  Data: {
    menu: {
      categories: [],
      options: [],
      products: [],
      steps: [],
    },
  },
};

export const Menus: React.FC = () => {
  const navigate = useNavigate();
  const clientContext = useClientContext();
  const [menus, setMenus] = useState<Array<MenuType>>([]);
  const [isCreateNewMenuModalOpen, setsCreateNewMenuModalOpen] =
    useState<boolean>(false);
  const [isCopyMenuModalOpen, setsCopyMenuModalOpen] = useState<boolean>(false);
  const [copyMenu, setCopyMenu] = useState<CopyMenuRequest>({
    Id: 0,
    Name: "",
    Description: "",
  });
  const [newMenuData, setNewMenuData] = useState<SaveMenuRequest>(emptyMenu);
  const {
    data: menuData,
    isLoading: isMenuLoading,
    refetch: doGetMenus,
  } = useQuery({
    queryKey: ["menus", clientContext.selectedClient?.Id],
    queryFn: () =>
      getMenus(
        clientContext.selectedClient !== null
          ? clientContext.selectedClient.Id
          : 0
      ),
  });
  const { data: createNewMenuData, mutate: doCreateNewMenu } = useMutation({
    mutationKey: ["createNewMenu"],
    mutationFn: saveMenu,
  });
  const { data: copyMenuData, mutate: doCopyMenu } = useMutation({
    mutationKey: ["copyMenu"],
    mutationFn: postCopyMenu,
  });

  const handleVisibilityHistoryExport = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    menuId: number
  ) => {
    e.stopPropagation();
    downloadProductVisibilityHistories(menuId);
  };

  useEffect(() => {
    if (createNewMenuData !== undefined) {
      void doGetMenus();
      void message.success("Menu created");
    }
  }, [createNewMenuData, doGetMenus]);

  useEffect(() => {
    if (copyMenuData !== undefined) {
      void doGetMenus();
      void message.success("Menu copied");
    }
  }, [copyMenuData, doGetMenus]);

  useEffect(() => {
    if (menuData !== undefined) {
      setMenus(menuData.data);
    }
  }, [menuData]);

  useEffect(() => {
    if (clientContext.selectedClient !== null) {
      setNewMenuData({
        ...newMenuData,
        ClientId: clientContext.selectedClient.Id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientContext.selectedClient]);

  const onEditMenuButtonClick = useCallback(
    (menuId: number) => () => {
      navigate(`/dashboard/menu/${menuId}/categories`);
    },
    [navigate]
  );

  const columns: ColumnsType<MenuType> = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Menu valid",
      dataIndex: "IsValid",
      key: "IsValid",
      render: (isValid: boolean) => {
        return isValid ? (
          <CheckCircleTwoTone rev={undefined} twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone rev={undefined} twoToneColor="#eb2f96" />
        );
      },
    },
    {
      width: 450,
      title: "Action",
      dataIndex: "ID",
      key: "ID",
      render: (_id: number, data: MenuType) => {
        return (
          <div>
            <Button
              onClick={onEditMenuButtonClick(data.Id)}
              style={{ marginRight: 10 }}
            >
              Edit menu
            </Button>
            <Button
              onClick={() => navigate(`/dashboard/menu/${data.Id}/export`)}
              style={{ marginRight: 10 }}
            >
              Export menu
            </Button>
            <Button onClick={(e) => handleVisibilityHistoryExport(e, data.Id)}>
              Export Visibility History
            </Button>
          </div>
        );
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCreateNewMenuClick = useCallback(() => {
    setNewMenuData(emptyMenu);
    setsCreateNewMenuModalOpen(true);
  }, []);

  const onCopyMenuClick = useCallback(() => {
    setCopyMenu({
      Id: menus[0].Id,
      Name: "",
      Description: "",
    });
    setsCopyMenuModalOpen(true);
  }, [menus]);

  const onMenuSaveButtonClick = useCallback(() => {
    setsCreateNewMenuModalOpen(false);
    if (clientContext.selectedClient === null) return;
    void doCreateNewMenu({
      ...newMenuData,
      ClientId: clientContext.selectedClient.Id,
    });
    // init new menu data to empty
    setNewMenuData(emptyMenu);
  }, [clientContext.selectedClient, doCreateNewMenu, newMenuData]);

  const onCopyMenuModalOkClick = useCallback(() => {
    setsCopyMenuModalOpen(false);
    void doCopyMenu(copyMenu);
    setCopyMenu({
      Id: menus[0].Id,
      Name: "",
      Description: "",
    });
  }, [copyMenu, doCopyMenu, menus]);

  const onMenuModalCancel = useCallback(() => {
    setsCreateNewMenuModalOpen(false);
  }, []);

  const onCopyMenuModalCancel = useCallback(() => {
    setsCopyMenuModalOpen(false);
  }, []);

  const onMenuNameChange = useCallback(
    (type: "Name" | "Description") => (value: string | null) => {
      setNewMenuData({
        ...newMenuData,
        [type]: value !== null ? value : "",
      });
    },
    [newMenuData]
  );

  const onCopyMenuChange = useCallback(
    (type: "Name" | "Description" | "Id") =>
      (value: string | null | number | (string | number)[]) => {
        setCopyMenu({
          ...copyMenu,
          [type]: value !== null ? value : "",
        });
      },
    [copyMenu]
  );

  const onRow = useCallback(
    (record: MenuType) => {
      return {
        onClick: () => {
          if (clientContext.selectedClient === null) return;
          setNewMenuData({
            Id: record.Id,
            Name: record.Name,
            Description: record.Description,
            ClientId: clientContext.selectedClient.Id,
            Data: null,
          });
          setsCreateNewMenuModalOpen(true);
        },
      };
    },
    [clientContext.selectedClient]
  );

  const menuOptions = useMemo(() => {
    return menus.map((menu) => ({
      label: menu.Name,
      value: menu.Id,
    }));
  }, [menus]);

  if (isMenuLoading) return null;

  return (
    <Fragment>
      <PageHeader
        title="Menus"
        extra={
          <div>
            <Button
              style={{ marginRight: 10 }}
              type="primary"
              onClick={onCopyMenuClick}
            >
              Copy Menu
            </Button>
            {/*<Button type="primary" onClick={onCreateNewMenuClick}>
              Create New Menu
        </Button>*/}
          </div>
        }
      />

      <Table
        onRow={onRow}
        rowKey="Id"
        columns={columns}
        tableLayout="auto"
        sticky={true}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        dataSource={menus.sort((a, b) => a.Id - b.Id)}
        sortDirections={["descend", "ascend"]}
      />

      {/* MODALS */}
      {/* NEW MENU MODAL */}
      <Modal
        title="Create New Menu"
        open={isCreateNewMenuModalOpen}
        onOk={onMenuSaveButtonClick}
        onCancel={onMenuModalCancel}
        maskClosable={false}
      >
        <InputString
          label="Name"
          onChange={onMenuNameChange("Name")}
          value={newMenuData.Name}
        />
        <InputString
          label="Description"
          onChange={onMenuNameChange("Description")}
          value={newMenuData.Description}
        />
      </Modal>
      {/* COPY MENU MODAL */}
      <Modal
        title="Copy menu"
        open={isCopyMenuModalOpen}
        onOk={onCopyMenuModalOkClick}
        onCancel={onCopyMenuModalCancel}
        maskClosable={false}
      >
        <InputString
          label="Name"
          onChange={onCopyMenuChange("Name")}
          value={copyMenu.Name}
        />
        <InputString
          label="Description"
          onChange={onCopyMenuChange("Description")}
          value={copyMenu.Description}
        />
        <Select
          label="Copy from menu"
          value={copyMenu.Id}
          options={menuOptions}
          onChange={onCopyMenuChange("Id")}
        />
      </Modal>
    </Fragment>
  );
};
