import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Table, Tooltip, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { ITEMS_PER_PAGE } from "../lib/settings";
import { getLocations, postDeployMenu } from "../api/client";
import { Location, ActiveMenu, Kiosk, UserRole } from "../generated";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useClientContext, useUserContext } from "../editor/context";

export const Locations: React.FC = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState<Array<Location>>([]);
  const clientContext = useClientContext();
  const userContext = useUserContext();

  const {
    data: locationData,
    isLoading: isLocationsLoading,
    refetch: fetchLocations,
  } = useQuery({
    queryKey: ["locations", clientContext.selectedClient?.Id],
    queryFn: () =>
      getLocations(
        clientContext.selectedClient !== null
          ? clientContext.selectedClient.Id
          : 0
      ),
    enabled: false,
  });
  const { data: deployMenuData, mutate: doDeployMenu } = useMutation({
    mutationKey: ["deployMenu"],
    mutationFn: postDeployMenu,
  });

  useEffect(() => {
    if (deployMenuData !== undefined) {
      void message.success("Menu deployed");
      void fetchLocations();
    }
  }, [deployMenuData, fetchLocations]);

  useEffect(() => {
    if (clientContext.selectedClient !== null) {
      void fetchLocations();
    }
  }, [fetchLocations, clientContext.selectedClient]);

  useEffect(() => {
    if (locationData !== undefined) {
      setLocations(locationData.data);
    }
  }, [locationData]);

  const onDeployMenuButton = useCallback(
    (locationId: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      if (clientContext.selectedClient === null) return;
      void doDeployMenu({
        LocationId: locationId,
        ClientId: clientContext.selectedClient.Id,
      });
    },
    [doDeployMenu, clientContext.selectedClient]
  );

  const basicColums: ColumnsType<Location> = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Menu",
      dataIndex: "ActiveMenu",
      key: "ActiveMenu",
      render: (activeMenu: ActiveMenu | null) => {
        if (activeMenu !== null) {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 10 }}>{activeMenu.Name}</div>
              <div>
                {activeMenu.IsValid ? (
                  <CheckCircleTwoTone rev={undefined} twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleTwoTone rev={undefined} twoToneColor="#eb2f96" />
                )}
              </div>
            </div>
          );
        }
        return "No active menu";
      },
    },
    {
      title: "Last deployed",
      dataIndex: "LastDeploy",
      key: "LastDeploy",
      render: (lastDeploy: string | null) => {
        if (lastDeploy === null) return "No last deploy";
        return DateTime.fromISO(lastDeploy).toFormat("yyyy-MM-dd HH:mm");
      },
    },
  ];

  const actionColumns: ColumnsType<Location> = [
    {
      title: "Action",
      dataIndex: "Id",
      key: "Id",
      render: (locationId: number, record: Location) => {
        return (
          <Tooltip
            title={
              record.ActiveMenu !== undefined
                ? record.ActiveMenu.IsValid
                  ? undefined
                  : "Menu is not valid so this location's menu cannot be deployed"
                : "Menu is not valid so this location's menu cannot be deployed"
            }
          >
            <Button
              type="primary"
              disabled={
                record.ActiveMenu !== undefined
                  ? !record.ActiveMenu.IsValid
                  : true
              }
              onClick={onDeployMenuButton(locationId)}
            >
              Deploy Menu
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const kioskColumns: ColumnsType<Kiosk> = [
    {
      title: "ID",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
  ];

  const onRow = useCallback(
    (location: Location) => {
      return {
        onClick: () => {
          navigate(`/dashboard/location/${location.Id}`);
        },
      };
    },
    [navigate]
  );

  const expandedRowRender = useCallback((record: Location) => {
    return (
      <div>
        <Table
          rowKey="Id"
          size="small"
          pagination={false}
          columns={kioskColumns}
          dataSource={record.Kiosks}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLocationsLoading) return null;

  return (
    <Fragment>
      <PageHeader title="Locations" />

      <Table
        onRow={onRow}
        rowKey="Id"
        columns={
          userContext.user !== null &&
          (userContext.user.Role === UserRole.ADMIN ||
            userContext.user.Role === UserRole.CLIENT)
            ? [...basicColums, ...actionColumns]
            : basicColums
        }
        tableLayout="auto"
        sticky={true}
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          position: ["topLeft"],
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        expandable={{
          expandedRowRender,
        }}
        dataSource={locations.sort((a, b) => a.Id - b.Id)}
        sortDirections={["descend", "ascend"]}
      />
    </Fragment>
  );
};
