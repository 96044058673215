import { Button, Col, message, Row } from "antd";
import { useNavigate } from "react-router-dom";
import {
  LoginFormContainer,
  LoginFormWrapper,
  LoginHeaderWrapper,
  LoginInputWrapper,
  LoginPageWrapper,
  LoginPasswordInputWrapper,
} from "./LoginStyle";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { FC, useCallback, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { postLogin } from "../api/client";
import { LoginRequest } from "../generated";
import { useMutation } from "@tanstack/react-query";
import { MainRoutes } from "../Router";

export const Login: FC = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<LoginRequest>({
    Username: "",
    Password: "",
  });

  const {
    data,
    error,
    isLoading: loading,
    mutate: doPostLogin,
  } = useMutation({ mutationKey: ["login"], mutationFn: postLogin });

  useEffect(() => {
    if (error !== null) {
      void message.error("ERROR on login, maybe bad username or password?");
    }
  }, [error]);

  useEffect(() => {
    if (data !== undefined) {
      void message.success("Login success");
      navigate("/" + MainRoutes.DASHBOARD);
    }
  }, [data, navigate]);

  const doLogin = useCallback(() => {
    if (credentials.Username.length > 0 && credentials.Password.length > 0) {
      doPostLogin(credentials);
    }
  }, [credentials, doPostLogin]);

  const onKeyPressDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13) {
        void doLogin();
      }
    },
    [doLogin]
  );

  const onInputFieldsChange = useCallback(
    (type: "Username" | "Password") =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type === "Username") {
          setCredentials({
            ...credentials,
            Username: e.target.value,
          });
        } else if (type === "Password") {
          setCredentials({
            ...credentials,
            Password: e.target.value,
          });
        }
      },
    [credentials]
  );

  return (
    <LoginPageWrapper className="App">
      <Row
        align="middle"
        justify="center"
        style={{ width: "100%", flexGrow: 1 }}
      >
        <LoginFormWrapper span={24}>
          <LoginHeaderWrapper>
            <ReactSVG src="logo.svg" />
          </LoginHeaderWrapper>
          <Row style={{ width: "100%" }} align={"middle"} justify={"center"}>
            <Col span={8} md={8} lg={8} xl={6} xxl={4}>
              <LoginFormContainer>
                <LoginInputWrapper
                  prefix={
                    <UserOutlined
                      rev={undefined}
                      style={{ color: "#1890FF" }}
                    />
                  }
                  status={error !== null ? "error" : undefined}
                  onKeyDown={onKeyPressDown}
                  placeholder="Username"
                  onChange={onInputFieldsChange("Username")}
                />
                <LoginPasswordInputWrapper
                  prefix={
                    <LockOutlined
                      rev={undefined}
                      style={{ color: "#1890FF" }}
                    />
                  }
                  status={error !== null ? "error" : undefined}
                  placeholder="Password"
                  onKeyDown={onKeyPressDown}
                  onChange={onInputFieldsChange("Password")}
                />
                <Button
                  type="primary"
                  onClick={doLogin}
                  loading={loading}
                  block
                >
                  Login
                </Button>
              </LoginFormContainer>
            </Col>
          </Row>
        </LoginFormWrapper>
      </Row>
    </LoginPageWrapper>
  );
};
