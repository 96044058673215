import { default as AntSelect, DefaultOptionType } from "antd/lib/select";
import { Tooltip } from "antd";
import React from "react";
import { ReactNode, useCallback } from "react";
import { shallowCompare } from "../utils";
import { CheckboxNull } from "./ChechboxNull";
import { InfoCircleOutlined } from "@ant-design/icons";

export interface SelectOptionType<T extends number | string>
  extends DefaultOptionType {
  label: React.ReactNode;
  value: T;
  children?: Omit<DefaultOptionType, "children">[];
}

export type SelectProps<T extends number | string> = {
  label?: string;
  placeholder?: ReactNode;
  value: T | T[] | null;
  options: SelectOptionType<T>[];
  // defaultValue: RV; // in case options prop is empty, set value to this
  onChange?: (value: T | T[] | null) => void;
  nullable?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  tooltipText?: string;
  hidden?: boolean;
};

const Select = <T extends number | string>({
  label,
  placeholder,
  value,
  options: propOptions,
  onChange: onSelectValueChange,
  nullable,
  disabled,
  multiple,
  tooltipText,
  hidden,
}: SelectProps<T>) => {
  const filterOption = useCallback(
    (inputValue: string, option?: DefaultOptionType): boolean =>
      option !== undefined &&
      (option.label ?? "")
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          inputValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        ),
    []
  );

  const onCheckBoxChange = useCallback(
    (value: T | T[] | null) => {
      if (value === null && nullable === false) {
        onSelectValueChange !== undefined &&
          onSelectValueChange(propOptions[0].value);
      } else {
        onSelectValueChange !== undefined && onSelectValueChange(value);
      }
    },
    [nullable, onSelectValueChange, propOptions]
  );

  const renderLabelWithTooltip = () => (
    <>
      {tooltipText !== undefined && (
        <Tooltip title={tooltipText} placement="topLeft">
          <h3 style={{ width: "fit-content" }}>
            {label}
            <InfoCircleOutlined
              style={{ fontSize: 12, position: "absolute", marginLeft: 3 }}
            />
          </h3>
        </Tooltip>
      )}
      {tooltipText === undefined && (
        <h3 style={{ width: "fit-content" }}>{label}</h3>
      )}
    </>
  );

  return (
    <div
      style={{
        marginTop: 5,
        marginBottom: 5,
        display: hidden === true ? "none" : "auto",
      }}
    >
      {nullable === true && (
        <CheckboxNull
          value={value}
          defaultValue={propOptions[0].value}
          onChange={onCheckBoxChange}
        >
          {renderLabelWithTooltip()}
        </CheckboxNull>
      )}
      {nullable !== true && label !== undefined && renderLabelWithTooltip()}
      {value !== null && (
        <AntSelect
          disabled={disabled}
          dropdownMatchSelectWidth={false}
          allowClear={nullable === true}
          style={{ width: "100%" }}
          showSearch
          placeholder={placeholder}
          optionFilterProp="label"
          onChange={onSelectValueChange}
          mode={multiple === true ? "multiple" : undefined}
          value={value}
          filterOption={filterOption}
          options={propOptions}
        />
      )}
    </div>
  );
};

// shallowCompare has not much effect here, because dataPath contains function
// in it's array elements, that won't match the previous prop in a shallow way.
const SelectMemo = React.memo(Select, shallowCompare);
export { SelectMemo as Select };
